//imports
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../index.scss';
import './welcome.scss';
import Mascot from '../../assets/mascot.js';
import CloseIcon from "../../assets/closeIcon";

/**
 * An element that will represent a welcome banner on the not logged in home page.
 * Will welcome people with a logo and an option to login or sign up
 * Will also have a tooltip for explaining how the site works
 */
class WelcomeBanner extends Component {

    constructor(props) {
        super(props);

        //base states
        this.state = {
            howItWorks: false,
        }
    }

    toggleHowItWorks = () => {
        this.setState({ howItWorks: !this.state.howItWorks });
    }

    /**
     * render function
     */
    render() {

        return (
            <div id={`welcome-container${this.state.howItWorks}`}>
                <div id="welcome-banner">
                    <div id="welcome">
                        <h1>Welcome to FantomBook!</h1>
                    </div>
                    <div id="mascot-logo">
                        <Mascot/>
                    </div>
                    <div id="slogan">
                        The world's first fantasy sportsbook
                    </div>
                    <div id="login-signup-buttons">
                        <button id="login" onClick={() => this.props.history.push('/login')}><p>LOGIN</p></button>
                        <button id="signup" onClick={() => this.props.history.push('/signup')}><p>SIGN UP</p></button>
                    </div>
                    <div id="toggle-button">
                        <button id="how-does-it-work-button" onClick={() => this.toggleHowItWorks()}><p>How does it work?</p></button>
                    </div>
                </div>
                <div id="how-it-works-banner">
                    <button id="close" onClick={() => this.toggleHowItWorks()}><CloseIcon/></button>
                    <div id="how-it-works-header">
                        <h1>How FantomBook Works</h1>
                    </div>
                    <div id="how-it-works-content">
                        FantomBook is the world's first fantasy sportsbook.
                        It is a completely legal bet on sports games, without the fear of losing real money.<br/><br/>

                        Sign up to FantomBook <b>now</b> and start with 100 FantomPoints that you can use to wager on any of the available games.<br/><br/>

                        There are three main bets for each game: spread, total, and money line.
                        When betting spread bets you are betting on the difference in score in a game. So if you take one team -3.0, you are betting on them to win by 3 points, if you take another team +3.0, you are betting on them to lost by less than 3 points, or win by any margin.
                        When betting totals, you are betting on the total amount of points scored in a game. So if you take over 56.0, you are betting on there to be over 56 points in the game.
                        When betting money line, you are betting one team to beat the other straight up. So if you take Team A money line, you are betting on Team A to win the game.<br/><br/>

                        FantomBook also supports various prop bets and specials where you can take different fun bets.<br/><br/>

                        Wager your FantomPoints to try and win as many as possible and get yourself on the leaderboard! Good Luck!
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(WelcomeBanner);