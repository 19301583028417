//imports
import React, { Component } from 'react';
import axios from 'axios';
import Game from '../../elements/game/game.js';
import NoGames from '../../elements/game/noGames.js';
import Specials from '../../elements/game/specials.js';
import SportPicker from '../../elements/sportPicker/sportPicker.js';
import DropDownArrow from '../../assets/dropdownArrow.js';
import Loader from 'react-loader-spinner'
import './home.scss';
import Error from '../../elements/error/error.js';

/**
 * A component representing the content of the home page
 * Will display contain all available bets (specials, game lines, and props)
 * This is where users will view all odds and place all bets
 */
class HomeContent extends Component {

    //constructor
    constructor(props) {
        super(props);

        //available leagues
        this.leagues = ["NFL", "MLB", "NBA", "NHL", "NCAAF"];

        //initialize number of specials
        this.numSpecials = 0;

        //initialize specials state (open or closed) based off users last preference
        this.currentSpecialState = !!localStorage.getItem('specialsState') ? JSON.parse(localStorage.getItem('specialsState')) : true;

        //base states
        this.state = {
            error: null,
            isLoaded: false,
            numOfGames: 0,
            games: [],
            numOfSpecials: 0,
            specials: [],
            currentSport: !!sessionStorage.getItem('currentSport') ? JSON.parse(sessionStorage.getItem('currentSport')) - 1 : 0,
            specialsState: this.currentSpecialState,
        };

        //dealing with teh specials state
        if (this.currentSpecialState === "true") {
            this.setState({ specialsState: true });
        }
        else if (this.currentSpecialState === "false") {
            this.setState({ specialsState: false });
        }

        this.getPopupState = this.getPopupState.bind(this);
        this.addBetToParlay = this.addBetToParlay.bind(this);
        this.removeBetFromParlay = this.removeBetFromParlay.bind(this);
    }

    getPopupState = (val) => {
        this.props.sendPopupState(val);
    }

    addBetToParlay = (bet) => {
        this.props.addToParlay(bet);
    }

    removeBetFromParlay = (bet) => {
        this.props.removeFromParlay(bet);
    }

    /**
     * method called when the component mounts
     * will get games and specials from the backend
     */
    componentDidMount() {
        //getting upcoming games
        axios.get(process.env.REACT_APP_APIL + "games")
            .then(result => {
                const games = result.data;
                // console.log(result.data);
                this.setState({
                    isLoaded: true,
                    games: games.games,
                    numOfGames: result.data.numOfGames,
                });
                this.handleScrollPosition();
                if(!sessionStorage.getItem("currentSport")) {
                    //if theres no games in a league that day, make sure its not showing first (unless no games that day in any league)
                    if (games.leagueCounters.nfl === 0) {
                        this.setState({currentSport: 1});
                        if (games.leagueCounters.mlb === 0) {
                            this.setState({currentSport: 2});
                            if (games.leagueCounters.nba === 0) {
                                this.setState({currentSport: 3});
                                if (games.leagueCounters.nhl === 0) {
                                    this.setState({currentSport: 4});
                                    if (games.leagueCounters.ncaaf === 0) {
                                        this.setState({currentSport: 0});
                                    }
                                }
                            }
                        }
                    }
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    isLoaded: true,
                    error: error
                })
            });
        //getting live specials
        axios.get(process.env.REACT_APP_APIL + "specials")
            .then(result => {
                const specials = result.data;
                this.setState({
                    isLoaded: true,
                    specials: specials.specials,
                    numOfSpecials: result.data.numOfSpecials
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    isLoaded: true,
                    error: error
                })
            })
        // this.handleScrollPosition();
    }

    /**
     * method to update the current index of the sport picker
     * @param {int} newIndx the new index that the sport picker will switch to
     */
    updateSport = newIndx => {
        this.setState({ currentSport: newIndx - 1 });
        this.storeSportIndex(newIndx);
    }

    /**
     * A method to store the current sport index in session storage to keep a user on that one when refreshed
     * @param index the index being stored
     */
    storeSportIndex(index) {
        sessionStorage.setItem('currentSport', JSON.stringify(index))
    }

    /**
     * A method to check if the scroll position has been saved in storage and if it is then bring the screen to that spot
     */
    handleScrollPosition = () => {
        const scrollPosition = sessionStorage.getItem("scrollPosition");
        if (scrollPosition) {
            window.scrollTo(0, parseInt(scrollPosition));
            sessionStorage.removeItem("scrollPosition");
        }
    };

    /**
     * toggle showing the specials. Will open and close the drop down
     */
    toggleSpecials = () => {
        this.setState({
            specialsState: !this.state.specialsState
        }, () => {
            localStorage.setItem('specialsState', JSON.stringify(this.state.specialsState))
        });
    }


    /**
     * render method
     */
    render() {
        //from the axios requests
        const { error, isLoaded, games, numOfGames, specials, numOfSpecials } = this.state;

        //check if there are at least one special available
        let showSpecials = numOfSpecials > 0;

        //error component if there is an error
        if (error) {
            return <Error message={error.message}/>;
        }
        //loader component if the component is loading
        else if (!isLoaded) {
            return <div id="loader"><Loader type="Oval" color="#3DF5D9" height={100} width={100} /></div>;
        } else {

            let filteredGames = [];

            //filter the games by which sport is selected currently
            if (numOfGames !== 0) {
                filteredGames = games.filter(
                    game => game.league === this.leagues[this.state.currentSport]
                );
            }
            //return the content
            return (
                <div>
                    {showSpecials ? (
                        <div className="specials">
                            <div id={`specialsLabel${this.state.specialsState}`}>Specials <div id="arrow" onClick={this.toggleSpecials}><DropDownArrow /></div></div>
                            {this.state.specialsState ? (
                                <Specials parlayMode={this.props.parlayMode} currentParlay={this.props.currentParlay} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} sendPopupState={this.getPopupState} specials={specials} />
                            ) : null}
                        </div>
                    ) : null}
                    <div className="sportPicker">
                        <p id={`leagueLabel${showSpecials}`}>Pick A League</p>
                        <SportPicker startSport={this.state.currentSport} onChange={this.updateSport} />
                    </div>
                    <div className="games">
                        <p id="label">Upcoming {this.leagues[this.state.currentSport]} Games</p>
                        {filteredGames.length > 0 ? (
                            filteredGames.map(filteredGame => (
                                    <Game
                                        key={filteredGame.gameId}
                                        gameId={filteredGame.gameId}
                                        date={filteredGame.date}
                                        awayTeam={filteredGame.awayTeam}
                                        homeTeam={filteredGame.homeTeam}
                                        homeSpreadPoints={filteredGame.homeSpreadPoints}
                                        awaySpreadPoints={filteredGame.awaySpreadPoints}
                                        homeSpreadOdds={filteredGame.homeSpreadOdds}
                                        awaySpreadOdds={filteredGame.awaySpreadOdds}
                                        overPoints={filteredGame.overPoints}
                                        underPoints={filteredGame.underPoints}
                                        overOdds={filteredGame.overOdds}
                                        underOdds={filteredGame.underOdds}
                                        homeMoneyLine={filteredGame.homeMlOdds}
                                        awayMoneyLine={filteredGame.awayMlOdds}
                                        numOfProps={filteredGame.numOfProps}
                                        propArr={filteredGame.props}
                                        drawOdds={filteredGame.drawOdds}
                                        league={filteredGame.league}
                                        sendPopupState={this.getPopupState}
                                        parlayMode={this.props.parlayMode}
                                        addToParlay={this.addBetToParlay}
                                        removeFromParlay={this.removeBetFromParlay}
                                        currentParlay={this.props.currentParlay}
                                    />
                                )
                            )
                        ) : (
                            <div>
                                <NoGames league={this.leagues[this.state.currentSport]} />
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    }
}

export default HomeContent;