//imports
import React, { Component } from 'react';
import '../../index.scss';
import './menuItem.scss';

/**
 * An element to represent a menu item in the account dash menu
 */
class MenuItem extends Component {

    // //constructor
    // constructor(props) {
    //     super(props);
    //     //base states
    // }

    /**
     * render method
     */
    render() {
        return (
            <div className="menu-item" id={"menu-item-name" + this.props.isOn}>
                <p>{this.props.name}</p>
            </div>
        );
    }
}

export default MenuItem;