import React from 'react'; 

class NBASPIcon extends React.Component {
    render() {
        return (
            <svg width="132" height="91" viewBox="0 0 132 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M57.0097 85V68.992H53.6017V79.048L46.1857 68.992H42.6817V85H46.0897V74.56L53.7217 85H57.0097ZM68.854 85C71.926 85 73.51 83.08 73.51 80.656C73.51 78.664 72.166 77.008 70.438 76.744C71.95 76.432 73.198 75.064 73.198 73.072C73.198 70.936 71.638 68.992 68.59 68.992H60.166V85H68.854ZM67.846 75.352H63.574V71.992H67.846C68.998 71.992 69.718 72.688 69.718 73.672C69.718 74.704 68.998 75.352 67.846 75.352ZM67.99 82H63.574V78.352H67.99C69.31 78.352 70.03 79.168 70.03 80.176C70.03 81.328 69.262 82 67.99 82ZM91.0081 85L84.8401 68.992H80.5681L74.4001 85H78.2641L79.2721 82.288H86.1361L87.1441 85H91.0081ZM85.2001 79.288H80.2081L82.7041 72.4L85.2001 79.288Z" fill="white"/>
                <path d="M49.661 45.182C54.1505 49.6715 60.0971 52.1425 66.4132 52.1425C72.7294 52.1425 78.6783 49.6715 83.1655 45.182C92.4028 35.9447 92.4028 20.9148 83.1655 11.6752C78.6783 7.18799 72.727 4.7146 66.4132 4.7146C60.0995 4.7146 54.1482 7.18799 49.661 11.6775C40.4237 20.9125 40.4237 35.9447 49.661 45.182ZM67.1453 9.4908C71.1021 9.63322 74.9121 11.0249 78.0292 13.4662L66.4132 25.0798L62.9543 21.6208C65.7049 18.1169 67.1998 13.7837 67.1453 9.4908ZM59.5664 18.2329L54.7997 13.4662C57.0375 11.7121 59.6443 10.4889 62.4236 9.88881C62.3881 12.8171 61.3693 15.7572 59.5664 18.2329ZM56.1927 21.5592C53.7794 23.208 50.9206 24.0816 47.9979 24.0634C48.6135 21.4277 49.7918 18.9562 51.4521 16.8186L56.1927 21.5592ZM47.4861 28.7756C47.6686 28.7804 47.851 28.8088 48.0334 28.8088C52.2024 28.8221 56.2609 27.469 59.5877 24.9566L63.0633 28.4298L51.4521 40.0409C48.9464 36.8137 47.5548 32.8609 47.4861 28.7756ZM76.6124 35.279C79.0811 33.4831 82.0141 32.4644 84.9234 32.4218C84.3307 35.1971 83.1183 37.8026 81.3768 40.0433L76.6124 35.279ZM78.0268 43.3933C75.8905 45.0632 73.4182 46.2513 70.7796 46.8759C70.7528 43.9424 71.6269 41.0713 73.2838 38.6502L78.0268 43.3933ZM66.0768 47.3877C61.9819 47.3225 58.0206 45.9197 54.7973 43.3933L66.4132 31.7797L69.8793 35.2458C67.2661 38.6668 65.9489 42.9834 66.0768 47.3877ZM85.3238 27.7001C81.0427 27.6574 76.7167 29.1452 73.2222 31.8887L69.7632 28.4298L81.3744 16.8186C83.7979 19.9426 85.1794 23.7488 85.3238 27.7001Z" fill="white"/>
            </svg>
        )
    }
}

export default NBASPIcon;