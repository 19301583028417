//imports
import React, { Component } from 'react';
import '../../index.scss';
import './game.scss'
import SpreadBox from './spreadBox.js'
import MLBox from './mlBox.js'
import OverBox from './overBox.js'
import UnderBox from './underBox.js'
import PropBet from './propBet';
import NoProp from './noProps';
import Config from '../../helpers/config.js';
import DrawBet from "./drawBet";
import AltSpread from "./altSpread.js";
import AltTotal from "./altTotal.js";

/**
 * An element that will represent a game.
 * Will show date, teams, lines, and props
 * Will be mapped on the home screen for each available game
 */
class Game extends Component {
	//constructor 
	constructor(props) {
		super(props);

		this.getPopupState = this.getPopupState.bind(this);
		this.addBetToParlay = this.addBetToParlay.bind(this);
		this.removeBetFromParlay = this.removeBetFromParlay.bind(this);

		//base states
		this.state = {
			seeMore: false,
			collapsed: false,
		}

		//defining the date string
		//will either be "Today, TIME", "Tomorrow, TIME", or "WEEKDAY, MONTH DAY, TIME"
		const dateInfo = this.props.date.split(" ");
		const dayInfo = dateInfo[0].split("-");
		const timeInfo = dateInfo[1].split(":");

		this.timestamp = new Date(dayInfo[0], dayInfo[1] - 1, dayInfo[2], parseInt(timeInfo[0]) + Config.TIME_DIFF, timeInfo[1], timeInfo[2]);

		let today = new Intl.DateTimeFormat('en-US', {
			month: 'numeric',
			day: 'numeric',
			year: 'numeric'
		}).format(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
		today = this.formatIntTimestamp(today);
		let tomorrow = new Intl.DateTimeFormat('en-US', {
			month: 'numeric',
			day: 'numeric',
			year: 'numeric'
		}).format(new Date(new Date().getTime() + 48 * 60 * 60 * 1000));
		tomorrow = this.formatIntTimestamp(tomorrow);
		let gameDay = new Intl.DateTimeFormat('en-US', {
			month: 'numeric',
			day: 'numeric',
			year: 'numeric'
		}).format(this.timestamp);
		gameDay = this.formatIntTimestamp(gameDay);

		if (gameDay < today) {
			this.dateString = "Today, " + new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: '2-digit' }).format(this.timestamp) + " ET";
		}
		else if (gameDay === today && gameDay < tomorrow) {
			this.dateString = "Tomorrow, " + new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: '2-digit' }).format(this.timestamp) + " ET";
		}
		else {
			this.dateString = new Intl.DateTimeFormat('en-US', { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' }).format(this.timestamp) + " ET";
		}

		//getting the names of the teams
		this.homeArr = this.props.homeTeam.split(" ");
		this.homeName = this.homeArr[this.homeArr.length - 1];

		this.awayArr = this.props.awayTeam.split(" ");
		this.awayName = this.awayArr[this.awayArr.length - 1];

		//dealing with the toggle more bets function
		this.toggleMoreBets = this.toggleMoreBets.bind(this);
	}

	/**
	 * Method to turn a time stamp formatted "12/12/1212" to "12121212"
	 * @param {*} stringTs the time stamp being changed
	 * @return a timestamp formatted as an int
	 */
	formatIntTimestamp(stringTs) {
		const splited = stringTs.split("/");
		const year = splited[2].toString();
		const month = splited[0].padStart(2, '0').toString();
		const day = splited[1].padStart(2, '0').toString();
		return parseInt(year + month + day);
	}

	static getDerivedStateFromProps(nextProps, prevState){
		if(nextProps !== prevState){
			return { props: nextProps};
		}
		else return null;
	}

	/**
	 * A method called when the more bets button is clicked
	 * Will switch the value of the see more state and the collapsed state
	 */
	toggleMoreBets = () => {
		this.setState({ seeMore: !this.state.seeMore, collapsed: !this.state.collapsed });
	}

	getPopupState = (val) => {
		this.props.sendPopupState(val);
	}

	addBetToParlay = (bet) => {
		this.props.addToParlay(bet);
	}

	removeBetFromParlay = (bet) => {
		this.props.removeFromParlay(bet);
	}

	/**
	 * render function
	 */
	render() {

		//defining props
		let props = this.props.propArr;
		//defining the more bets label to say either "See Less" or "More Bets" and the status of the game (collapsed or expanded)
		let moreBets = this.state.seeMore ? "See Less" : "More Bets";
		let status = this.state.collapsed ? "Expanded" : "Collapsed";

		return (
			<div id="game" className={`game${status}`}>
				<div className="top">
					<table>
						<thead>
							<tr>
								<th className="info" id="date">{this.dateString}</th>
								<th className="lines" id="spread">Spread</th>
								<th className="lines" id="money">Money</th>
								<th className="lines" id="total">Total</th>
							</tr>
						</thead>
						<tbody>
							<tr id="away">
								<td className="info" id="awayTeam">{this.props.awayTeam}</td>
								<td className="lines" ><SpreadBox currentParlay={this.props.currentParlay} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} parlayMode={this.props.parlayMode} sendPopupState={this.getPopupState} gameId={this.props.gameId} homeTeam={this.props.homeTeam} awayTeam={this.props.awayTeam} betType={3} points={this.props.awaySpreadPoints} odds={this.props.awaySpreadOdds} /></td>
								<td className="lines" ><MLBox currentParlay={this.props.currentParlay} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} parlayMode={this.props.parlayMode} sendPopupState={this.getPopupState} gameId={this.props.gameId} homeTeam={this.props.homeTeam} awayTeam={this.props.awayTeam} betType={1} odds={this.props.awayMoneyLine} /></td>
								<td className="lines" id="totals"><OverBox currentParlay={this.props.currentParlay} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} parlayMode={this.props.parlayMode} sendPopupState={this.getPopupState} gameId={this.props.gameId} homeTeam={this.props.homeTeam} awayTeam={this.props.awayTeam} betType={5} points={this.props.overPoints} odds={this.props.overOdds} /></td>
							</tr>
							<tr id="home">
								<td className="info" id="homeTeam">{this.props.homeTeam}</td>
								<td className="lines" ><SpreadBox currentParlay={this.props.currentParlay} addToParlay={this.props.addToParlay} removeFromParlay={this.props.removeFromParlay} parlayMode={this.props.parlayMode} sendPopupState={this.getPopupState} gameId={this.props.gameId} homeTeam={this.props.homeTeam} awayTeam={this.props.awayTeam} betType={4} points={this.props.homeSpreadPoints} odds={this.props.homeSpreadOdds} /></td>
								<td className="lines" ><MLBox currentParlay={this.props.currentParlay} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} parlayMode={this.props.parlayMode} sendPopupState={this.getPopupState} gameId={this.props.gameId} homeTeam={this.props.homeTeam} awayTeam={this.props.awayTeam} betType={2} odds={this.props.homeMoneyLine} /></td>
								<td className="lines" id="totals"><UnderBox currentParlay={this.props.currentParlay} addToParlay={this.props.addToParlay} removeFromParlay={this.props.removeFromParlay} parlayMode={this.props.parlayMode} sendPopupState={this.getPopupState} gameId={this.props.gameId} homeTeam={this.props.homeTeam} awayTeam={this.props.awayTeam} betType={6} points={this.props.underPoints} odds={this.props.underOdds} /></td>
							</tr>
						</tbody>
					</table>
					<button onClick={this.toggleMoreBets} id="moreBets">{moreBets}</button>
				</div>
				<div className="bottom">
					{this.state.seeMore ? (
						<div className='moreBetsList'>
							{(this.props.league === "NHL" && !(this.props.awaySpreadPoints === null || this.props.awaySpreadOdds === null || this.props.awaySpreadPoints === "0.0" || this.props.homeSpreadPoints === null || this.props.homeSpreadOdds === null || this.props.homeSpreadPoints === "0.0" || (Math.abs(this.props.homeSpreadPoints) !== 1.5))) ? (
								<AltSpread
									league={this.props.league}
									homeFull={this.props.homeTeam}
									home={this.homeName}
									awayFull={this.props.awayTeam}
									away={this.awayName}
									homeSpread={parseFloat(this.props.homeSpreadPoints)}
									awaySpread={parseFloat(this.props.awaySpreadPoints)}
									awayOdds={this.props.awaySpreadOdds}
									homeOdds={this.props.homeSpreadOdds}
									min={-2.5}
									max={2.5}
									gameId={this.props.gameId}
									sendPopupState={this.getPopupState}
									parlayMode={this.props.parlayMode}
									addToParlay={this.addBetToParlay}
									removeFromParlay={this.removeBetFromParlay}
									currentParlay={this.props.currentParlay}/>
							) : null}
							{(this.props.league === "NHL" && !(this.props.underPoints === null || this.props.underOdds === null || this.props.overOdds === null || this.props.overPoints === null)) ? (
								<AltTotal
									league={this.props.league}
									homeFull={this.props.homeTeam}
									home={this.homeName}
									awayFull={this.props.awayTeam}
									away={this.awayName}
									start={parseFloat(this.props.overPoints)}
									underOdds={this.props.underOdds}
									overOdds={this.props.overOdds}
									min={2.5}
									max={9.5}
									gameId={this.props.gameId}
									sendPopupState={this.getPopupState}
									parlayMode={this.props.parlayMode}
									addToParlay={this.addBetToParlay}
									removeFromParlay={this.removeBetFromParlay}
									currentParlay={this.props.currentParlay}/>
							) : null}
							{(this.props.league === "NBA" && !(this.props.awaySpreadPoints === null || this.props.awaySpreadOdds === null || this.props.awaySpreadPoints === "0.0" || this.props.homeSpreadPoints === null || this.props.homeSpreadOdds === null || this.props.homeSpreadPoints === "0.0")) ? (
								<AltSpread
									league={this.props.league}
									homeFull={this.props.homeTeam}
									home={this.homeName}
									awayFull={this.props.awayTeam}
									away={this.awayName}
									homeSpread={parseFloat(this.props.homeSpreadPoints)}
									awaySpread={parseFloat(this.props.awaySpreadPoints)}
									awayOdds={this.props.awaySpreadOdds}
									homeOdds={this.props.homeSpreadOdds}
									min={parseFloat(this.props.homeSpreadPoints) < 0 ? parseFloat(this.props.homeSpreadPoints) - 15 : parseFloat(this.props.awaySpreadPoints) - 15}
									max={parseFloat(this.props.homeSpreadPoints) > 0 ? parseFloat(this.props.homeSpreadPoints) + 15 : parseFloat(this.props.awaySpreadPoints) + 15}
									gameId={this.props.gameId}
									sendPopupState={this.getPopupState}
									parlayMode={this.props.parlayMode}
									addToParlay={this.addBetToParlay}
									removeFromParlay={this.removeBetFromParlay}
									currentParlay={this.props.currentParlay}/>
							) : null}
							{(this.props.league === "NBA" && !(this.props.underPoints === null || this.props.underOdds === null || this.props.overOdds === null || this.props.overPoints === null)) ? (
								<AltTotal
									league={this.props.league}
									homeFull={this.props.homeTeam}
									home={this.homeName}
									awayFull={this.props.awayTeam}
									away={this.awayName}
									start={parseFloat(this.props.overPoints)}
									underOdds={this.props.underOdds}
									overOdds={this.props.overOdds}
									min={parseFloat(this.props.overPoints) - 15}
									max={parseFloat(this.props.overPoints) + 15}
									gameId={this.props.gameId}
									sendPopupState={this.getPopupState}
									parlayMode={this.props.parlayMode}
									addToParlay={this.addBetToParlay}
									removeFromParlay={this.removeBetFromParlay}
									currentParlay={this.props.currentParlay}/>
							) : null}
							{!!this.props.drawOdds ? (
								<DrawBet
									gameId={this.props.gameId}
									homeTeam={this.props.homeTeam}
									awayTeam={this.props.awayTeam}
									odds={this.props.drawOdds}
									sendPopupState={this.getPopupState}
									parlayMode={this.props.parlayMode}
									addToParlay={this.addBetToParlay}
									removeFromParlay={this.removeBetFromParlay}
									currentParlay={this.props.currentParlay}/>
								) : null
							}
							{this.props.numOfProps > 0 ? (
								props.map((prop, index) => {
									return <PropBet
										betType="7"
										name={prop.name}
										optionOneName={prop.optionOne}
										optionOneOdds={prop.optionOneOdds}
										optionTwoName={prop.optionTwo}
										optionTwoOdds={prop.optionTwoOdds}
										optionThreeName={prop.optionThree}
										optionThreeOdds={prop.optionThreeOdds}
										optionFourName={prop.optionFour}
										optionFourOdds={prop.optionFourOdds}
										teams={prop.teams}
										key={index}
										gameId={this.props.gameId}
										propId={prop.propId}
										sendPopupState={this.getPopupState}
										addToParlay={this.addBetToParlay}
										removeFromParlay={this.removeBetFromParlay}
										parlayMode={this.props.parlayMode}
										currentParlay={this.props.currentParlay}
									/>
								})
							) : null}
							{!!!this.props.drawOdds && this.props.numOfProps === 0 ? (
								<NoProp />
							) : null}
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export default Game;