import Config from './config.js';

/**
 * A class that will hold all the constants used in the project
 */
class Algos {

    /////NHL SPREAD/////
    AVG_NHL_FAV_START_SPREAD_ODDS = 2.826;
    AVG_NHL_DOG_START_SPREAD_ODDS = 1.47759945;

    NHL_POS_CHANGE_POS_SO = (americanOdds, diffInSpread) => {
        const startOdds = Config.americanToDecimal(americanOdds);
        const newOdds = startOdds + (startOdds * (0.0523 + (1.24 * diffInSpread) + (0.3 * diffInSpread * diffInSpread)) / (this.AVG_NHL_FAV_START_SPREAD_ODDS));
        return Config.decimalToAmerican(newOdds);
    }

    NHL_NEG_CHANGE_POS_SO = (americanOdds, diffInSpread) => {
        const startOdds = Config.americanToDecimal(americanOdds);
        let newOdds = startOdds - (startOdds * (0.104 + (-0.751 * diffInSpread) + (-0.0801 * diffInSpread * diffInSpread)) / (1.1 * this.AVG_NHL_FAV_START_SPREAD_ODDS));
        newOdds = newOdds < 1 ? 1.001 : newOdds;
        return Config.decimalToAmerican(newOdds);
    }

    NHL_POS_CHANGE_NEG_SO = (americanOdds, diffInSpread) => {
        const startOdds = Config.americanToDecimal(americanOdds)
        const newOdds = startOdds + (startOdds * (0.0281+ (0.294 * diffInSpread) + (0.186 * diffInSpread * diffInSpread)) / (1.1 * this.AVG_NHL_FAV_START_SPREAD_ODDS));
        return Config.decimalToAmerican(newOdds);
    }

    NHL_NEG_CHANGE_NEG_SO = (americanOdds, diffInSpread) => {
        const startOdds = Config.americanToDecimal(americanOdds);
        let newOdds = startOdds - (startOdds * (0.0081 + (-0.266 * diffInSpread) + (-0.0199 * diffInSpread * diffInSpread)) / (1.1 * this.AVG_NHL_FAV_START_SPREAD_ODDS));
        newOdds = newOdds < 1 ? 1.001 : newOdds;
        return Config.decimalToAmerican(newOdds);
    }

    NHL_CLEAN_SPREAD = (odds, points, startPoints, startOdds) => {
        odds = points === startPoints ? startOdds : odds;
        return odds;
    }

    /////NHL TOTALS/////
    AVG_NHL_OVER_START_ODDS = -114.14;
    AVG_NHL_UNDER_START_ODDS = -115;

    NHL_POS_CHANGE_OVER = (startOdds, diffInTotal) => {
        let newOdds = startOdds + (startOdds * 38.3 / this.AVG_NHL_OVER_START_ODDS) + (((startOdds * 155) / this.AVG_NHL_OVER_START_ODDS) * diffInTotal) + (((startOdds * 21.7) / this.AVG_NHL_OVER_START_ODDS) * (diffInTotal * diffInTotal));
        return startOdds > 0 ? newOdds * -1 : newOdds;
    }

    NHL_NEG_CHANGE_OVER = (startOdds, diffInTotal) => {
        let newOdds = startOdds + ((startOdds * -47) / this.AVG_NHL_OVER_START_ODDS) + (((startOdds * 13.2) / this.AVG_NHL_OVER_START_ODDS) * diffInTotal) + (((startOdds * -139) / this.AVG_NHL_OVER_START_ODDS) * (diffInTotal * diffInTotal));
        return startOdds > 0 ? newOdds * -1 : newOdds;
    }

    NHL_POS_CHANGE_UNDER = (startOdds, diffInTotal) => {
        diffInTotal *= -1;
        let newOdds = startOdds + ((startOdds * 95.6) / this.AVG_NHL_UNDER_START_ODDS) + (((startOdds * -17.6) / this.AVG_NHL_UNDER_START_ODDS) * diffInTotal) + (((startOdds * 126) / this.AVG_NHL_UNDER_START_ODDS) * (diffInTotal * diffInTotal));
        return startOdds > 0 ? newOdds * -1 : newOdds;
    }

    NHL_NEG_CHANGE_UNDER = (startOdds, diffInTotal) => {
        diffInTotal *= -1;
        let newOdds = startOdds - 50 - ((startOdds * 1.22) / this.AVG_NHL_UNDER_START_ODDS) + (((startOdds * -73.8) / this.AVG_NHL_UNDER_START_ODDS) * diffInTotal) + (((startOdds * -67.4) / this.AVG_NHL_UNDER_START_ODDS) * (diffInTotal * diffInTotal));
        return startOdds > 0 ? newOdds * -1 : newOdds;
    }

    NHL_CLEAN_TOTAL = (odds, points, startPoints, startOdds, type) => {
        odds = startPoints - points > -1.5 && startPoints - points < 0 ? odds + 100 : odds;
        odds = startPoints - points > -2.5 && startPoints - points <= -1.5 ? odds + 50 : odds;
        odds = startPoints - points < 1.5 && startPoints - points > 0 ? odds + 100 : odds;
        odds = (type === 5 && startPoints - points === -0.5 && Math.abs(odds - startOdds) < 50) ? odds + 50 : odds;
        odds = (type === 5 && startPoints - points === 0.5 && Math.abs(odds - startOdds) < 50) ? odds - 50 : odds;
        odds = (type === 6 && startPoints - points === 0.5 && Math.abs(odds - startOdds) < 50) ? odds + 50 : odds;
        odds = (type === 6 && startPoints - points === -0.5 && Math.abs(odds - startOdds) < 50) ? odds - 50 : odds;
        odds = odds > 50 && odds < 100 ? 100 : odds;
        odds = odds > -100 && odds < 50 ? -110 : odds;
        odds = parseFloat(points) === startPoints ? startOdds : odds;

        return odds;
    }

    /////NBA SPREAD/////
    AVG_NBA_FAV_START_SPREAD_ODDS = -109.5;
    AVG_NBA_DOG_START_SPREAD_ODDS = -111.8;

    NBA_POS_CHANGE_FAV = (startOdds, diffInSpread) => {
        return startOdds + (startOdds * 206 / this.AVG_NBA_FAV_START_SPREAD_ODDS) + (((startOdds * 9.19) / this.AVG_NBA_FAV_START_SPREAD_ODDS) * diffInSpread) + (((startOdds * 1.56) / this.AVG_NBA_FAV_START_SPREAD_ODDS) * (diffInSpread * diffInSpread));
    }

    NBA_NEG_CHANGE_FAV = (startOdds, diffInSpread) => {
        return startOdds + ((startOdds * -17.5) / this.AVG_NBA_FAV_START_SPREAD_ODDS) + (((startOdds * -3.64) / this.AVG_NBA_FAV_START_SPREAD_ODDS) * diffInSpread) + (((startOdds * -2.71) / this.AVG_NBA_FAV_START_SPREAD_ODDS) * (diffInSpread * diffInSpread));
    }

    NBA_POS_CHANGE_DOG = (startOdds, diffInSpread) => {
        return startOdds + (startOdds * 222 / this.AVG_NBA_DOG_START_SPREAD_ODDS) + (((startOdds * -1.15) / this.AVG_NBA_DOG_START_SPREAD_ODDS) * diffInSpread) + (((startOdds * 1.74) / this.AVG_NBA_DOG_START_SPREAD_ODDS) * (diffInSpread * diffInSpread));
    }

    NBA_NEG_CHANGE_DOG = (startOdds, diffInSpread) => {
        return startOdds + (startOdds * -17.1 / this.AVG_NBA_DOG_START_SPREAD_ODDS) + (((startOdds * -1.43) / this.AVG_NBA_DOG_START_SPREAD_ODDS) * diffInSpread) + (((startOdds * -3.41) / this.AVG_NBA_DOG_START_SPREAD_ODDS) * (diffInSpread * diffInSpread));
    }

    NBA_CLEAN_SPREAD = (odds, points, startPoints, startOdds) => {
        odds = odds > 50 && odds < 100 ? 100 : odds;
        odds = odds > -100 && odds < 50 ? -110 : odds;
        odds = points === startPoints ? startOdds : odds;

        return odds;
    }

    /////NBA TOTALS/////
    AVG_NBA_OVER_START_ODDS = -111;
    AVG_NBA_UNDER_START_ODDS = -110;

    NBA_POS_CHANGE_OVER = (startOdds, diffInTotal) => {
        let newOdds = startOdds + (startOdds * 208 / this.AVG_NBA_OVER_START_ODDS) + (((startOdds * 4.86) / this.AVG_NBA_OVER_START_ODDS) * diffInTotal) + (((startOdds * .71) / this.AVG_NBA_OVER_START_ODDS) * (diffInTotal * diffInTotal));
        return startOdds > 0 ? newOdds * -1 : newOdds;
    }

    NBA_NEG_CHANGE_OVER = (startOdds, diffInTotal) => {
        let newOdds = startOdds + ((startOdds * -14.2) / this.AVG_NBA_OVER_START_ODDS) + (((startOdds * -.407) / this.AVG_NBA_OVER_START_ODDS) * diffInTotal) + (((startOdds * -1.69) / this.AVG_NBA_OVER_START_ODDS) * (diffInTotal * diffInTotal));
        return startOdds > 0 ? newOdds * -1 : newOdds;
    }

    NBA_POS_CHANGE_UNDER = (startOdds, diffInTotal) => {
        diffInTotal *= -1;
        let newOdds = startOdds + ((startOdds * 211) / this.AVG_NBA_UNDER_START_ODDS) + (((startOdds * -3.13) / this.AVG_NBA_UNDER_START_ODDS) * diffInTotal) + (((startOdds * .921) / this.AVG_NBA_UNDER_START_ODDS) * (diffInTotal * diffInTotal));
        return startOdds > 0 ? newOdds * -1 : newOdds;
    }

    NBA_NEG_CHANGE_UNDER = (startOdds, diffInTotal) => {
        diffInTotal *= -1;
        let newOdds = startOdds + ((startOdds * -8.07) / this.AVG_NBA_UNDER_START_ODDS) + (((startOdds * -3.59) / this.AVG_NBA_UNDER_START_ODDS) * diffInTotal) + (((startOdds * -1.19) / this.AVG_NBA_UNDER_START_ODDS) * (diffInTotal * diffInTotal));
        return startOdds > 0 ? newOdds * -1 : newOdds;
    }

    NBA_CLEAN_TOTAL = (odds, points, startPoints, startOdds) => {
        odds = odds > 50 && odds < 100 ? 100 : odds;
        odds = odds > -100 && odds < 50 ? -110 : odds;
        odds = points === startPoints ? startOdds : odds;

        return odds;
    }
}

export default new Algos();