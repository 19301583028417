//imports
import React, { Component } from 'react';
import Popup from './popup.js'
import './altBox.scss';
import {toBase64} from "js-base64";

/**
 * An element to represent an alternate spread odds box
 */
class AltSpreadBox extends Component {
    //constructor
    constructor(props) {
        super(props);

        let parlayId = toBase64(this.props.gameId.toString() + this.props.points + this.props.betType + this.props.odds);

        this.state = {
            points: this.props.points.toFixed(1),
            odds: this.props.odds,
            showPopup: false,
            selectedForParlay: this.props.currentParlay.some(c => c.parlayBetId === parlayId),
            parlayId: toBase64(this.props.gameId.toString() + this.props.points + this.props.betType + this.props.odds)
        }

        //toggling the popup
        this.togglePopup = this.togglePopup.bind(this);
    }

    componentDidUpdate(prevProps) {
        const bet = { parlayBetId: this.state.parlayId }
        if (prevProps.points !== this.props.points) {
            this.setState({
                points: parseFloat(this.props.points).toFixed(1),
                selectedForParlay: false,
                parlayId: toBase64(this.props.gameId.toString() + this.props.points + this.props.betType + this.props.odds)
            });
            this.props.removeFromParlay(bet);
        }
        if (prevProps.odds !== this.props.odds) {
            this.setState({
                odds: this.props.odds,
                selectedForParlay: false,
                parlayId: toBase64(this.props.gameId.toString() + this.props.points + this.props.betType + this.props.odds)
            });
            this.props.removeFromParlay(bet);
        }
    }


    /**
     * A method to toggle the popup
     */
    togglePopup() {
        if (this.props.parlayMode) {
            this.setState({ selectedForParlay: !this.state.selectedForParlay, });
            const parlayInfo = ({
                parlayBetId: toBase64(this.props.gameId.toString() + this.props.points + this.props.betType + this.props.odds),
                betInfo: this.props
            });
            !this.state.selectedForParlay ?  this.props.addToParlay(parlayInfo) : this.props.removeFromParlay(parlayInfo);
        } else {
            this.setState({
                showPopup: !this.state.showPopup,
            });
            this.props.sendPopupState(!this.state.showPopup ? "Popup Enabled" : "Popup Disabled");
        }
    }

    /**
     * render method
     */
    render() {
        //creating the odds and points string
        let oddsStr = this.state.odds > 0 ? `+${this.state.odds}` : `${this.state.odds}`;
        if (oddsStr === "undefined") {
            oddsStr = this.props.odds > 0 ? `+${this.props.odds}` : `${this.props.odds}`;
        }
        let pointsStr = this.state.points > 0 ? `+${this.state.points}` : `${this.state.points}`;
        if (pointsStr === "undefined") {
            pointsStr = this.props.points > 0 ? `+${parseFloat(this.props.points).toFixed(1)}` : `${parseFloat(this.props.points).toFixed(1)}`;
        }

        return (
            <div>
                <button {...this.state.selectedForParlay && this.props.parlayMode ? {className: "parlay-on"} : null} id="alt-spread-button" onClick={this.togglePopup.bind(this)}>
                    <div id="team-spread-val">{this.props.team} {pointsStr}</div>
                    <p id="spread-odds">{oddsStr}</p>
                </button>

                {this.state.showPopup ?
                    <Popup
                        closePopup={this.togglePopup.bind(this)}
                        gameId={this.props.gameId}
                        awayTeam={this.props.awayTeam}
                        homeTeam={this.props.homeTeam}
                        betType={this.props.betType}
                        odds={oddsStr}
                        points={pointsStr}
                    />
                    :
                    null
                }
            </div>
        );
    }
}

export default AltSpreadBox;