import React from 'react';

class NcaafSbIcon extends React.Component {
    render() {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.2 7.99413C8.88174 7.99413 8.57652 8.13451 8.35147 8.38437C8.12643 8.63424 8 8.97313 8 9.32649C8 9.67985 8.12643 10.0187 8.35147 10.2686C8.57652 10.5185 8.88174 10.6588 9.2 10.6588C9.51826 10.6588 9.82348 10.5185 10.0485 10.2686C10.2736 10.0187 10.4 9.67985 10.4 9.32649C10.4 8.97313 10.2736 8.63424 10.0485 8.38437C9.82348 8.13451 9.51826 7.99413 9.2 7.99413ZM9.2 0C12.952 0 16 3.17989 16 7.1059C16 8.54484 16 9.77061 15.272 11.5471C12 11.5471 11.2 15.1 8.4 15.1C6.656 15.1 5.816 13.5723 5.64 11.5471H4.992L3.968 15.3665C3.91223 15.5733 3.79051 15.7506 3.6256 15.865C3.4607 15.9794 3.2639 16.0232 3.072 15.9883H0.8C0.587827 15.9883 0.384344 15.8947 0.234315 15.7281C0.0842854 15.5615 0 15.3356 0 15.1C0 14.8645 0.0842854 14.6385 0.234315 14.4719C0.384344 14.3054 0.587827 14.2118 0.8 14.2118V11.5471C0.587827 11.5471 0.384344 11.4535 0.234315 11.2869C0.0842854 11.1203 0 10.8944 0 10.6588C0 10.4233 0.0842854 10.1973 0.234315 10.0308C0.384344 9.86419 0.587827 9.77061 0.8 9.77061H3.8L4.184 8.34055C3.776 8.11849 3.304 7.99413 2.8 7.99413H2.456L2.4 7.1059C2.4 3.17989 5.448 0 9.2 0ZM2.4 11.5471V14.2118H2.608L3.32 11.5471H2.4Z" fill="#3DF5D9"/>
            </svg>
        )
    }
}

export default NcaafSbIcon;