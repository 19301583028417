//imports
import React, { Component } from 'react';
import Header from '../../elements/header/header.js';
import Menu from '../../elements/accountDash/menu.js';
import Trends from '../../elements/accountDash/trends.js'
import LiveBets from '../../elements/accountDash/liveBets.js'
import UpcomingBets from '../../elements/accountDash/upcomingBets.js'
import PastBets from '../../elements/accountDash/pastBets.js'
import LeaderBoard from '../../elements/accountDash/leaderboard.js'
import Info from '../../elements/accountDash/info.js'
import './accountPage.scss';
import axios from 'axios';
import Loader from 'react-loader-spinner'
import UserProfile from '../../helpers/userProfile.js';
import { withRouter } from 'react-router-dom'
import Footer from '../../elements/footer/footer.js';
import Error from '../../elements/error/error.js';
import MenuMobile from "../../elements/accountDash/menu_mobile";

/**
 * A component representing the users dashboard of the web app
 * Users can access this page by clicking the profile icon in the top right of the header
 * User can view their live bets, past bets, leaderboard, and site stats and info
 * Users can also update their email, username, and passwords here, as well as restart if their bank is low enough
 */
class Account extends Component {
	//constructor
	constructor(props) {
		super(props);

		//checking to see if the user is logged in and setting user stats and login status accordingly
		if (!UserProfile.getIsLoggedIn() || !UserProfile.getUserInfo()) {
			this.props.history.push('/');
		}
		UserProfile.updateStats();

		//get isLoggedIn value and user profile
		this.user = UserProfile.getUserInfo();
		this.isLoggedIn = UserProfile.getIsLoggedIn();

		//base states
		this.state = {
			currentItem: !!sessionStorage.getItem('currentItem') ? JSON.parse(sessionStorage.getItem('currentItem')) - 1 : 0,
			error: null,
			isLoaded: false,
			userLiveBets: [],
			userUpcomingBets: [],
			userPastBets: [],
			leaderboard: [],
			stats: [],
			trends: [],
			leaderboardPosition: null,
		};
	}

	/**
	 * Method that will be called when the component mounts
	 * Used to get the data needed for the page from the data base
	 * Gets live bets, past bets, leaderboard, and account info
	 */
	componentDidMount() {
		//if the user is logged in
		if (this.isLoggedIn) {
			const userEmail = this.user.email;
			const apiKey = process.env.REACT_APP_APIK;
			//get user live bets
			axios.post(process.env.REACT_APP_APIL + "userLiveBets", { apiKey, userEmail })
				.then(result => {
					// console.log(result.data);
					const bets = result.data;
					this.setState({
						isLoaded: true,
						userLiveBets: bets.live,
						userUpcomingBets: bets.upcoming,
						leaderboardPosition: bets.leaderBoardPosition,
					});
				})
				.catch(error => {
					console.log(error);
				});
			//get other account info (past bets, leaderboard, stats)
			axios.post(process.env.REACT_APP_APIL + "userAccountInfo", { apiKey, userEmail })
				.then(result => {
					// console.log(result.data);
					this.setState({
						isLoaded: true,
						userPastBets: result.data.pastBets,
						leaderboard: result.data.leaderboard,
						stats: result.data.stats,
						trends: result.data.trends,
					});
				})
				.catch(error => {
					console.log(error);
					this.setState({
						isLoaded: true,
						error: error
					})
				});
		}
	}

	/**
	 * method to update the current index of the menu
	 * @param {int} newIndx the new index that the menu will switch to
	 */
	updateMenuItem = newIndx => {
		this.setState({ currentItem: newIndx - 1 });
	}


	/**
	 * render method
	 */
	render() {
		//from the axios requests
		const { error, isLoaded, userPastBets, leaderboard, stats } = this.state;
		//return the correct state whether the user is logged in or not
		if (this.isLoggedIn !== true) {
			this.props.history.push('/');
			return null;
		}
		//error component if there is an error
		else if (error) {
			return <Error message={error.message}/>;
		}
		//loader component if the component is loading
		else if (!isLoaded) {
			return <div id="loader"><Loader type="Oval" color="#3DF5D9" height={100} width={100} /></div>;
		} else {
			sessionStorage.removeItem("currentItem");
			return (
				<div className="page-container">
					<div id="header">
						<Header liveBets={this.state.userLiveBets} upcomingBets={this.state.userUpcomingBets} bank={this.user.bank} username={this.user.username} userPos={this.state.leaderboardPosition} />
					</div>
					<div id="dashContent">
						<div id="menu-container">
							<Menu startItem={this.state.currentItem} onChange={this.updateMenuItem} />
						</div>
						<div id="menu-container-mobile">
							<MenuMobile startItem={this.state.currentItem} onChange={this.updateMenuItem} />
						</div>
						<div id="account-dash-container">
							{this.state.currentItem === 0 ?
								(
									<Trends trends={this.state.trends}/>
								) : null}
							{this.state.currentItem === 1 ?
								(
									<LiveBets liveBets={this.state.userLiveBets}/>
								) : null}
							{this.state.currentItem === 2 ?
								(
									<UpcomingBets upcomingBets={this.state.userUpcomingBets}/>
								) : null}
							{this.state.currentItem === 3 ?
								(
									<PastBets pastBets={userPastBets}/>
								) : null}
							{this.state.currentItem === 4 ?
								(
									<Info stats={stats}/>
								) : null}
						</div>
						<div id="leaderboard-container">
							<LeaderBoard user={this.user.username} leaderboard={leaderboard} />
						</div>
					</div>
					<div id="footer">
						<Footer />
					</div>
				</div>
			);
		}
	}
}

export default withRouter(Account);