//imports
import React, { Component } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import './codeScreen.scss';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import ReactGA from "react-ga";

/**
 * An element to be the screen where the user can enter their password reset code
 * Will contain a form that just accepts one value
 */
class ResetCodeForm extends Component {
	//constructor
	constructor(props) {
		super(props);

		//getting user email from data passed to this page
		this.userEmail = this.props.location.data;

		//base state
		this.state = {
			dbError: false,
			dbErrorInfo: "",
		}
	}

	/**
	 * render method
	 */
	render() {
		return (
			<Formik
				initialValues={{ code: "", email: this.userEmail }}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						setSubmitting(false);
					}, 500);
					if (values.email === undefined) {
						this.setState({ dbError: true, dbErrorInfo: "Something went wrong! Please try again" });
					}
					else {
						const apiKey = process.env.REACT_APP_APIK;
						axios.post(process.env.REACT_APP_APIL + "resetCode", { apiKey, values })
							.then(res => {
								// console.log(res);
								// console.log(res.data);
								const responseCode = parseInt(res.data)
								if (responseCode > 0) {
									this.setState({ dbError: true });
									if (responseCode === 1) {
										this.setState({ dbErrorInfo: "That code is incorrect!" });
									}
									else if (responseCode === 2) {
										this.setState({ dbErrorInfo: "The code you entered has expired!" });
									}
								}
								else if (responseCode === 0) {
									this.setState({ dbError: false, dbErrorInfo: "" });

									//create ga event
									ReactGA.event({
										category: 'Forgot Password',
										action: 'Successful code entry',
									});

									this.props.history.push({
										pathname: "/resetpw",
										data: values.email
									});
								}
								else {
									this.setState({ dbError: true, dbErrorInfo: "Something went wrong! Please try again" });
								}
							})
							.catch(error => {
								console.log(error);
								this.setState({ dbError: true, dbErrorInfo: "Something went wrong! Please try again" });
							});
						}
				}}
				validationSchema={Yup.object().shape({
					code: Yup.string()
						.required("Please enter the code"),
				})}>

				{props => {
					const {
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit
					} = props;

					return (
						<div className="codeForm">
							<h1>Password Reset Code</h1>
							<div className={`dbError${this.state.dbError}`}>
								<div className="dbError-feedback">{this.state.dbErrorInfo}</div>
							</div>
							<form onSubmit={handleSubmit}>
								<div id="code">
									<input
										id="code"
										name="code"
										type="text"
										placeholder="Code:"
										autoComplete='off'
										value={values.code}
										onChange={handleChange}
										onBlur={handleBlur}
										className={errors.code && touched.code && "error"}
									/>
									{errors.code && touched.code && (
										<div className="input-feedback">{errors.code}</div>
									)}
								</div>
								<div className="loginButton">
									<button type="submit" id="login" disabled={isSubmitting}>
										<p>SUBMIT</p>
									</button>
								</div>
								<div className="dontHaveAccount">
									<p>Didn't get a code? <a href="/login">Try Again</a></p>
								</div>
							</form>
						</div>
					);
				}}
			</Formik>
		);
	}
}

export default withRouter(ResetCodeForm);