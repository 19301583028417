//imports
import React, { Component } from 'react';
import './altLine.scss';
import Algos from "../../helpers/algos.js";
import AltTotalBox from "./altTotalBox";

/**
 * An element to represent a money line box in the game element
 * Will display the odds of the money line bet for that team
 */
class AltTotal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.start,
            under: this.props.underOdds,
            over: this.props.overOdds,
        }

        this.sendPopupState = this.sendPopupState.bind(this);
        this.addBetToParlay = this.addBetToParlay.bind(this);
        this.removeBetFromParlay = this.removeBetFromParlay.bind(this);
    }

    sendPopupState = (val) => {
        this.props.sendPopupState(val);
    }

    addBetToParlay = (bet) => {
        this.props.addToParlay(bet);
    }

    removeBetFromParlay = (bet) => {
        this.props.removeFromParlay(bet);
    }

    handleChange = (event) => {

        let val = event.target.value;
        let over = this.state.over;
        let under = this.state.under;

        //update the point values
        if (val >= 0 && val < 1) {
            val = 1;
        }
        if (val <= 0 && val > -1) {
            val = -1;
        }

        let change = this.props.start - val;

        //update the odds value
        //NHL
        if (this.props.league === "NHL") {
            if (change <= 0) {
                over = Algos.NHL_POS_CHANGE_OVER(this.props.overOdds, -change);
                under = Algos.NHL_NEG_CHANGE_UNDER(this.props.underOdds, change);
            }
            else {
                over = Algos.NHL_NEG_CHANGE_OVER(this.props.overOdds, -change);
                under = Algos.NHL_POS_CHANGE_UNDER(this.props.underOdds, change);
            }
            over = Algos.NHL_CLEAN_TOTAL(over, val, this.props.start, this.props.overOdds, 5);
            under = Algos.NHL_CLEAN_TOTAL(under, val, this.props.start, this.props.underOdds, 6);
        }

        //NBA
        else if (this.props.league === "NBA") {
            if (change <= 0) {
                over = Algos.NBA_POS_CHANGE_OVER(this.props.overOdds, -change);
                under = Algos.NBA_NEG_CHANGE_UNDER(this.props.underOdds, change);
            }
            else {
                over = Algos.NBA_NEG_CHANGE_OVER(this.props.overOdds, -change);
                under = Algos.NBA_POS_CHANGE_UNDER(this.props.underOdds, change);
            }
            over = Algos.NBA_CLEAN_TOTAL(over, val, this.props.start, this.props.overOdds);
            under = Algos.NBA_CLEAN_TOTAL(under, val, this.props.start, this.props.underOdds);
        }

        this.setState({value: val, over: over.toFixed(0), under: under.toFixed(0)});
    }

    /**
     * render method
     */
    render() {
        return (
            <div id="alt-line">
                <h2 id="alt-line-header">Alternate Total</h2>
                <div id="slider">
                    <input type="range" step={.5} min={this.props.min} max={this.props.max} value={this.state.value} onChange={this.handleChange}/>
                </div>
                <table id="alt-spread-boxes">
                    <tbody>
                    <tr>
                        <td id="alt-data">
                            <AltTotalBox currentParlay={this.props.currentParlay} parlayMode={this.props.parlayMode} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} sendPopupState={this.sendPopupState} team={this.props.away} awayTeam={this.props.awayFull} homeTeam={this.props.homeFull} points={this.state.value} odds={this.state.over} gameId={this.props.gameId} betType={5} />
                        </td>
                        <td id="alt-data">
                            <AltTotalBox currentParlay={this.props.currentParlay} parlayMode={this.props.parlayMode} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} sendPopupState={this.sendPopupState} team={this.props.home} awayTeam={this.props.awayFull} homeTeam={this.props.homeFull} points={this.state.value} odds={this.state.under} gameId={this.props.gameId} betType={6}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AltTotal;