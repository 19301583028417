//imports
import React, { Component } from 'react';
import '../../index.scss';
import './leaderboard.scss';

/**
 * An element that will display the leader board
 * Will contain the top 5 users in points and display their username, points, and restarts
 */
class Leaderboard extends Component {

	/**
	 * render methof
	 */
	render() {
		let i = 0;
		return (
			<div className="leaderboard">
				<h1 id="title">Global Leaderboard</h1>
				<div id="leaderboardTable">
					<table>
						<thead>
							<tr><th id="leaderboardInfo">Rank</th><th id="leaderboardInfo">Username</th><th id="leaderboardInfo">Points</th><th id="leaderboardInfo">Restarts</th></tr>
						</thead>
						<tbody>
							{this.props.leaderboard ? (
								this.props.leaderboard.map(leader => (
									leader.username === this.props.user ? (
										<tr id="me" key={i++}><td id="leaderboardInfo">#{leader.position}</td><td id="leaderboardInfo">{leader.username}</td><td id="leaderboardInfo">{leader.bank.toFixed(2)}</td><td id="leaderboardInfo">{leader.restarts}</td></tr>
									) : (
										<tr key={i++}><td id="leaderboardInfo">#{leader.position}</td><td id="leaderboardInfo">{leader.username}</td><td id="leaderboardInfo">{leader.bank.toFixed(2)}</td><td id="leaderboardInfo">{leader.restarts}</td></tr>
									)
									)
								)
							) : null}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default Leaderboard;