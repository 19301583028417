//imports
import axios from 'axios';
import Cookie from './cookies.js';

/**
 * A class representing the users profile
 * Will help retrieving and setting all info in local/session storage
 */
class UserProfile {

    /**
     * A method to store all the users data in local/session storage
     * @param userData the users data being stored
     */
    storeUserProfile = (userData) => {
        //if the remember me checkbox was clicked, store in local storage
        if (Cookie.getCookie("rememberMe")) {
            localStorage.setItem('userInfo', JSON.stringify(userData));
            localStorage.setItem('isLoggedIn', JSON.stringify(true));
            sessionStorage.clear();
        }
        //otherwise store in session storage
        else {
            sessionStorage.setItem('userInfo', JSON.stringify(userData));
            sessionStorage.setItem('isLoggedIn', JSON.stringify(true));
            localStorage.clear();
        }
    }

    /**
     * A method to clear the user profile
     * Will be called when a user gets logged out to clear the local/session storage
     */
    clearUserProfile = () => {
        document.cookie = "rememberMe=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        localStorage.clear();
        sessionStorage.clear();

        localStorage.setItem('isLoggedIn', JSON.stringify(false));
        sessionStorage.setItem('isLoggedIn', JSON.stringify(false));
    }

    /**
     * A method to update the users data in local/session storage
     */
    updateStats = () => {
        let userEmail;
        //if there is nothing in the user data currently then clear the profile
        if (!this.getUserInfo()) {
            this.clearUserProfile();
        }
        //otherwise get the user stats and update them in storage
        else {
            //storing the users email
            userEmail = this.getEmail();
            const apiKey = process.env.REACT_APP_APIK;
            //getting all the users info from the db
            axios.post(process.env.REACT_APP_APIL + "updateUser", { apiKey, userEmail })
            .then(result => {
                // console.log(result);
                // console.log(result.data);
                //if the remember me checkbox was clicked then update local storage
                if (Cookie.getCookie("rememberMe")) {
                    Cookie.setCookie("rememberMe", "true", 180);
                    localStorage.setItem('userInfo', JSON.stringify(result.data));
                }
                //otherwise update the session storage
                else {
                    sessionStorage.setItem('userInfo', JSON.stringify(result.data));
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    /**
     * A method to get all the user info from local/session storage
     * @returns a user object full of their data from storage
     */
    getUserInfo = () => {
        //if the remember me checkbox was clicked, get info from local storage
        if (Cookie.getCookie("rememberMe")) {
            return JSON.parse(localStorage.getItem('userInfo'));
        }
        //otherwise get info from session storage
        else {
            return JSON.parse(sessionStorage.getItem('userInfo'));
        }
    }

    /**
     * A method to get if the user is logged in or not from local/session storage
     * @returns a boolean saying whether they are logged in or not from storage
     */
    getIsLoggedIn = () => {
        //if the remember me checkbox was clicked, get info from local storage
        if (Cookie.getCookie("rememberMe")) {
            return JSON.parse(localStorage.getItem('isLoggedIn'));
        }
        //otherwise get info from session storage
        else {
            return JSON.parse(sessionStorage.getItem('isLoggedIn'));
        }
    }

    /**
     * A method to get a users bank from local/session storage
     * @returns {string} the users bank
     */
    getBank = () => {
        //if the remember me checkbox was clicked, get info from local storage
        if (Cookie.getCookie("rememberMe")) {
            return JSON.parse(localStorage.getItem('userInfo')).bank.toFixed(2);
        }
        //otherwise get info from session storage
        else {
            return JSON.parse(sessionStorage.getItem('userInfo')).bank.toFixed(2);
        }
    }

    /**
     * A method to get the users email from local/session storage
     * @returns {string} returns the users email from storage
     */
    getEmail = () => {
        //if the remember me checkbox was clicked, get info from local storage
        if (Cookie.getCookie("rememberMe")) {
            return JSON.parse(localStorage.getItem('userInfo')).email;
        }
        //otherwise get info from session storage
        else {
            return JSON.parse(sessionStorage.getItem('userInfo')).email;
        }
    }

    /**
     * A method to get the users username from local/session storage
     * @returns {string} the users username from storage
     */
    getUsername = () => {
        //if the remember me checkbox was clicked, get info from local storage
        if (Cookie.getCookie("rememberMe")) {
            return JSON.parse(localStorage.getItem('userInfo')).username;
        }
        //otherwise get info from session storage
        else {
            return JSON.parse(sessionStorage.getItem('userInfo')).username;
        }
    }

    /**
     * A method to get the users number of live bets from local/session storage
     * @returns {int} the number of live bets the user has in storage
     */
    getNumLiveBets = () => {
        //if the remember me checkbox was clicked, get info from local storage
        if (Cookie.getCookie("rememberMe")) {
            return JSON.parse(localStorage.getItem('userInfo')).numLiveBets;
        }
        //otherwise get info from session storage
        else {
            return JSON.parse(sessionStorage.getItem('userInfo')).numLiveBets;
        }
    }
}

export default new UserProfile();