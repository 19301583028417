//imports
import React from 'react';
import './restartPopup.scss'; 
import CloseIcon from '../../assets/closeIcon';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import UserProfile from '../../helpers/userProfile';

/**
 * An element to represent the restart popup
 * Users will be asked if they are sure they want to start over and can confirm
 */
class RestartPopup extends React.Component {
    //constructor
    constructor(props) {
		super(props);

		//getting the users info
        this.user = UserProfile.getUserInfo();
        this.isLoggedIn = UserProfile.getIsLoggedIn();
	}

    /**
     * An element to handle the restart button being clicked
     * Will signify a restart to the backend and redirect the user to the home page
     */
    restartClicked = () => {

        //making sure the user is logged in
        if (this.isLoggedIn) {
            //getting the users email
			const userEmail = this.user.email;
			const apiKey = process.env.REACT_APP_APIK;
			//posting to the restart page
			axios.post(process.env.REACT_APP_APIL + "restart", { apiKey, userEmail })
				.then(result => {
                    console.log(result.data);
                    if (result.data.status === 1) {
                        this.props.history.push({pathname: "/"});
                    }
				})
				.catch(error => {
					console.log(error);
				});
		}
    }

    /**
     * render method
     */
    render() {  
        return (  
            <div className='restartPopup'>  
                <div className='restartPopupInner'>  
                    <div className="headBar">
                        <h4 id="title">Restart</h4><button id="close" onClick={this.props.closePopup}><CloseIcon/></button>
                    </div>
                    <h4 id="instruction">Restarting will reset your bank to 50.00 points and you will be able to place bets again. This action CANNOT be undone. Are you sure you want to proceed?</h4>

                    <button type="submit" onClick={() => this.restartClicked()} id="restartButton"><p>RESTART</p></button> 
                </div>  
            </div>  
        );  
    }  
}  

export default withRouter(RestartPopup);