//imports
import React, { Component } from 'react';
import '../../index.scss';
import './menu.scss';
import MenuItem from "./menuItem";
import ReactGA from "react-ga";

/**
 * An element to represent the menu in the account dash
 * Here users can filter which content they see on the page
 * They can view their trends, upcoming bets, past bets, live bets, and account info
 */
class Menu extends Component {

    //constructor
    constructor(props) {
        super(props);
        //base states
        this.state = {
            currentItem: this.props.startItem + 1
        };

        this.menuItems = ["Current Trends", "Live Bets", "Upcoming Bets", "Past Bets", "My Info"];

        //create ga event
        ReactGA.event({
            category: 'Account Dash Item Viewed',
            action: this.menuItems[this.props.startItem] + ' Viewed',
        });
    }

    /**
     * A function to update the props because they are coming in as a state
     * @param nextProps the next props (from state)
     * @param prevState the previous props (from base)
     * @returns the current sport to show on the sport picker
     */
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.startItem!==prevState.startItem){
            return { startItem: nextProps.startItem + 1};
        }
        else return null;
    }

    /**
     * A method to update which item is being displayed
     * @param event the click of the new sport
     */
    updateCurrentItem = (event) => {
        let key = `${event.currentTarget.id}`;
        this.setState({ currentItem: key });
        this.props.onChange(key);

        //create ga event
        ReactGA.event({
            category: 'Account Dash Item Viewed',
            action: this.menuItems[key - 1] + ' Viewed',
        });
    };

    /**
     * A method to determine if this menu is the one that's currently selected
     * @param id determining which sport that is being compared
     * @returns {boolean} whether the passed in sport is on or not
     */
    isOn(id) {
        // eslint-disable-next-line
        return id == this.state.currentItem;
    }

    /**
     * render method
     */
    render() {
        return (
            <div className="itemList">

                <input
                    type="radio"
                    id="trends"
                    name="trends"
                    readOnly
                    checked={this.isOn(1)}
                />
                <label htmlFor="trends">
                    <button className={'menuItemButton' + this.isOn(1)} id={1} onClick={this.updateCurrentItem}>
                        <MenuItem isOn={this.isOn(1)} name="Current Trends"/>
                    </button>
                </label>

                <input
                    type="radio"
                    id="liveBets"
                    name="liveBets"
                    readOnly
                    checked={this.isOn(2)}
                />
                <label htmlFor="liveBets">
                    <button className={'menuItemButton' + this.isOn(2)} id={2} onClick={this.updateCurrentItem}>
                        <MenuItem isOn={this.isOn(2)} name="Live Bets"/>
                    </button>
                </label>

                <input
                    type="radio"
                    id="upcomingBets"
                    name="upcomingBets"
                    readOnly
                    checked={this.isOn(3)}
                />
                <label htmlFor="upcomingBets">
                    <button className={'menuItemButton' + this.isOn(3)} id={3} onClick={this.updateCurrentItem}>
                        <MenuItem isOn={this.isOn(3)} name="Upcoming Bets"/>
                    </button>
                </label>

                <input
                    type="radio"
                    id="pastBets"
                    name="pastBets"
                    readOnly
                    checked={this.isOn(4)}
                />
                <label htmlFor="pastBets">
                    <button className={'menuItemButton' + this.isOn(4)} id={4} onClick={this.updateCurrentItem}>
                        <MenuItem isOn={this.isOn(4)} name="Past Bets"/>
                    </button>
                </label>

                <input
                    type="radio"
                    id="myInfo"
                    name="myInfo"
                    readOnly
                    checked={this.isOn(5)}
                />
                <label htmlFor="myInfo">
                    <button className={'menuItemButton' + this.isOn(5)} id={5} onClick={this.updateCurrentItem}>
                        <MenuItem isOn={this.isOn(5)} name="My Info"/>
                    </button>
                </label>
            </div>
        );
    }
}

export default Menu;