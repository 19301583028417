//imports
import React, {Component} from 'react';
import {Formik} from "formik";
import * as Yup from "yup";
import './signupForm.scss';
import axios from 'axios';
import {Base64} from 'js-base64';
import {withRouter} from 'react-router-dom';
import UserProfile from '../../helpers/userProfile.js';
import Cookie from '../../helpers/cookies.js';
import ReactGA from "react-ga";

//defining today
const today = new Date();

/**
 * An element to represent the signup form where users can signup
 * Users will have to enter a username, their email, their password, and birthday to sign up
 */
class SignupForm extends Component {
	//constructor
	constructor(props) {
		super(props);

		//base states
		this.state = {
			dbError: false,
			dbErrorInfo: "",
		}

		//clearing the user profile when building the element
		UserProfile.clearUserProfile();
	}

	tosView = () => {
		//create ga event
		ReactGA.event({
			category: 'TOS',
			action: 'TOS viewed',
		});
	}

	/**
	 * render method
	 */
	render() {
		return (
			<Formik
				initialValues={{ email: "", username: "", password: "", confirmPassword: "", birthday: "", remember: false }}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						setSubmitting(false);
					}, 500);

					const tempPW = values.password;
					const tempCPW = values.confirmPassword;

					//create ga event
					ReactGA.event({
						category: 'Sign Up',
						action: 'Sign up attempted',
					});

					values.password = Base64.encode(values.password);
					values.confirmPassword = Base64.encode(values.confirmPassword);
					const apiKey = process.env.REACT_APP_APIK;

					axios.post(process.env.REACT_APP_APIL + "newSignup", { apiKey, values })
						.then(res => {
							// console.log(values);
							// console.log(res);
							// console.log(res.data);
							const responseCode = parseInt(res.data.status)
							if (responseCode > 1) {
								this.setState({ dbError: true });
								if (responseCode === 2) {
									this.setState({ dbErrorInfo: "An account with the email you entered already exists! blah blah blah blah blah blah" });
								}
								else if (responseCode === 3) {
									this.setState({ dbErrorInfo: "The username you entered is already taken!" });
								}
								else if (responseCode === 4) {
									this.setState({ dbErrorInfo: "An error occurred. Please contact help@fantombook.com for help" });
								}
								values.password = tempPW;
								values.confirmPassword = tempCPW;
							}
							else if (responseCode === 1) {
								this.setState({ dbError: false, dbErrorInfo: "" });
								if (values.remember) {
									Cookie.setCookie("rememberMe", "true", 180);
								}
								UserProfile.storeUserProfile(res.data.userInfo);

								//create ga event
								ReactGA.event({
									category: 'Sign Up',
									action: 'Sign up successful',
								});

								this.props.history.push({pathname: "/"});
							}
							else {
								this.setState({ dbError: true, dbErrorInfo: "Something went wrong! Please try again" });
								values.password = tempPW;
								values.confirmPassword = tempCPW;
							}
						})
						.catch(error => {
							console.log(error);
							this.setState({ dbError: true, dbErrorInfo: "Something went wrong! Please try again" });
							values.password = tempPW;
							values.confirmPassword = tempCPW;
						});
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string()
						.email()
						.required("Please enter an email"),
					username: Yup.string()
						.required("Please eneter a username")
						.min(2, "This username is too short - it should be at least 2 characters")
						.max(15, "This username is too long - it should be at most 15 characters")
						.matches(/^[a-zA-Z0-9_-]{2,15}$/, "A username must not contain a special character"),
					password: Yup.string()
						.required("Please enter a password")
						.min(8, "This password is too short - it should be at least 8 characters")
						.max(32, "This password is too long - it should be at most 32 characters")
						.matches(/(?=^.{8,32}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
							"Passwords must contain a number, upper case letter, lower case letter, and a special character"),
					confirmPassword: Yup.string()
						.oneOf([Yup.ref('password'), null], "The passwords you entered do not match")
						.required('Please confirm your password'),
					birthday: Yup.date()
						.required("Please enter your birthday")
						.max(today, "Please enter a valid birthday"),
				})}>


				{props => {
					const {
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
					} = props;

					return (
						<div className="signupForm">
							<h1>Create Your Account</h1>
							<div className={`dbError${this.state.dbError}`}>
								<div className="dbError-feedback">{this.state.dbErrorInfo}</div>
							</div>
							<form onSubmit={handleSubmit}>
								<div id="email">
									<input
										id="email"
										name="email"
										type="email"
										placeholder="Email:"
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
										className={errors.email && touched.email && "error"}
									/>
									{errors.email && touched.email && (
										<div className="input-feedback">{errors.email}</div>
									)}
								</div>

								<div className="username">
									<input
										id="username"
										name="username"
										type="text"
										placeholder="Username:"
										autoComplete='new-username'
										value={values.username}
										onChange={handleChange}
										onBlur={handleBlur}
										className={errors.username && touched.username && "error"}
									/>
									{errors.username && touched.username && (
										<div className="input-feedback">{errors.username}</div>
									)}
								</div>

								<div className="password">
									<input
										id="password"
										name="password"
										type="password"
										placeholder="Password:"
										autoComplete='new-password'
										value={values.password}
										onChange={handleChange}
										onBlur={handleBlur}
										className={errors.password && touched.password && "error"}
									/>
									{errors.password && touched.password && (
										<div className="input-feedback">{errors.password}</div>
									)}

									<input
										id="confirmPassword"
										name="confirmPassword"
										type="password"
										placeholder="Confirm Password:"
										autoComplete='new-password'
										value={values.confirmPassword}
										onChange={handleChange}
										onBlur={handleBlur}
										className={errors.confirmPassword && touched.confirmPassword && "error"}
									/>
									{errors.confirmPassword && touched.confirmPassword && (
										<div className="input-feedback">{errors.confirmPassword}</div>
									)}
								</div>

								<div className="birthdayWithLabel">
									<div className="birthday">
										<input
											id="birthday"
											name="birthday"
											type="text"
											placeholder="Birthday:"
											min="1920-01-01"
											onFocus={(e) => e.target.type = "date"}
											value={values.birthday}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.birthday && touched.birthday && "error"}
										/>
										{errors.birthday && touched.birthday && (
											<div className="input-feedback">{errors.birthday}</div>
										)}
									</div>
								</div>

								<div className="tos">
									<p>By creating an account, you are agreeing to our<br /><a onClick={this.tosView} href="/tos">Terms & Conditions, and Privacy Policy</a></p>
								</div>

								<div className="rememberMe">
									<p>Remember Me
										<label className="checkbox">
											<input type="checkbox"
												   id="remember"
												   name="remember"
												   value={values.remember}
												   onChange={handleChange} />
											<span className="overlay">
												<svg xmlns="http://www.w3.org/2000/svg"
													 width="24"
													 height="24"
													 viewBox="0 0 24 24"
													 fill="none"
													 stroke="currentColor"
													 strokeWidth="2"
													 strokeLinecap="round"
													 strokeLinejoin="round"
													 className="icon">
													<polyline points="20 6 9 17 4 12" />
												</svg>
											</span>
										</label>
									</p>
								</div>

								<div className="signupButton">
									<button type="submit" id="signup" disabled={isSubmitting}>
										<p>SIGN UP</p>
									</button>
								</div>

								<div className="alreadyHaveAccount">
									<p>Already have an account? <a href="/login">Log In</a></p>
								</div>

							</form>
							<p id="response"/>
						</div>
					);
				}}
			</Formik>
		);
	}
}

export default withRouter(SignupForm);