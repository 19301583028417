//imports
import React from 'react';
import './forgotPassword.scss';
import * as Yup from "yup";
import { Formik } from "formik";
import CloseIcon from '../../assets/closeIcon';
import axios from 'axios';
import { withRouter } from 'react-router-dom'
import ReactGA from "react-ga";
import Loader from "react-loader-spinner";

/**
 * An element to represent the forgot password screen
 */
class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        //base states
        this.state = {
            isEmailOn: true,
            dbEmailError: false,
            dbEmailErrorInfo: "",
            isLoaded: true,
        };
    }

    /**
     * A method to toggle the email screen on and off
     */
    toggleScreen = () => {
        this.setState({ isEmailOn: !this.state.isEmailOn });
    }

    render() {

        let emailOn = !!this.state.isEmailOn;

        return (
            <div id='popup'>
                <div id='popupInner'>
                    <div className="head">
                        <h1 id="name">Forgot Password</h1><button id="close" onClick={this.props.closePopup}><CloseIcon /></button>
                    </div>
                    <div className="body">
                        {emailOn ? (
                            <Formik
                                initialValues={{ email: "" }}
                                onSubmit={(values, { setSubmitting }) => {
                                    this.setState({isLoaded: false});
                                    setTimeout(() => {
                                        setSubmitting(false);
                                        // console.log(values);
                                    }, 500);

                                    const apiKey = process.env.REACT_APP_APIK;

                                    axios.post(process.env.REACT_APP_APIL + "fpwEmail", { apiKey, values })
                                        .then(res => {
                                            // console.log(res);
                                            // console.log(res.data);
                                            const responseCode = parseInt(res.data)
                                            if (responseCode > 1) {
                                                this.setState({ dbEmailError: true });
                                                if (responseCode === 2) {
                                                    this.setState({ dbEmailErrorInfo: "No account with the email you entered exists!" });
                                                }
                                            }
                                            else if (responseCode === 1) {
                                                this.setState({dbEmailError: false, dbEmailErrorInfo: "" });

                                                //create ga event
                                                ReactGA.event({
                                                    category: 'Forgot Password',
                                                    action: 'Forgotten password process started',
                                                });

                                                this.setState({isLoaded: true});

                                                this.props.history.push({
                                                    pathname: "/resetcode",
                                                    data: values.email
                                                })
                                            }
                                            else {
                                                this.setState({ dbError: true, dbErrorInfo: "Something went wrong! Please try again" });
                                            }
                                        })
                                        .catch(error => {
                                            console.log(error);
                                            this.setState({ dbEmailError: true, dbEmailErrorInfo: "Something went wrong! Please try again" });
                                        });
                                }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string()
                                        .email()
                                        .required("Please enter an email"),
                                })}>

                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                    } = props;

                                    if (!this.state.isLoaded) {
                                        return <div id="fpw-loader"><Loader type="Oval" color="#3DF5D9" height={100} width={100} /></div>;
                                    }
                                    else {
                                        return (
                                            <div className="fpwEmailForm">
                                                <div className={`dbError${this.state.dbEmailError}`}>
                                                    <div
                                                        className="dbError-feedback">{this.state.dbEmailErrorInfo}</div>
                                                </div>
                                                <div className={`instructions${this.state.dbEmailError}`}>Enter the
                                                    email associated with your account
                                                </div>
                                                <form onSubmit={handleSubmit}>
                                                    <div id="emailInput">
                                                        <input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            placeholder="Email:"
                                                            autoComplete='new-email'
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={errors.email && touched.email && "error"}
                                                        />
                                                        {errors.email && touched.email && (
                                                            <div className="input-feedback">{errors.email}</div>
                                                        )}
                                                    </div>

                                                    <div className="submit">
                                                        <button type="submit" className="enter" disabled={isSubmitting}>
                                                            <p>SUBMIT</p>
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        );
                                    }
                                }}
                            </Formik>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ForgotPassword);