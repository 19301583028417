import React from 'react'; 

class MLBSPIcon extends React.Component {
    render() {
        return (
            <svg width="132" height="91" viewBox="0 0 132 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M60.5376 85V68.992H55.7376L51.8496 79L47.9616 68.992H43.1856V85H46.5936V73.456L51.1056 85H52.5936L57.1056 73.456V85H60.5376ZM73.7728 85V82H67.0048V68.992H63.5728V85H73.7728ZM84.7564 85C87.8284 85 89.4124 83.08 89.4124 80.656C89.4124 78.664 88.0684 77.008 86.3404 76.744C87.8524 76.432 89.1004 75.064 89.1004 73.072C89.1004 70.936 87.5404 68.992 84.4924 68.992H76.0684V85H84.7564ZM83.7484 75.352H79.4764V71.992H83.7484C84.9004 71.992 85.6204 72.688 85.6204 73.672C85.6204 74.704 84.9004 75.352 83.7484 75.352ZM83.8924 82H79.4764V78.352H83.8924C85.2124 78.352 85.9324 79.168 85.9324 80.176C85.9324 81.328 85.1644 82 83.8924 82Z" fill="white"/>
                <path d="M65.1168 4.73832C62.0029 4.73297 58.9189 5.34543 56.0434 6.54023C53.1678 7.73502 50.5578 9.48841 48.3646 11.6989C39.1297 20.9338 39.1297 35.966 48.3646 45.2057C52.8541 49.6952 58.8007 52.1663 65.1168 52.1663C71.433 52.1663 77.3819 49.6952 81.8691 45.2057C91.1064 35.9684 91.1064 20.9362 81.8691 11.6989C79.6758 9.48841 77.0659 7.73502 74.1903 6.54023C71.3148 5.34543 68.2307 4.73297 65.1168 4.73832ZM78.5191 41.8557C76.0353 44.3478 72.9036 46.095 69.4784 46.8996L69.4761 46.812C69.4721 44.9814 69.8104 43.1663 70.4735 41.4601L66.0598 39.733C65.1098 42.1762 64.665 44.7867 64.752 47.4066C59.8491 47.3226 55.1722 45.3304 51.7146 41.8534C48.2505 38.3895 46.2692 33.7141 46.1897 28.8159C46.3793 28.8207 46.5688 28.8278 46.7583 28.8278C49.18 28.8344 51.5813 28.386 53.8373 27.5058L52.1079 23.0945C50.3846 23.7591 48.5507 24.0903 46.7038 24.0705C47.4995 20.654 49.2352 17.5281 51.7146 15.0465C54.2886 12.4604 57.5574 10.6762 61.1248 9.91016C61.1022 11.4865 60.8163 13.0479 60.279 14.53L64.7354 16.1434C65.4854 14.093 65.8719 11.9275 65.8773 9.74432C65.8773 9.66614 65.8679 9.59033 65.8655 9.51214C70.6346 9.69931 75.1052 11.6349 78.5215 15.0465C81.8935 18.4213 83.861 22.9488 84.0274 27.7167C81.777 27.6906 79.5403 28.0702 77.4246 28.8373L79.0451 33.2913C80.5146 32.7519 82.0642 32.4628 83.6293 32.436C82.8736 36.0044 81.0984 39.2766 78.5191 41.8557Z" fill="white"/>
                <path d="M70.1441 33.476C69.3297 34.2891 68.595 35.1784 67.9503 36.1318L71.8736 38.7852C72.3475 38.084 72.8924 37.4253 73.4917 36.8283C74.3329 35.9883 75.2696 35.2496 76.2826 34.6274L73.7974 30.5927C72.4712 31.4078 71.2451 32.3755 70.1441 33.476ZM56.7443 20.0761C56.1431 20.6741 55.4872 21.2142 54.785 21.6895L57.436 25.6175C58.3892 24.9715 59.2792 24.237 60.0942 23.4237C61.1933 22.3215 62.1608 21.0955 62.9775 19.7705L58.9428 17.2876C58.3196 18.2985 57.5819 19.2342 56.7443 20.0761Z" fill="white"/>
            </svg>
        )
    }
}

export default MLBSPIcon;