//imports
import React, { Component } from 'react';
import '../../index.scss';
import './menu_mobile.scss';
import DropDownArrow from "../../assets/dropdownArrow";
import ReactGA from "react-ga";

/**
 * An element to represent the menu in the account dash when the screen size becomes too small to handle the standard menu
 * Here users can filter which content they see on the page
 * They can view their trends, upcoming bets, past bets, live bets, and account info
 */
class MenuMobile extends Component {

    //constructor
    constructor(props) {
        super(props);
        //base states
        this.state = {
            currentItem: this.props.startItem + 1,
            showMenu: false,
        };

        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);

        this.menuItems = ["Current Trends", "Live Bets", "Upcoming Bets", "Past Bets", "My Info"];

        //create ga event
        ReactGA.event({
            category: 'Account Dash Item Viewed',
            action: this.menuItems[this.props.startItem] + ' Viewed',
        });
    }

    /**
     * A function to update the props because they are coming in as a state
     * @param nextProps the next props (from state)
     * @param prevState the previous props (from base)
     * @returns the current sport to show on the sport picker
     */
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.startItem !== prevState.startItem){
            return { startItem: nextProps.startItem + 1};
        }
        else return null;
    }

    /**
     * A method to update which item is being displayed
     * @param event the click of the new sport
     */
    updateCurrentItem = (event) => {
        let key = `${event.currentTarget.id}`;
        let newIndx = this.menuItems.indexOf(key) + 1;
        this.setState({ currentItem: newIndx});
        this.props.onChange(newIndx);

        //create ga event
        ReactGA.event({
            category: 'Account Dash Item Viewed',
            action: this.menuItems[newIndx - 1] + ' Viewed',
        });
    };

    /**
     * A method to determine if this menu is the one that's currently selected
     * @param id determining which sport that is being compared
     * @returns {boolean} whether the passed in sport is on or not
     */
    isOn(id) {
        // eslint-disable-next-line
        return id == this.state.currentItem;
    }

    showMenu(event) {
        event.preventDefault();

        this.setState({ showMenu: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }

    closeMenu(event) {

        if (!this.dropdownMenu.contains(event.target)) {

            this.setState({ showMenu: false }, () => {
                document.removeEventListener('click', this.closeMenu);
            });

        }
    }

    /**
     * render method
     */
    render() {
        return (
            <div id="dropdown-menu">
                <button className="menu-item-dropdown" id="selected-item" onClick={this.showMenu}>
                    {this.menuItems[this.state.currentItem - 1]} <DropDownArrow />
                </button>

                {
                    this.state.showMenu
                        ? (
                            <div
                                className="mobile-menu"
                                ref={(element) => {
                                    this.dropdownMenu = element;
                                }}
                            >
                                {this.menuItems.map(item => (
                                    (item !== this.menuItems[this.state.currentItem - 1] ? (
                                        <button onClick={this.updateCurrentItem} className="menu-item-dropdown" id={item} key={item}>{item}</button>
                                    ) : null)
                                ))}
                            </div>
                        )
                        : null
                }
            </div>
        );
    }
}

export default MenuMobile;