//imports
import React, { Component } from 'react';

/**
 * An element to represent a live bet in the live bets table
 * Will contain all data about the bet like name, time placed, odds, wager, to win, and payout values
 */
class LiveBet extends Component {
	//constructor
	constructor(props) {
		super(props);

		//dealing with the date of the bet
		this.dateInfo = this.props.date.split(" ");
		this.dayInfo = this.dateInfo[0].split("-");
		this.timeInfo = this.dateInfo[1].split(":");

		//creating the odds string
		this.oddsStr = this.props.odds > 0 ? `+${this.props.odds}` : `${this.props.odds}`;

		//creating the time stamp
		this.timestamp = new Date(this.dayInfo[0], this.dayInfo[1] - 1, this.dayInfo[2], this.timeInfo[0], this.timeInfo[1], this.timeInfo[2]);

		this.timePlaced = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' }).format(this.timestamp);
	}

	/**
	 * render method
	 */
	render() {
		return (
			<table>
				<tbody>
					<tr><td id="name">{this.props.name}</td><td id="lbInfo">{this.timePlaced}</td><td id="lbInfo">{this.oddsStr}</td><td id="lbInfo">{this.props.wager}</td><td id="lbInfo">{this.props.toWin}</td><td id="lbInfo">{this.props.payout}</td></tr>
				</tbody>
			</table>
		);
	}
}

export default LiveBet;