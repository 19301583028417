//imports
import React, { Component } from 'react';
import { Formik } from "formik";
import ForgotPassword from './forgotPassword.js'
import * as Yup from "yup";
import './loginForm.scss';
import axios from 'axios';
import { Base64 } from 'js-base64';
import { withRouter } from 'react-router-dom'
import UserProfile from '../../helpers/userProfile.js';
import Cookie from '../../helpers/cookies.js';
import ReactGA from "react-ga";

/**
 * An element to represent the login form of the web app
 * Here users can login with their email and password
 */
class LoginForm extends Component {
	//constructor
	constructor(props) {
		super(props);

		//toggling the popup
		this.togglePopup = this.togglePopup.bind(this);
		this.state = { showPopup: false };

		//base states
		this.state = {
			dbError: false,
			dbErrorInfo: "",
		}

		//clearing the user profile when the component is constructed
		UserProfile.clearUserProfile();
	}

	/**
	 * A method to toggle the popup showing and now
	 */
	togglePopup() {
		this.setState({
			showPopup: !this.state.showPopup,
		});
	}

	/**
	 * render method
	 */
	render() {
		return (
			<Formik
				initialValues={{ email: "", password: "", remember: false }}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						setSubmitting(false);
					}, 500);

					const tempPW = values.password;

					//create ga event
					ReactGA.event({
						category: 'Log In',
						action: 'Log in attempted',
					});

					values.password = Base64.encode(values.password);
					const apiKey = process.env.REACT_APP_APIK;

					axios.post(process.env.REACT_APP_APIL + "login", { apiKey, values })
						.then(res => {
							// console.log(res);
							// console.log(res.data);
							const responseCode = parseInt(res.data.status)
							if (responseCode > 1) {
								this.setState({ dbError: true });
								if (responseCode === 2) {
									this.setState({ dbErrorInfo: "No account with that email exists!" });
								}
								else if (responseCode === 3) {
									this.setState({ dbErrorInfo: "The password and email you entered do not match!" });
								}
								values.password = tempPW;
							}
							else if (responseCode === 1) {
								this.setState({ dbError: false, dbErrorInfo: "" });
								if (values.remember) {
									Cookie.setCookie("rememberMe", "true", 180);
								}
								UserProfile.storeUserProfile(res.data.userInfo);

								//create ga event
								ReactGA.event({
									category: 'Log In',
									action: 'Successful log in',
								});

								this.props.history.push({ pathname: "/" });
							}
							else {
								this.setState({ dbError: true, dbErrorInfo: "Something went wrong! Please try again" });
								values.password = tempPW;
							}
						})
						.catch(error => {
							console.log(error);
							this.setState({ dbError: true, dbErrorInfo: "Something went wrong! Please try again" });
							values.password = tempPW;
						});
				}}
				validationSchema={Yup.object().shape({
					email: Yup.string()
						.required("Please enter your email"),
					password: Yup.string()
						.required("Please enter your password")
				})}>

				{props => {
					const {
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit
					} = props;

					return (
						<div className="loginForm">
							<h1 id="login-title">Login</h1>
							<div className={`dbError${this.state.dbError}`}>
								<div className="dbError-feedback">{this.state.dbErrorInfo}</div>
							</div>
							<form onSubmit={handleSubmit}>
								<div id="email">
									<input
										id="email"
										name="email"
										type="email"
										placeholder="Email:"
										autoComplete='current-email'
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
										className={errors.email && touched.email && "error"}
									/>
									{errors.email && touched.email && (
										<div className="input-feedback">{errors.email}</div>
									)}
								</div>

								<div className="password">
									<input
										id="password"
										name="password"
										type="password"
										placeholder="Password:"
										autoComplete='current-password'
										value={values.password}
										onChange={handleChange}
										onBlur={handleBlur}
										className={errors.password && touched.password && "error"}
									/>
									{errors.password && touched.password && (
										<div className="input-feedback">{errors.password}</div>
									)}
								</div>
								<div className="forgotPassword">
									<p onClick={this.togglePopup.bind(this)}>Forgot Password</p>
								</div>

								<div className="rememberMe">
									<p>Remember Me
										<label className="checkbox">
											<input type="checkbox" 
												id="remember"
												name="remember"
												value={values.remember}
												onChange={handleChange} />
											<span className="overlay">
												<svg xmlns="http://www.w3.org/2000/svg"
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													stroke="currentColor"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
													className="icon">
													<polyline points="20 6 9 17 4 12" />
												</svg>
											</span>
										</label>
									</p>
								</div>

								<div className="loginButton">
									<button type="submit" id="login" disabled={isSubmitting}>
										<p>LOG IN</p>
									</button>
								</div>

								<div className="dontHaveAccount">
									<p>Don't have an account? <a href="/signup">Sign Up</a></p>
								</div>

							</form>

							{this.state.showPopup ?
								<ForgotPassword
									closePopup={this.togglePopup.bind(this)}
								/>
								:
								null
							}
						</div>
					);
				}}
			</Formik>
		);
	}
}

export default withRouter(LoginForm);
