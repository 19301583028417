//imports
import React, { Component } from 'react';
import Popup from './popup.js'
import './oddsBoxes.scss';
import {toBase64} from "js-base64";

/**
 * An element to represent a draw box in the game element
 * Will display the odds of the draw bet
 */
class DrawBox extends Component {
    //constructor
    constructor(props) {
        super(props);

        let parlayId = toBase64(this.props.gameId.toString() + this.props.points + this.props.betType + this.props.odds);

        //base states
        this.state = { showPopup: false, selectedForParlay: this.props.currentParlay.some(c => c.parlayBetId === parlayId) };
        //creating the odds string
        this.oddsStr = this.props.odds > 0 ? `+${this.props.odds}` : `${this.props.odds}`;

        //toggle the place bet popup
        this.togglePopup = this.togglePopup.bind(this);
    }

    /**
     * A method toggle the popup
     */
    togglePopup() {
        if (this.props.parlayMode) {
            this.setState({ selectedForParlay: !this.state.selectedForParlay, });
            const parlayInfo = ({
                parlayBetId: toBase64(this.props.gameId.toString() + this.props.points + this.props.betType + this.props.odds),
                betInfo: this.props
            });
            !this.state.selectedForParlay ?  this.props.addToParlay(parlayInfo) : this.props.removeFromParlay(parlayInfo);
        } else {
            this.setState({
                showPopup: !this.state.showPopup,
            });
            this.props.sendPopupState(!this.state.showPopup ? "Popup Enabled" : "Popup Disabled");
        }
    }

    /**
     * render method
     */
    render() {

        return (
            <div>

                <button {...this.state.selectedForParlay && this.props.parlayMode ? {className: "parlay-on"} : null} id="odds-box" onClick={this.togglePopup.bind(this)}>
                    <p id="mlOdds">{this.oddsStr}</p>
                </button>

                {this.state.showPopup ?
                    <Popup
                        closePopup={this.togglePopup.bind(this)}
                        gameId={this.props.gameId}
                        awayTeam={this.props.awayTeam}
                        homeTeam={this.props.homeTeam}
                        betType={this.props.betType}
                        odds={this.oddsStr}
                    />
                    :
                    null
                }
            </div>
        );
    }
}

export default DrawBox;