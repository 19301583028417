import React from 'react'; 

class MLBIcon extends React.Component {
    render() {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99236 0.380962C6.99095 0.379244 5.99914 0.576207 5.07438 0.960447C4.14961 1.34469 3.31026 1.90857 2.60493 2.61944C-0.364974 5.58934 -0.364974 10.4236 2.60493 13.3951C4.04874 14.8389 5.96112 15.6335 7.99236 15.6335C10.0236 15.6335 11.9367 14.8389 13.3798 13.3951C16.3505 10.4244 16.3505 5.59011 13.3798 2.61944C12.6745 1.90857 11.8351 1.34469 10.9103 0.960447C9.98558 0.576207 8.99377 0.379244 7.99236 0.380962V0.380962ZM12.3025 12.3177C11.5037 13.1192 10.4966 13.681 9.39503 13.9398L9.39426 13.9116C9.39299 13.3229 9.50178 12.7392 9.71503 12.1905L8.2956 11.6351C7.9901 12.4208 7.84703 13.2603 7.87503 14.1029C6.29829 14.0758 4.79422 13.4352 3.68226 12.317C2.56823 11.203 1.93107 9.69943 1.9055 8.1242C1.96645 8.12572 2.02741 8.12801 2.08836 8.12801C2.86715 8.13013 3.6394 7.98592 4.36493 7.70287L3.80874 6.2842C3.25454 6.49794 2.66479 6.60446 2.07084 6.5981C2.32672 5.49935 2.8849 4.4941 3.68226 3.69601C4.51006 2.86434 5.56128 2.29056 6.70855 2.0442C6.70126 2.55113 6.60933 3.05328 6.43655 3.52991L7.86969 4.04877C8.1109 3.38939 8.23517 2.69298 8.23693 1.99087C8.23693 1.96572 8.23388 1.94134 8.23312 1.9162C9.76684 1.97639 11.2046 2.59887 12.3032 3.69601C13.3877 4.78133 14.0204 6.23736 14.0739 7.77068C13.3502 7.76229 12.6309 7.88437 11.9505 8.13106L12.4716 9.56344C12.9442 9.38999 13.4425 9.29702 13.9459 9.28839C13.7028 10.436 13.1319 11.4883 12.3025 12.3177V12.3177Z" fill="#3DF5D9"/>
            <path d="M9.60917 9.62286C9.34723 9.88435 9.11098 10.1704 8.90364 10.477L10.1654 11.3303C10.3177 11.1048 10.493 10.893 10.6857 10.701C10.9563 10.4308 11.2575 10.1932 11.5833 9.99315L10.784 8.69562C10.3575 8.95773 9.96321 9.26894 9.60917 9.62286V9.62286ZM5.29983 5.31353C5.10652 5.50583 4.89556 5.67955 4.66974 5.83238L5.52231 7.09562C5.82884 6.88788 6.11506 6.65166 6.37717 6.3901C6.73061 6.03562 7.04176 5.64137 7.30441 5.21524L6.00688 4.41676C5.80646 4.74187 5.56921 5.04278 5.29983 5.31353V5.31353Z" fill="#3DF5D9"/>
            </svg>
        )
    }
}

export default MLBIcon;