//imports
import React, { Component } from 'react';
import ResetPWScreen from'../../elements/login/resetPwScreen.js'

/**
 * A class to represent the form to allow a user to reset their password
 */
class ResetPW extends Component {

  /**
   * render method
   */
  render() {
    return (
      <div className="resetPW">
        <ResetPWScreen/>
      </div>
    );
  }
}
 
export default ResetPW;