//imports
import React, { Component } from 'react';
import '../../index.scss';
import './drawBet.scss';
import DrawBox from "./drawBox";

/**
 * An element that will represent a draw.
 * Will be like an old prop bet and show just the odds for a draw
 */
class DrawBet extends Component {

    constructor(props) {
        super(props);

        this.sendPopupState = this.sendPopupState.bind(this);
        this.addBetToParlay = this.addBetToParlay.bind(this);
        this.removeBetFromParlay = this.removeBetFromParlay.bind(this);
    }

    sendPopupState = (val) => {
        this.props.sendPopupState(val);
    }

    addBetToParlay = (bet) => {
        this.props.addToParlay(bet);
    }

    removeBetFromParlay = (bet) => {
        this.props.removeFromParlay(bet);
    }

    /**
     * render function
     */
    render() {

        return (
            <div id="draw-bet">
                <div id="draw">
                    Draw (Tied after regulation)
                </div>
                <div id="odds">
                    <DrawBox currentParlay={this.props.currentParlay} parlayMode={this.props.parlayMode} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} sendPopupState={this.sendPopupState} gameId={this.props.gameId} homeTeam={this.props.homeTeam} awayTeam={this.props.awayTeam} betType={8} odds={this.props.odds}/>
                </div>
            </div>
        );
    }
}

export default DrawBet;