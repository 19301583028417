//imports
import React, { Component } from 'react';
import LoginForm from'../../elements/login/loginForm.js'

/**
 * A class that represents the login page of the web app
 * Will be where users can log in
 * Will be redirected where when clicking on the home page content if not logged in
 */
class Login extends Component {

  /**
   * render method
   */
  render() {
    return (
      <div className="login">
        <LoginForm/>
      </div>
    );
  }
}
 
export default Login;