//imports
import React, { Component } from 'react';
import BankLogo from '../../assets/banklogo'
import PointsLogo from '../../assets/pointslogo'
import ProfileIcon from '../../assets/profileIcon'
import SideBarIcon from '../../assets/sideBarIcon'
import LiveBetsIcon from '../../assets/liveBetsIcon'
import UpcomingBetsIcon from '../../assets/upcomingBetsIcon'
import LiveBetSidebarItem from './liveBetSideBarItem'
import FBLetterIcon from '../../assets/fbLetterIcon'
import CloseIcon from '../../assets/closeIcon'
import RestartPopup from "../../assets/restartPopup";
import '../../index.scss';
import './header.scss'
import UserProfile from '../../helpers/userProfile';
import { withRouter } from 'react-router-dom';
import NotificationBubble from "../../assets/notificationBubble";
import SMSIcon from "../../assets/smsIcon";
import TwitterIcon from "../../assets/twitterIcon";

/**
 * Header component that will appear on all pages
 * Will have a button to take you to the profile page, your bank, and sidebar
 * Sidebar will display all live bets and links to differnet pages
 */
class Header extends Component {

	//constructor
	constructor(props) {
		super(props);

		//update user stats and get user bank
		UserProfile.updateStats();
		this.bank = UserProfile.getBank();
		this.numLiveBets = UserProfile.getNumLiveBets();

		//define a variable to determine if a restart is available or not
		this.restart = (this.bank < 2 && this.numLiveBets === 0);

		if (!this.restart) {
			sessionStorage.removeItem('restartPopupClicked');
		}

		//set the value of session storage variable restartPopupClicked is this.restart is true
		if (this.restart && !sessionStorage.getItem('restartPopupClicked')) {
			sessionStorage.setItem('restartPopupClicked', JSON.stringify(false));
		}

		//set local storage item leaderboardNotificationClosed to false if it doesnt already exist and the user is in the top 5
		if (!(!!localStorage.getItem('leaderboardNotificationClosed')) && (this.props.userPos <= 5)) {
			localStorage.setItem('leaderboardNotificationClosed', JSON.stringify(false));
		}

		//if the users position on the leaderboard is greater than 5, remove the item in local storage pertaining to it
		if (this.props.userPos > 5) {
			localStorage.removeItem('leaderboardNotificationClosed');
		}

		//base states
		this.state = {
			leftOpen: false,
			closeIcon: false,
			liveBets: this.props.liveBets,
			hideLeaderboardNotification: JSON.parse((localStorage.getItem('leaderboardNotificationClosed'))),
		}
	}

	/**
	 * Method to update livebets to the proper value
	 * @param {*} prevProps previous props
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.liveBets !== this.props.liveBets) {
			this.setState({liveBets: this.props.liveBets});
		}
	}

	/**
	 * Method called when the restart notification is clicked
	 * Will set the current item to 5 on the account dash menu, and go to the account dash page
	 */
	goRestart = () => {
		sessionStorage.setItem('currentItem', JSON.stringify(5));
		sessionStorage.setItem('restartPopupClicked', JSON.stringify(true));
		if (window.location.pathname !== "/account") {
			this.props.history.push("/account");
		}
		else {
			window.location.reload();
		}

	}

	/**
	 * A method that will be called when the leaderboard notification is closed
	 * Will set the item in local storage to true and the state of hideLeaderboardNotification to true
	 */
	closeLeaderboardNotification = () => {
		localStorage.setItem('leaderboardNotificationClosed', JSON.stringify(true));
		this.setState({ hideLeaderboardNotification: true});
	}

	/**
	 * A method to toggle the sidebar in and out
	 * @param {*} event a click on the sidebar icon
	 */
	toggleSidebar = (event) => {
		let key = `${event.currentTarget.parentNode.id}Open`;
		this.setState({ [key]: !this.state[key] });
	}

	/**
	 * render method
	 */
	render() {
		//defining the values for the sidebar open and icon status
		let leftOpen = this.state.leftOpen ? 'open' : 'closed';
		let closeIcon = this.state.leftOpen ? <CloseIcon /> : <SideBarIcon />;
		let i = 0; //for mapping key

		return (
			<div id='layout'>
				<div id='left' className={leftOpen} >
					<div className='icon'
						onClick={this.toggleSidebar} >
						{closeIcon}
					</div>

					<div className={`sidebar ${leftOpen}`}>
						<div className='content'>
							<div className="home">
								<p id="liveBetsHeader"><FBLetterIcon className="icon" /><a className="heading" href="/">Home</a></p>
							</div>
							<div className="liveBetsSB">
								<p id="liveBetsHeader"><LiveBetsIcon className="icon" /><a onClick={() => sessionStorage.setItem('currentItem', JSON.stringify(2))} className="heading" href="/account">My Live Bets</a></p>
								{this.props.liveBets ? (
									this.props.liveBets.map(liveBet => (
										<LiveBetSidebarItem
											key={i++}
											league={liveBet.league}
											name={liveBet.betName}
										/>
									)
								)
								) : null
								}
							</div>
							<div className="upcomingBetsSB">
								<p id="upcomingBetsHeader"><UpcomingBetsIcon className="icon" /><a onClick={() => sessionStorage.setItem('currentItem', JSON.stringify(3))} className="heading" href="/account">My Upcoming Bets</a></p>
								{this.props.upcomingBets ? (
									this.props.upcomingBets.map(upcomingBet => (
											<LiveBetSidebarItem
												key={i++}
												league={upcomingBet.league.toString()}
												name={upcomingBet.betName}
											/>
										)
									)
								) : null
								}
							</div>
							<p id="bottom"/>
						</div>
					</div>
				</div>
				<div id="main">
					<div className="bank">
						<div className="points"><p id="points"><PointsLogo /> {this.bank}</p></div>
						<div className="label"><BankLogo /></div>
						{this.restart && sessionStorage.getItem('restartPopupClicked') === 'false' ? (
							<div id="restart-notification" onClick={this.goRestart}>
								<RestartPopup/>
							</div>
						) : null}
					</div>
					<div className="profile">
						<a href="/account"><ProfileIcon /> <p>{this.props.username}</p></a>
						{(!!localStorage.getItem('leaderboardNotificationClosed')) && (this.state.hideLeaderboardNotification).toString() === 'false' ? (
							<div id="leaderboard-notification">
								<NotificationBubble/>
								<div id="x" onClick={this.closeLeaderboardNotification}>
									<CloseIcon/>
								</div>
								<div id="notification-message">
									You’re on the global leaderboard! Let your friends know that you’re on fire!
								</div>
								<div id="share-methods">
									<a href="sms://&body=I%27m%20on%20the%20FantomBook%20global%20leaderboard!%20I%27ve%20been%20on%20a%20hot%20streak%20winning%20bets%20on%20the%20world%27s%20first%20fantasy%20sportsbook,%20where%20fantasy%20betting%20is%20free%20and%20legal%20for%20everyone.%20Visit%20fantombook.com%20and%20get%20started%20now!">
										<SMSIcon/>
									</a>
									<a target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=I'm%20on%20the%20FantomBook%20global%20leaderboard!%20I%E2%80%99ve%20been%20on%20a%20hot%20streak%20winning%20bets%20on%20the%20worlds%20first%20fantasy%20sportsbook,%20where%20fantasy%20betting%20is%20free%20and%20legal%20for%20everyone.%20Visit%20fantombook.com%20now%20to%20get%20started!">
										<TwitterIcon/>
									</a>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Header);