import React from 'react'; 

class ProfileIcon extends React.Component {
    render() {
        return (
            <svg width="39" height="52" viewBox="0 0 39 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.4966 0C25.8479 0 30.9966 5.28467 30.9966 11.8038C30.9966 18.3229 25.8479 23.6076 19.4966 23.6076C13.1453 23.6076 7.99658 18.3229 7.99658 11.8038C8.00281 5.28741 13.1479 0.00639128 19.4966 0Z"
                    fill="#3DF5D9"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M36.5 51.5889H2.5C0 51.5 0.279723 48.9819 0.419584 47.7681C1.19778 43.6859 3.00766 40.0053 5.70649 37.1051C9.33841 33.206 14.233 31.0605 19.4998 31.0605C24.7666 31.0605 29.6616 33.206 33.2931 37.1051C35.9926 40.006 37.8027 43.6879 38.5808 47.7713C38.7208 48.9863 38.9978 51.5001 36.5 51.5889Z"
                      fill="#3DF5D9"/>
            </svg>
        )
    }
}

export default ProfileIcon;