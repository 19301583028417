//imports
import React, { Component } from 'react';
import axios from 'axios';
import Header from '../../elements/header/header.js';
import NotLoggedInHeader from "../../elements/header/notLoggedInHeader";
import NotLoggedIn from "./notLoggedIn";
import Loader from 'react-loader-spinner';
import HomeContent from "./homeContent";
import './home.scss';
import UserProfile from '../../helpers/userProfile.js';
import Error from '../../elements/error/error.js';
import Footer from "../../elements/footer/footer";
import ParlayButton from "../../assets/parlayButton";
import CloseIcon from "../../assets/closeIcon";
import Tooltip from "../../assets/tooltip";
import ParlayBox from "./parlayBox";

/**
 * A component representing the home page of the web app
 * Users will land here when they navigate to the root
 * Will handle whether they are logged in or not and direct them to the login/signup page if not
 * Will contain all available bets (specials, game lines, and props)
 * This is where users will view all odds and place all bets if they are logged in
 */
class Home extends Component {

	//constructor
	constructor(props) {
		super(props);

		//checking to see if the user is logged in and setting user stats and login status accordingly
		if(UserProfile.getUserInfo()) {
			UserProfile.updateStats();
			this.user = UserProfile.getUserInfo();
		}
		this.isLoggedIn = UserProfile.getIsLoggedIn();

		//base states
		this.state = {
			error: null,
			isLoaded: false,
			userLiveBets: [],
			userUpcomingBets: [],
			parlayBuilder: false,
			parlayTooltip: false,
			betPopupEnabled: false,
			parlay: [],
		};

		this.getPopupState = this.getPopupState.bind(this);
		this.addBetToParlay = this.addBetToParlay.bind(this);
		this.removeBetFromParlay = this.removeBetFromParlay.bind(this);
	}

	addBetToParlay = (bet) => {
		let newParlay = this.state.parlay;
		newParlay.push(bet);
		this.setState({ parlay: newParlay })
	}

	removeBetFromParlay = (bet) => {
		let i = this.state.parlay.length;
		while (i--) {
			if (this.state.parlay[i] && this.state.parlay[i].hasOwnProperty("parlayBetId") && (this.state.parlay[i].parlayBetId === bet.parlayBetId )) {
				let newParlay = this.state.parlay;
				newParlay.splice(i, 1);
				this.setState({ parlay: newParlay });
			}
		}
	}

	getPopupState = (val) => {
		this.setState({ betPopupEnabled: val === "Popup Enabled" })
	}

	/**
	 * method called when the component mounts
	 * will get games user live bets from the backend if logged in
	 */
	componentDidMount() {

		//if the user is logged in, get users live bets
		if (this.isLoggedIn) {
			const userEmail = this.user.email;
			const apiKey = process.env.REACT_APP_APIK;
			axios.post(process.env.REACT_APP_APIL + "userLiveBets", { apiKey, userEmail })
				.then(result => {
					// console.log(result);
					// console.log(result.data);
					const bets = result.data;
					this.setState({
						isLoaded: true,
						userLiveBets: bets.live,
						userUpcomingBets: bets.upcoming,
						leaderboardPosition: bets.leaderBoardPosition,
					});
				})
				.catch(error => {
					console.log(error);
					this.setState({
						isLoaded: true,
						error: error
					})
				});
		}
		else {
			this.setState({
				isLoaded: true,
			})
		}
	}

	frontingClicked = () => {
		this.props.history.push('/login');
	}

	toggleParlayBuilder = () => {
		this.setState({ parlayBuilder: !this.state.parlayBuilder});
	}

	toggleParlayTooltip = () => {
		this.setState({ parlayTooltip: !this.state.parlayTooltip});
	}


	/**
	 * render method
	 */
	render() {

		const { error, isLoaded } = this.state;

		//if there is an error return the error component
		if (error) {
			return <Error message={error.message}/>;
		}
		//loader component if the component is loading
		else if (!isLoaded) {
			return <div id="loader"><Loader type="Oval" color="#3DF5D9" height={100} width={100} /></div>;
		}
		//otherwise return the home content either in front of the not logged in div or not
		else {
			return (
				<div id="home-page-content">
					<div id="header">
						{this.isLoggedIn ? (
							<Header liveBets={this.state.userLiveBets} upcomingBets={this.state.userUpcomingBets}
									bank={this.user.bank} username={this.user.username} userPos={this.state.leaderboardPosition}/>
						) : (
							<NotLoggedInHeader/>
						)}
					</div>
					<div id="homeContent">
						{this.isLoggedIn ? (
							<HomeContent currentParlay={this.state.parlay} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} sendPopupState={this.getPopupState} parlayMode={this.state.parlayBuilder}/>
						) : (
							<NotLoggedIn/>
						)}
					</div>
					<div id="footer">
						<Footer/>
					</div>
					{!this.state.betPopupEnabled ? (
						<div id={"parlay-builder"}>
							{this.state.parlayBuilder && !this.state.parlayTooltip ? (
								<div id={"parlay-building-screen"}>
									<div id={"parlay-builder-title-bar"}>
										<div onClick={this.toggleParlayBuilder} id={"close-parlay-builder"}>
											<CloseIcon/>
										</div>
										<h1>Parlay Builder</h1>
										<div onClick={this.toggleParlayTooltip} id={"parlay-tooltip"}>
											<Tooltip/>
										</div>
									</div>
									<div id={"parlay-bet-box"}>
										<ParlayBox sendPopupState={this.getPopupState} currentParlay={this.state.parlay}/>
									</div>
								</div>
							) : this.state.parlayTooltip && this.state.parlayTooltip ? (
								<div id={"parlay-tooltip-screen"}>
									<div id={"parlay-tooltip-content"}>
										<div onClick={this.toggleParlayTooltip} id={"close-parlay-tooltip"}>
											<CloseIcon/>
										</div>
										<div id={"parlay-instructions"}>
											Parlays are bets that combine 2 or more regular bets into one to get better odds. You can select up to 10 available bets and parlay them. All legs of the parlay must win for you to win. <b>You cannot parlay spread and money line bets from the same game.</b>
										</div>
									</div>
								</div>
							) : (
								<div onClick={this.toggleParlayBuilder} id={"parlay-button"}>
									<ParlayButton/>
								</div>
							)}
						</div>
					) : null}
				</div>
			);
		}
	}
}

export default Home;