import React from 'react';

class TrendDown extends React.Component {
    render() {
        return (
            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H6.25V100H0V0ZM6.25 93.75H100V100H6.25V93.75Z" fill="white"/>
                <path d="M0 0H6.25V100H0V0ZM6.25 93.75H100V100H6.25V93.75Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M96.152 64.7467L68.9707 30.7592L49.9645 49.778L27.177 26.9842L22.7519 31.4092L49.9645 58.6154L68.4582 40.1342L91.2707 68.6529L96.152 64.7529L96.152 64.7467Z" fill="#FF0000"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M68.7139 69.8217C68.7139 70.6505 69.0431 71.4453 69.6292 72.0314C70.2152 72.6174 71.0101 72.9467 71.8389 72.9467L96.8389 72.9467C97.6677 72.9467 98.4625 72.6174 99.0486 72.0314C99.6346 71.4453 99.9639 70.6505 99.9639 69.8217L99.9639 44.8216C99.9639 43.9928 99.6346 43.198 99.0486 42.6119C98.4625 42.0259 97.6677 41.6966 96.8389 41.6966C96.0101 41.6966 95.2152 42.0259 94.6292 42.6119C94.0431 43.198 93.7139 43.9928 93.7139 44.8216L93.7139 66.6967L71.8389 66.6967C71.0101 66.6967 70.2152 67.0259 69.6292 67.6119C69.0431 68.198 68.7139 68.9929 68.7139 69.8217Z" fill="#FF0000"/>
            </svg>
        )
    }
}

export default TrendDown;