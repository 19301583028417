//imports
import React, { Component } from 'react';
import '../../index.scss';
import './parlayBox.scss';
import Popup from "../../elements/game/popup";
import Config from "../../helpers/config";

/**
 * An element that will represent the parlay odds box
 * Will read "n leg parlay" and display odds if bets have been selected
 * Will show popup when clicked
 * Will read "select bets to get started" if no bets have been selected yet
 */
class ParlayBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showPopup: false
        }

        this.togglePopup = this.togglePopup.bind(this);
    }

    togglePopup() {
        this.setState({ showPopup: !this.state.showPopup });
        // this.props.sendPopupState(!this.state.showPopup ? "Popup Enabled" : "Popup Disabled");
    }

    getOdds = (parlay) => {
        let parlayMultiplier = 1;

        parlay.forEach(leg => {
            let multiplier = Config.americanToDecimal(leg.betInfo.odds);
            parlayMultiplier *= multiplier;
        })

        const parlayOdds = Config.decimalToAmerican(parlayMultiplier);

        return parlayOdds > 0 ? `+${parlayOdds.toFixed(0)}` : `${parlayOdds.toFixed(0)}`;
    }

    getName = (parlay) => {
        let name = `${parlay.length} Leg Parlay: `;

        let counter = 1;
        parlay.forEach(leg => {
            let thisLegName = Config.nameBet(leg.betInfo.betType, leg.betInfo.awayTeam, leg.betInfo.homeTeam, leg.betInfo.points, leg.betInfo.name);
            name = counter === parlay.length ? name + thisLegName : name + `${thisLegName}, `;
            counter++;
        });
        return name;
    }

    getLegInfoArr = (parlay) => {
        const allowed = ['awayTeam', 'betType', 'gameId', 'homeTeam', 'odds', 'points'];


        let legInfoArr = [];
        parlay.forEach(leg => {
            const filteredLeg = Object.keys(leg.betInfo)
                .filter(key => allowed.includes(key))
                .reduce((obj, key) => {
                    obj[key] = leg.betInfo[key];
                    return obj;
                }, {});
            legInfoArr.push(filteredLeg);
        });

        return legInfoArr;
    }

    checkParlay = (parlay) => {
        let err = "";
        //check for length of parlay
        if (parlay.length > 10) err = "This parlay is too long! Parlays must have 10 or fewer legs.";
        if (parlay.length < 2) err = "This parlay is too short! Parlays must have 2 or more legs.";
        //check for legs having proper bets
        let currentLegCounter = 0;
        parlay.forEach(currentLeg => {
            let otherLegCounter = 0;
            parlay.forEach(otherLeg => {
                if (currentLegCounter !== otherLegCounter) {
                    if (currentLeg.betInfo.gameId === otherLeg.betInfo.gameId) {
                        if (currentLeg.betInfo.betType < 5 && otherLeg.betInfo.betType < 5) {
                            err = "You cannot parlay money line and spread bets from the same game.";
                        }
                    }
                }
                otherLegCounter++;
            });
            currentLegCounter++;
        });
        return err;
    }

    /**
     * render function
     */
    render() {

        let oddsStr = this.getOdds(this.props.currentParlay);
        let parlayName = this.getName(this.props.currentParlay);
        let errorMessage = this.checkParlay(this.props.currentParlay);
        let parlayInfo = this.getLegInfoArr(this.props.currentParlay);

        return (
            <div id={"parlay-box"}>
                <button id={"parlay-box-button"} onClick={this.togglePopup.bind(this)} disabled={this.props.currentParlay.length === 0}>
                    {this.props.currentParlay.length > 0 ? (
                        <div>
                            <div id="parlay-leg-count">{this.props.currentParlay.length} Leg Parlay</div>
                            <p id="parlay-odds">{oddsStr}</p>
                        </div>
                    ) : (
                        <p id={"no-bets-selected"}>Select some bets to add them to the parlay!</p>
                    )}
                </button>
                {this.state.showPopup ?
                    <Popup
                        closePopup={this.togglePopup.bind(this)}
                        betType={9}
                        odds={oddsStr}
                        parlayName={parlayName}
                        errorMessage={errorMessage}
                        parlay={parlayInfo}
                    />
                    :
                    null
                }
            </div>
        );
    }
}

export default ParlayBox;