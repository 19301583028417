import React from 'react'; 

class LiveBetsIcon extends React.Component {
    render() {
        return (
            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="37" height="37" fill="#3DF5D9"/>
                <path d="M24.0191 25.9L18.5 22.3542L12.9808 25.9L14.6458 19.5484L9.57373 15.4167L16.1258 15.0159L18.5 8.94169L20.8741 15.0159L27.4262 15.4167L22.3541 19.5484L24.0191 25.9ZM30.8333 18.5C30.8333 17.6823 31.1582 16.898 31.7364 16.3198C32.3146 15.7415 33.0989 15.4167 33.9166 15.4167V9.25002C33.9166 8.43227 33.5918 7.64801 33.0136 7.06977C32.4353 6.49154 31.6511 6.16669 30.8333 6.16669H6.16665C5.3489 6.16669 4.56464 6.49154 3.9864 7.06977C3.40816 7.64801 3.08331 8.43227 3.08331 9.25002V15.4167C3.90106 15.4167 4.68532 15.7415 5.26356 16.3198C5.8418 16.898 6.16665 17.6823 6.16665 18.5C6.16665 19.3178 5.8418 20.102 5.26356 20.6803C4.68532 21.2585 3.90106 21.5834 3.08331 21.5834V27.75C3.08331 28.5678 3.40816 29.352 3.9864 29.9303C4.56464 30.5085 5.3489 30.8334 6.16665 30.8334H30.8333C31.6511 30.8334 32.4353 30.5085 33.0136 29.9303C33.5918 29.352 33.9166 28.5678 33.9166 27.75V21.5834C33.0989 21.5834 32.3146 21.2585 31.7364 20.6803C31.1582 20.102 30.8333 19.3178 30.8333 18.5Z" fill="#07141C"/>
            </svg>
        )
    }
}

export default LiveBetsIcon;