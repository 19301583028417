//imports
import React, {Component} from 'react';
import '../../index.scss';
import './tos.scss';
import { withRouter } from 'react-router-dom';

/**
 * An element to represent the raw data in the tos agreement. Will house the main document.
 */
class TOS extends Component {

    /**
     * render method
     */
    render() {
        return (
            <div className="tos-raw">
                <h2>Terms and Conditions</h2>

                <p><strong>Agreement between User and fantombook.com</strong></p>
                <p>Welcome to fantombook.com. The fantombook.com website (the "Site") consists of various web pages
                    operated by FantomBook. fantombook.com is offered to you conditioned on your acceptance without
                    modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of
                    fantombook.com constitutes your agreement to all such Terms. Please read these terms carefully, and
                    keep a copy of them for your reference.</p>

                <p>fantombook.com is a fantasy sports book betting site.</p>

                <p>The purpose of fantombook.com is to allow those who cannot legally bet on sports and those who do not
                    want to bet real money, to do so without spending real money. In no way is wagering on
                    fantombook.com real betting, as users do not spend any money when wagering on games. Betting on
                    fantombook.com is legal in all 50 states for people of any age.</p>

                <p><strong>Privacy</strong></p>
                <p>Your use of fantombook.com is subject to FantomBook's Privacy Policy. Please review our Privacy
                    Policy that is below. It also governs the Site and informs users of our data collection
                    practices.</p>

                <p><strong>Electronic Communications</strong></p>
                <p>Visiting fantombook.com or sending emails to FantomBook constitutes electronic communications. You
                    consent to receive electronic communications and you agree that all agreements, notices, disclosures
                    and other communications that we provide to you electronically, via email and on the Site, satisfy
                    any legal requirement that such communications be in writing.</p>

                <p><strong>Your Account</strong></p>
                <p>If you use this site, you are responsible for maintaining the confidentiality of your account and
                    password and for restricting access to your computer, and you agree to accept responsibility for all
                    activities that occur under your account or password. You may not assign or otherwise transfer your
                    account to any other person or entity. You acknowledge that FantomBook is not responsible for third
                    party access to your account that results from theft or misappropriation of your account. FantomBook
                    and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or
                    edit content at our sole discretion.</p>

                <p><strong>Children Under Thirteen</strong></p>
                <p>FantomBook does not knowingly collect, either online or offline, personal information from persons
                    under the age of thirteen. If you are under 18, you may use fantombook.com only with permission of a
                    parent or guardian.</p>

                <p><strong>Links to Third Party Sites/Third Party Services</strong></p>
                <p>fantombook.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under
                    the control of FantomBook and FantomBook is not responsible for the contents of any Linked Site,
                    including without limitation any link contained in a Linked Site, or any changes or updates to a
                    Linked Site. FantomBook is providing these links to you only as a convenience, and the inclusion of
                    any link does not imply endorsement by FantomBook of the site or any association with its
                    operators.</p>

                <p>Certain services made available via fantombook.com are delivered by third party sites and
                    organizations. By using any product, service or functionality originating from the fantombook.com
                    domain, you hereby acknowledge and consent that FantomBook may share such information and data with
                    any third party with whom FantomBook has a contractual relationship to provide the requested
                    product, service or functionality on behalf of fantombook.com users and customers.</p>

                <p><strong>No Unlawful or Prohibited Use/Intellectual Property</strong></p>
                <p>You are granted a non-exclusive, non-transferable, revocable license to access and use fantombook.com
                    strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant
                    to FantomBook that you will not use the Site for any purpose that is unlawful or prohibited by these
                    Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the
                    Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or
                    attempt to obtain any materials or information through any means not intentionally made available or
                    provided for through the Site.</p>

                <p>All content included as part of the Service, such as text, graphics, logos, images, as well as the
                    compilation thereof, and any software used on the Site, is the property of FantomBook or its
                    suppliers and protected by copyright and other laws that protect intellectual property and
                    proprietary rights. You agree to observe and abide by all copyright and other proprietary notices,
                    legends or other restrictions contained in any such content and will not make any changes
                    thereto.</p>

                <p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
                    derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.
                    FantomBook content is not for resale. Your use of the Site does not entitle you to make any
                    unauthorized use of any protected content, and in particular you will not delete or alter any
                    proprietary rights or attribution notices in any content. You will use protected content solely for
                    your personal use, and will make no other use of the content without the express written permission
                    of FantomBook and the copyright owner. You agree that you do not acquire any ownership rights in any
                    protected content. We do not grant you any licenses, express or implied, to the intellectual
                    property of FantomBook or our licensors except as expressly authorized by these Terms.</p>

                <p><strong>Use of Communication Services</strong></p>
                <p>The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal
                    web pages, calendars, and/or other message or communication facilities designed to enable you to
                    communicate with the public at large or with a group (collectively, "Communication Services"). You
                    agree to use the Communication Services only to post, send and receive messages and material that
                    are proper and related to the particular Communication Service.</p>

                <p>By way of example, and not as a limitation, you agree that when using a Communication Service, you
                    will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as
                    rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any
                    inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material
                    or information; upload files that contain software or other material protected by intellectual
                    property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or
                    have received all necessary consents; upload files that contain viruses, corrupted files, or any
                    other similar software or programs that may damage the operation of another's computer; advertise or
                    offer to sell or buy any goods or services for any business purpose, unless such Communication
                    Service specifically allows such messages; conduct or forward surveys, contests, pyramid schemes or
                    chain letters; download any file posted by another user of a Communication Service that you know, or
                    reasonably should know, cannot be legally distributed in such manner; falsify or delete any author
                    attributions, legal or other proper notices or proprietary designations or labels of the origin or
                    source of software or other material contained in a file that is uploaded; restrict or inhibit any
                    other user from using and enjoying the Communication Services; violate any code of conduct or other
                    guidelines which may be applicable for any particular Communication Service; harvest or otherwise
                    collect information about others, including email addresses, without their consent; violate any
                    applicable laws or regulations.</p>

                <p>FantomBook has no obligation to monitor the Communication Services. However, FantomBook reserves the
                    right to review materials posted to a Communication Service and to remove any materials in its sole
                    discretion. FantomBook reserves the right to terminate your access to any or all of the
                    Communication Services at any time without notice for any reason whatsoever.</p>

                <p>FantomBook reserves the right at all times to disclose any information as necessary to satisfy any
                    applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to
                    remove any information or materials, in whole or in part, in FantomBook's sole discretion.</p>

                <p>Always use caution when giving out any personally identifying information about yourself or your
                    children in any Communication Service. FantomBook does not control or endorse the content, messages
                    or information found in any Communication Service and, therefore, FantomBook specifically disclaims
                    any liability with regard to the Communication Services and any actions resulting from your
                    participation in any Communication Service. Managers and hosts are not authorized FantomBook
                    spokespersons, and their views do not necessarily reflect those of FantomBook.</p>

                <p>Materials uploaded to a Communication Service may be subject to posted limitations on usage,
                    reproduction and/or dissemination. You are responsible for adhering to such limitations if you
                    upload the materials.</p>

                <p><strong>Materials Provided to fantombook.com or Posted on Any FantomBook Web Page</strong></p>
                <p>FantomBook does not claim ownership of the materials you provide to fantombook.com (including
                    feedback and suggestions) or post, upload, input or submit to any FantomBook Site or our associated
                    services (collectively "Submissions"). However, by posting, uploading, inputting, providing or
                    submitting your Submission you are granting FantomBook, our affiliated companies and necessary
                    sublicensees permission to use your Submission in connection with the operation of their Internet
                    businesses including, without limitation, the rights to: copy, distribute, transmit, publicly
                    display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish
                    your name in connection with your Submission.</p>

                <p>No compensation will be paid with respect to the use of your Submission, as provided herein.
                    FantomBook is under no obligation to post or use any Submission you may provide and may remove any
                    Submission at any time in FantomBook's sole discretion.</p>

                <p>By posting, uploading, inputting, providing or submitting your Submission you warrant and represent
                    that you own or otherwise control all of the rights to your Submission as described in this section
                    including, without limitation, all the rights necessary for you to provide, post, upload, input or
                    submit the Submissions.</p>

                <p><strong>International Users</strong></p>
                <p>The Service is controlled, operated and administered by FantomBook from our offices within the USA.
                    If you access the Service from a location outside the USA, you are responsible for compliance with
                    all local laws. You agree that you will not use the FantomBook Content accessed through
                    fantombook.com in any country or in any manner prohibited by any applicable laws, restrictions or
                    regulations.</p>

                <p><strong>Indemnification</strong></p>
                <p>You agree to indemnify, defend and hold harmless FantomBook, its officers, directors, employees,
                    agents and third parties, for any losses, costs, liabilities and expenses (including reasonable
                    attorney's fees) relating to or arising out of your use of or inability to use the Site or services,
                    any user postings made by you, your violation of any terms of this Agreement or your violation of
                    any rights of a third party, or your violation of any applicable laws, rules or regulations.
                    FantomBook reserves the right, at its own cost, to assume the exclusive defense and control of any
                    matter otherwise subject to indemnification by you, in which event you will fully cooperate with
                    FantomBook in asserting any available defenses.</p>

                <p><strong>Liability Disclaimer</strong></p>
                <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY
                    INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION
                    HEREIN. FANTOMBOOK AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY
                    TIME.</p>

                <p>FANTOMBOOK AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
                    AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
                    GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
                    ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
                    WARRANTY OR CONDITION OF ANY KIND. FANTOMBOOK AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES
                    AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
                    INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                    TITLE AND NON-INFRINGEMENT.</p>

                <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL FANTOMBOOK AND/OR ITS SUPPLIERS
                    BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY
                    DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING
                    OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY
                    TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
                    INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR
                    OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
                    LIABILITY OR OTHERWISE, EVEN IF FANTOMBOOK OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
                    POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION
                    OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF
                    YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND
                    EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>

                <p><strong>Termination/Access Restriction</strong></p>
                <p>FantomBook reserves the right, in its sole discretion, to terminate your access to the Site and the
                    related services or any portion thereof at any time, without notice. Use of the Site is unauthorized
                    in any jurisdiction that does not give effect to all provisions of these Terms, including, without
                    limitation, this section.</p>

                <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you
                    and FantomBook as a result of this agreement or use of the Site. FantomBook's performance of this
                    agreement is subject to existing laws and legal process, and nothing contained in this agreement is
                    in derogation of FantomBook's right to comply with governmental, court and law enforcement requests
                    or requirements relating to your use of the Site or information provided to or gathered by
                    FantomBook with respect to such use. If any part of this agreement is determined to be invalid or
                    unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and
                    liability limitations set forth above, then the invalid or unenforceable provision will be deemed
                    superseded by a valid, enforceable provision that most closely matches the intent of the original
                    provision and the remainder of the agreement shall continue in effect.</p>

                <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user
                    and FantomBook with respect to the Site and it supersedes all prior or contemporaneous
                    communications and proposals, whether electronic, oral or written, between the user and FantomBook
                    with respect to the Site. A printed version of this agreement and of any notice given in electronic
                    form shall be admissible in judicial or administrative proceedings based upon or relating to this
                    agreement to the same extent and subject to the same conditions as other business documents and
                    records originally generated and maintained in printed form. It is the express wish to the parties
                    that this agreement and all related documents be written in English.</p>

                <p><strong>Changes to Terms</strong></p>
                <p>FantomBook reserves the right, in its sole discretion, to change the Terms under which fantombook.com
                    is offered. The most current version of the Terms will supersede all previous versions. FantomBook
                    encourages you to periodically review the Terms to stay informed of our updates.</p>

                <p><strong>Contact Us</strong></p>
                <p>FantomBook welcomes your questions or comments regarding the Terms:</p>

                <p>Email Address:</p>
                <p>fantombook@fantombook.com</p>


                <h2>Privacy Policy</h2>
                <p></p>
                <p>Protecting your private information is our priority. This Statement of Privacy applies to
                    fantombook.com and FantomBook and governs data collection and usage. For the purposes of this
                    Privacy Policy, unless otherwise noted, all references to FantomBook include fantombook.com. The
                    FantomBook website is a fantasy sports book betting site site. By using the FantomBook website, you
                    consent to the data practices described in this statement.</p>

                <p><strong>Collection of your Personal Information</strong></p>
                <p>In order to better provide you with products and services offered on our Site, FantomBook may collect
                    personally identifiable information, such as your:</p>

                <ul>
                    <li>Email address</li>
                    <li>Username</li>
                </ul>
                <p></p>
                <p>If you purchase FantomBook's products and services, we collect billing and credit card information.
                    This information is used to complete the purchase transaction.</p>
                <p></p>
                <p>FantomBook may also collect anonymous demographic information, which is not unique to you, such as
                    your:</p>

                <ul>
                    <li>Age</li>
                    <li>Gender</li>
                </ul>
                <p></p>
                <p>Please keep in mind that if you directly disclose personally identifiable information or personally
                    sensitive data through FantomBook's public message boards, this information may be collected and
                    used by others.</p>

                <p>We do not collect any personal information about you unless you voluntarily provide it to us.
                    However, you may be required to provide certain personal information to us when you elect to use
                    certain products or services available on the Site. These may include: (a) registering for an
                    account on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners;
                    (c) sending us an email message; (d) submitting your credit card or other payment information when
                    ordering and purchasing products and services on our Site. To wit, we will use your information for,
                    but not limited to, communicating with you in relation to services and/or products you have
                    requested from us. We also may gather additional personal or non-personal information in the
                    future.</p>

                <p><strong>Use of your Personal Information</strong></p>
                <p>FantomBook collects and uses your personal information to operate its website(s) and deliver the
                    services you have requested.</p>

                <p><strong>Sharing Information with Third Parties</strong></p>
                <p>FantomBook does not sell, rent or lease its customer lists to third parties.</p>

                <p>FantomBook may share data with trusted partners to help perform statistical analysis, send you email
                    or postal mail, provide customer support, or arrange for deliveries. All such third parties are
                    prohibited from using your personal information except to provide these services to FantomBook, and
                    they are required to maintain the confidentiality of your information.</p>

                <p>FantomBook may disclose your personal information, without notice, if required to do so by law or in
                    the good faith belief that such action is necessary to: (a) conform to the edicts of the law or
                    comply with legal process served on FantomBook or the site; (b) protect and defend the rights or
                    property of FantomBook; and/or (c) act under exigent circumstances to protect the personal safety of
                    users of FantomBook, or the public.</p>

                <p><strong>Tracking User Behavior</strong></p>
                <p>FantomBook may keep track of the websites and pages our users visit within FantomBook, in order to
                    determine what FantomBook services are the most popular. This data is used to deliver customized
                    content and advertising within FantomBook to customers whose behavior indicates that they are
                    interested in a particular subject area.</p>

                <p><strong>Automatically Collected Information</strong></p>
                <p>Information about your computer hardware and software may be automatically collected by FantomBook.
                    This information can include: your IP address, browser type, domain names, access times and
                    referring website addresses. This information is used for the operation of the service, to maintain
                    quality of the service, and to provide general statistics regarding use of the FantomBook
                    website.</p>

                <p><strong>Use of Cookies</strong></p>
                <p>The FantomBook website may use "cookies" to help you personalize your online experience. A cookie is
                    a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run
                    programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be
                    read by a web server in the domain that issued the cookie to you.</p>

                <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The
                    purpose of a cookie is to tell the Web server that you have returned to a specific page. For
                    example, if you personalize FantomBook pages, or register with the FantomBook site or services, a
                    cookie helps FantomBook to recall your specific information on subsequent visits. This simplifies
                    the process of recording your personal information, such as billing addresses, shipping addresses,
                    and so on. When you return to the same FantomBook website, the information you previously provided
                    can be retrieved, so you can easily use the FantomBook features that you customized.</p>

                <p>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies,
                    but you can usually modify your browser setting to decline cookies if you prefer. If you choose to
                    decline cookies, you may not be able to fully experience the interactive features of the FantomBook
                    services or websites you visit.</p>

                <p><strong>Links</strong></p>
                <p>This website contains links to other sites. Please be aware that we are not responsible for the
                    content or privacy practices of such other sites. We encourage our users to be aware when they leave
                    our site and to read the privacy statements of any other site that collects personally identifiable
                    information.</p>

                <p><strong>Security of your Personal Information</strong></p>
                <p>FantomBook secures your personal information from unauthorized access, use, or disclosure. FantomBook
                    uses the following methods for this purpose:</p>

                <ul>
                    <li>SSL Protocol</li>
                </ul>

                <p></p>
                <p>When personal information (such as a credit card number) is transmitted to other websites, it is
                    protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>

                <p>We strive to take appropriate security measures to protect against unauthorized access to or
                    alteration of your personal information. Unfortunately, no data transmission over the Internet or
                    any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect
                    your personal information, you acknowledge that: (a) there are security and privacy limitations
                    inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of
                    any and all information and data exchanged between you and us through this Site cannot be
                    guaranteed.</p>

                <p><strong>Right to Deletion</strong></p>
                <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we
                    will:</p>

                <ul>
                    <li>Delete your personal information from our records; and</li>
                    <li>Direct any service providers to delete your personal information from their records.</li>
                </ul>

                <p>Please note that we may not be able to comply with requests to delete your personal information if it
                    is necessary to:</p>

                <ul>
                    <li>Complete the transaction for which the personal information was collected, fulfill the terms of
                        a written warranty or product recall conducted in accordance with federal law, provide a good or
                        service requested by you, or reasonably anticipated within the context of our ongoing business
                        relationship with you, or otherwise perform a contract between you and us;
                    </li>
                    <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
                        activity; or prosecute those responsible for that activity;
                    </li>
                    <li>Debug to identify and repair errors that impair existing intended functionality;</li>
                    <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free
                        speech, or exercise another right provided for by law;
                    </li>
                    <li>Comply with the California Electronic Communications Privacy Act;</li>
                    <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public
                        interest that adheres to all other applicable ethics and privacy laws, when our deletion of the
                        information is likely to render impossible or seriously impair the achievement of such research,
                        provided we have obtained your informed consent;
                    </li>
                    <li>Enable solely internal uses that are reasonably aligned with your expectations based on your
                        relationship with us;
                    </li>
                    <li>Comply with an existing legal obligation; or</li>
                    <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with
                        the context in which you provided the information.
                    </li>
                </ul>

                <p><strong>Children Under Thirteen</strong></p>
                <p>FantomBook does not knowingly collect personally identifiable information from children under the age
                    of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for
                    permission to use this website.</p>

                <p><strong>E-mail Communications</strong></p>
                <p>From time to time, FantomBook may contact you via email for the purpose of providing announcements,
                    promotional offers, alerts, confirmations, surveys, and/or other general communication.</p>

                <p>If you would like to stop receiving marketing or promotional communications via email from
                    FantomBook, you may opt out of such communications by locating the unsubscribe link on the bottom of
                    emails.</p>

                <p><strong>Changes to this Statement</strong></p>
                <p>FantomBook reserves the right to change this Privacy Policy from time to time. We will notify you
                    about significant changes in the way we treat personal information by sending a notice to the
                    primary email address specified in your account, by placing a prominent notice on our site, and/or
                    by updating any privacy information on this page. Your continued use of the Site and/or Services
                    available through this Site after such modifications will constitute your: (a) acknowledgment of the
                    modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>

                <p><strong>Contact Information</strong></p>
                <p>FantomBook welcomes your questions or comments regarding this Statement of Privacy. If you believe
                    that FantomBook has not adhered to this Statement, please contact FantomBook at:</p>

                <p>Email Address:</p>
                <p>fantombook@fantombook.com</p>

                <button id="agree-button" onClick={() => this.props.history.goBack()}>Agree</button>
            </div>
        );
    }
}

export default withRouter(TOS);