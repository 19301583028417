//imports
import React, { Component } from 'react';
import './error.scss';

/**
 * An element that will represent what the screen shows when there is an error
 * Will display what type of error and tell the user to try again later
 */
class Error extends Component {

    /**
     * render method
     */
	render() {
		return (
            <div id="errContainer">
                <h1>OOPS!</h1>
                <h3>There appears to be some sort of error.</h3>
                <h3>More information is below.</h3>
                <h3>Please try again later, or contact help@fantombook.com</h3>
                <p>{this.props.message}</p>
            </div>
		);
	}
}

export default Error;