import React from 'react';

class TrendUp extends React.Component {
    render() {
        return (
            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H6.25V100H0V0ZM6.25 93.75H100V100H6.25V93.75Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M89.9371 26.9499L62.7559 60.9375L43.7496 41.9187L20.9621 64.7125L16.5371 60.2875L43.7496 33.0812L62.2434 51.5625L85.0559 23.0437L89.9371 26.9437V26.9499Z" fill="#3DF5D9"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M62.5 21.875C62.5 21.0462 62.8292 20.2513 63.4153 19.6653C64.0013 19.0792 64.7962 18.75 65.625 18.75H90.625C91.4538 18.75 92.2487 19.0792 92.8347 19.6653C93.4208 20.2513 93.75 21.0462 93.75 21.875V46.875C93.75 47.7038 93.4208 48.4987 92.8347 49.0847C92.2487 49.6708 91.4538 50 90.625 50C89.7962 50 89.0013 49.6708 88.4153 49.0847C87.8292 48.4987 87.5 47.7038 87.5 46.875V25H65.625C64.7962 25 64.0013 24.6708 63.4153 24.0847C62.8292 23.4987 62.5 22.7038 62.5 21.875Z" fill="#3DF5D9"/>
                <path d="M0 0H6.25V100H0V0ZM6.25 93.75H100V100H6.25V93.75Z" fill="white"/>
            </svg>
        )
    }
}

export default TrendUp;