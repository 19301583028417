//imports
import React, { Component } from 'react';
import '../../index.scss';
import './noGames.scss'

/**
 * An element to show an empty state when there are no games available in that league
 */
class NoGames extends Component {

    /**
     * render method
     */
    render() {
          return (
                <div className="noGame">
                      <div id="info">
                          <h2 id="team">There are no upcoming {this.props.league} games!</h2>
                      </div>
                </div>
          );
    }
}

export default NoGames;