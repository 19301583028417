//imports
import React, { Component } from 'react';
import BankLogo from '../../assets/banklogo';
import PointsLogo from '../../assets/pointslogo';
import SideBarIcon from '../../assets/sideBarIcon';
import LiveBetsIcon from '../../assets/liveBetsIcon';
import LiveBetSidebarItem from './liveBetSideBarItem';
import FBLetterIcon from '../../assets/fbLetterIcon';
import CloseIcon from '../../assets/closeIcon';
import { withRouter } from 'react-router-dom';
import '../../index.scss';
import './header.scss';

/**
 * An element to represent the header when the user is not logged in
 * Will do all the same things as the regular header but just show 0's for the bank and have a login button in the top right
 */
class NotLoggedInHeader extends Component {
	//constructor
	constructor(props) {
		super(props);

		this.state = {
			leftOpen: false,
			closeIcon: false,
		}
	}

	/**
	 * A method to toggle the sidebar from opening and closing
	 * @param event a click of the button containing the hamburger menu
	 */
	toggleSidebar = (event) => {
		let key = `${event.currentTarget.parentNode.id}Open`;
		this.setState({ [key]: !this.state[key] });
	}

	/**
	 * A function to handle when the user clicks anywhere on the page
	 * Will send them to the login page when clicked
	 */
	frontingClicked = () => {
		this.props.history.push('/login');
	}

	/**
	 * render method
	 */
	render() {

		//variables to handle the opening and closing of the sidebar
		let leftOpen = this.state.leftOpen ? 'open' : 'closed';
		let closeIcon = this.state.leftOpen ? <CloseIcon /> : <SideBarIcon />;

		return (
			<div id="header-fronting" onClick={this.frontingClicked}>
				<div id='layout'>
					<div id='left' className={leftOpen} >
						<div className='icon'
							onClick={this.toggleSidebar} >
							{closeIcon}
						</div>

						<div className={`sidebar ${leftOpen}`}>
							<div className='content'>
								<div className="home">
									<p id="liveBetsHeader"><FBLetterIcon className="icon" /><a className="heading" href="/">Home</a></p>
								</div>
								<div className="liveBetsSB">
									<p id="liveBetsHeader"><LiveBetsIcon className="icon" /><a className="heading" href="/account">My Live Bets</a></p>
									<LiveBetSidebarItem league="NHL" />
									<LiveBetSidebarItem league="MLB" />
									<LiveBetSidebarItem league="NFL" />
									<LiveBetSidebarItem league="NBA" />
								</div>
							</div>
						</div>
					</div>
					<div id="main">
						<div className="bank">
							<div className="points"><p id="points"><PointsLogo /> 0000.00</p></div>
							<div className="label"><BankLogo /></div>
						</div>
						<div><button id="loginButton"><p>LOG IN</p></button></div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(NotLoggedInHeader);