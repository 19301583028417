//imports
import React, { Component } from 'react';
import '../../index.scss';
import './liveBets.scss';
import LiveBet from './liveBet.js'

/**
 * An element to show all the users live bets
 * Will show all data about all of the users live bets in the LiveBet element form
 */
class LiveBets extends Component {

	/**
	 * render method
	 */
	render() {

		//for the key
		let i = 0;

		return (
			<div className="liveBets" id="liveBets">
				<h1 id="title">My Live Bets</h1>
				<div id="liveBetsTable">
					<table>
						<thead>
							<tr><th id="name">Bet Name</th><th id="lbInfo">Date Placed</th><th id="lbInfo">Odds</th><th id="lbInfo">Wager</th><th id="lbInfo">To Win</th><th id="lbInfo">Payout</th></tr>
						</thead>
					</table>
					{this.props.liveBets ? (
						this.props.liveBets.map(liveBet => (
							<LiveBet
								key={i++}
								name={liveBet.betName}
								date={liveBet.datePlaced}
								odds={liveBet.odds}
								wager={liveBet.wager}
								payout={liveBet.payout}
								toWin={liveBet.toWin}
							/>
							)
						)
					) : (
							<div><h1 id="emptyState">Looks Like You Don't Have Any Live Bets Right Now!</h1></div>
						)}
				</div>
			</div>
		);
	}
}

export default LiveBets;