import React from 'react';

class Tooltip extends React.Component {
    render() {
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 19C5.59 19 1 14.41 1 10C1 5.59 5.59 1 10 1C14.41 1 19 5.59 19 10C19 14.41 14.41 19 10 19ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z" fill="#C4C4C4"/>
            </svg>
        )
    }
}

export default Tooltip;