import React from 'react';

class TwitterIcon extends React.Component {
    render() {
        return (
            <svg width="40" height="33" viewBox="0 0 40 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5882 32.395C27.3477 32.395 35.3937 20.0595 35.3937 9.42728C35.3937 9.06599 35.3937 8.7047 35.3937 8.39502C36.9824 7.25954 38.3149 5.81437 39.3911 4.21437C37.9562 4.83373 36.4187 5.29824 34.7788 5.5047C36.4187 4.52405 37.6999 2.92405 38.3149 1.01437C36.7775 1.94341 35.035 2.61437 33.2413 2.97566C31.7551 1.37566 29.7052 0.39502 27.399 0.39502C22.9404 0.39502 19.353 4.00792 19.353 8.49825C19.353 9.1176 19.4043 9.73695 19.558 10.3563C12.8957 9.99502 7.00215 6.79502 3.00478 1.89179C2.33855 3.07889 1.92856 4.47244 1.92856 5.9176C1.92856 8.7047 3.36352 11.1821 5.51595 12.6273C4.18349 12.5757 2.95353 12.2144 1.87732 11.595C1.87732 11.6466 1.87732 11.6466 1.87732 11.6982C1.87732 15.6208 4.64473 18.8724 8.33461 19.595C7.66838 19.8015 6.9509 19.9047 6.23343 19.9047C5.72094 19.9047 5.20846 19.8531 4.74722 19.7499C5.77219 22.9499 8.7446 25.2724 12.2295 25.3757C9.46207 27.5434 6.02843 28.8337 2.2873 28.8337C1.62107 28.8337 1.00609 28.7821 0.391113 28.7305C3.876 31.0531 8.07837 32.395 12.5882 32.395Z" fill="white"/>
            </svg>
        )
    }
}

export default TwitterIcon;