import React from 'react';

class NHLSPIcon extends React.Component {
    render() {
        return (
            <svg width="132" height="91" viewBox="0 0 132 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M45.7167 85V68.992H42.3087V79.048L34.8927 68.992H31.3887V85H34.7967V74.56L42.4287 85H45.7167ZM63.2731 85V68.992H59.8411V75.28H52.2811V68.992H48.8731V85H52.2811V78.256H59.8411V85H63.2731ZM76.4838 85V82H69.7158V68.992H66.2838V85H76.4838Z" fill="white"/>
                <path d="M28.9944 40.2755V47.3829H33.7327V37.9063H31.3636C30.0606 37.9063 28.9944 38.9724 28.9944 40.2755ZM45.5785 37.9063H36.1019V47.3829L47.2132 47.3592C48.1135 47.3592 48.919 46.8617 49.3217 46.0562L51.3829 41.5548L47.6159 33.3102L45.5785 37.9063ZM75.6903 38.5934C75.4692 38.3735 75.2067 38.1995 74.918 38.0816C74.6293 37.9637 74.3201 37.9041 74.0082 37.9063H71.6391V47.3829H76.3774V40.2755C76.3774 39.6121 76.1168 39.0198 75.6903 38.5934ZM56.4765 30.4198L66.0716 9.47656H58.1349L53.9652 18.8821L52.8044 21.4881L52.6859 21.8198L47.2369 9.47656H39.3002L48.8953 30.4198L52.4964 38.2854L52.6859 38.7118L56.0501 46.0562C56.4528 46.8617 57.2584 47.3592 58.1586 47.3592L69.2699 47.3829V37.9063H59.7933L56.4765 30.4198Z" fill="white"/>
            </svg>
        )
    }
}

export default NHLSPIcon;