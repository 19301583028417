//imports
import React, { Component } from 'react';
import FooterMascot from '../../assets/footerMascot';
import InstaIcon from '../../assets/instaIcon';
import '../../index.scss';
import './footer.scss'
import ReactGA from "react-ga";

/**
 * An element that will act as the Footer, will appear on all pages
 * Will have email address listed for users to contact us
 * Will also include branding and links to social
 * Wil also include the version number of the app
 */
class Footer extends Component {

    instaViewed = () => {
        //create ga event
        ReactGA.event({
            category: 'Social Viewed',
            action: 'Instagram viewed',
        });
    }

	/**
	 * render method
	 */
	render() {

        const build = require('../../helpers/buildNum.json');

		return (
            <div className="footContainer">
                <div id="branding">
                    <FooterMascot />
                    <div id="social-mobile">
                        <p id="followUs">Follow Us On Social Media!</p>
                        <div id="socialIcons">
                            <a href="https://www.instagram.com/fantombook/"><InstaIcon/></a>
                        </div>
                    </div>
                    <div id="contactUs">
                        <p id="questions">Any Questions?</p>
                        <p id="emailUs">Email Us: <a href = "mailto: help@fantombook.com">help@fantombook.com</a></p>
                        <p id="buildNum">v{build.major}.{build.minor}.{build.revision}</p>
                    </div>
                </div>
                <div id="social">
                    <p id="followUs">Follow Us On Social Media!</p>
                    <div id="socialIcons">
                        <a onClick={this.instaViewed} href="https://www.instagram.com/fantombook/"><InstaIcon/></a>
                    </div>
                </div>
            </div>
		);
	}
}

export default Footer;