//imports
import React, { Component } from 'react';
import '../../index.scss';
import './propBet.scss'
import PropBox from'./propBox.js'

/**
 * An element to show a prop bet in a particular game or special
 */
class PropBet extends Component {

    constructor(props) {
        super(props);

        this.sendPopupState = this.sendPopupState.bind(this);
        this.addBetToParlay = this.addBetToParlay.bind(this);
        this.removeBetFromParlay = this.removeBetFromParlay.bind(this);
    }

    sendPopupState = (val) => {
        this.props.sendPopupState(val);
    }

    addBetToParlay = (bet) => {
        this.props.addToParlay(bet);
    }

    removeBetFromParlay = (bet) => {
        this.props.removeFromParlay(bet);
    }

    /**
     * render method
     */
    render() {
        return (
        <div id="prop-bet">
            <div id="name">
                {this.props.name}
            </div>
            <div id="oddsBox">
                <table id="prop-table">
                    <tbody>
                        <tr>
                            <td id="prop-data">
                                <PropBox currentParlay={this.props.currentParlay} parlayMode={this.props.parlayMode} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} sendPopupState={this.sendPopupState} points={1} betType={7} propId={this.props.propId} option={this.props.optionOneName} name={this.props.name + ": " + this.props.optionOneName} teams={this.props.teams} odds={this.props.optionOneOdds}/>
                            </td>
                            {this.props.optionTwoOdds !== 0 ?
                                (
                                    <td id="prop-data">
                                        <PropBox currentParlay={this.props.currentParlay} parlayMode={this.props.parlayMode} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} sendPopupState={this.sendPopupState} points={2} betType={7} propId={this.props.propId} option={this.props.optionTwoName} name={this.props.name + ": " + this.props.optionTwoName} teams={this.props.teams} odds={this.props.optionTwoOdds}/>
                                    </td>
                                ) : null
                            }
                        </tr>
                        <tr>
                            {this.props.optionThreeOdds !== 0 ?
                                (
                                    <td id="prop-data">
                                        <PropBox currentParlay={this.props.currentParlay} parlayMode={this.props.parlayMode} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} sendPopupState={this.sendPopupState} points={3} betType={7} propId={this.props.propId} option={this.props.optionThreeName} name={this.props.name + ": " + this.props.optionThreeName} teams={this.props.teams} odds={this.props.optionThreeOdds}/>
                                    </td>
                                ) : null
                            }
                            {this.props.optionFourOdds !== 0 ?
                                (
                                    <td id="prop-data">
                                        <PropBox currentParlay={this.props.currentParlay} parlayMode={this.props.parlayMode} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} sendPopupState={this.sendPopupState} points={4} betType={7} propId={this.props.propId} option={this.props.optionFourName} name={this.props.name + ": " + this.props.optionFourName} teams={this.props.teams} odds={this.props.optionFourOdds}/>
                                    </td>
                                ) : null
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        );
    }
}

export default PropBet;