//imports
import React, { Component } from 'react';
import ResetCodeForm from'../../elements/login/codeScreen.js'

/**
 * A class that will be the page where users can paste the reset code that they got
 */
class ResetCode extends Component {

  /**
   * render method
   */
  render() {
    return (
      <div className="resetCode">
        <ResetCodeForm/>
      </div>
    );
  }
}
 
export default ResetCode;