//imports
import React from 'react';
import './editInfoPopup.scss';
import CloseIcon from '../../assets/closeIcon';
import { Formik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { Base64 } from 'js-base64';
import { withRouter } from 'react-router-dom';
import UserProfile from '../../helpers/userProfile.js';
import ReactGA from "react-ga";

/**
 * An element to allow users to edit their info including username, email, and password
 * This element will be found in a popup when the click the "edit info" button
 */
class EditInfoPopup extends React.Component {
    //constructor
    constructor(props) {
        super(props);

        //base states
        this.state = {
            dbError: false,
            dbErrorInfo: "",
        }
    }

    /**
     * render method
     */
    render() {
        return (
            <div className='editInfoPopup'>
                <div className='editInfoPopupInner'>
                    <div className="headBar">
                        <h1 id="title">Edit Information</h1><button id="close" onClick={this.props.closePopup}><CloseIcon /></button>
                    </div>
                    <Formik
                        initialValues={{ email: "", username: "", password: "", confirmPassword: "", currentEmail: UserProfile.getEmail(), currentUsername: UserProfile.getUsername(), }}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 500);
                            const tempPW = values.password;
                            const tempCPW = values.confirmPassword;

                            //create ga event
                            ReactGA.event({
                                category: 'Edit Information',
                                action: 'Information attempted to be changed',
                            });

                            values.password = Base64.encode(values.password);
                            values.confirmPassword = Base64.encode(values.confirmPassword);
                            const apiKey = process.env.REACT_APP_APIK;

                            axios.post(process.env.REACT_APP_APIL + "updateInfo", { apiKey, values })
                                .then(res => {
                                    // console.log(res);
                                    // console.log(res.data);
                                    const responseCode = parseInt(res.data.status)
                                    if (values.email === "" && values.username === "" && values.password === "" &&  values.confirmPassword === "") {
                                        this.setState({ dbError: true, dbErrorInfo: "You must update at least 1 field!" });
                                    }
                                    else if (responseCode > 1) {
                                        this.setState({ dbError: true });
                                        if (responseCode === 2) {
                                            this.setState({ dbErrorInfo: "An account with the email you entered already exists!" });
                                        }
                                        else if (responseCode === 3) {
                                            this.setState({ dbErrorInfo: "The username you entered is already taken!" });
                                        }
                                        else if (responseCode === 4) {
                                            this.setState({ dbErrorInfo: "The email you entered is already the email associated with this account!" });
                                        }
                                        else if (responseCode === 5) {
                                            this.setState({ dbErrorInfo: "The username you entered is already the username associated with this account!" });
                                        }
                                        else if (responseCode === 6) {
                                            this.setState({ dbErrorInfo: "The passwords you entered do not match!" });
                                        }
                                        values.password = tempPW;
                                        values.confirmPassword = tempCPW;
                                    }
                                    else if (responseCode === 1) {
                                        this.setState({ dbError: false, dbErrorInfo: "" });
                                        UserProfile.storeUserProfile(res.data.userInfo);
                                        // this.props.history.push("/account");

                                        //create ga event
                                        ReactGA.event({
                                            category: 'Edit Information',
                                            action: 'Information successfully edited',
                                        });

                                        window.location.reload();
                                    }
                                    else {
                                        this.setState({ dbError: true, dbErrorInfo: "Something went wrong! Please try again" });
                                        values.password = tempPW;
                                        values.confirmPassword = tempCPW;
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                    this.setState({ dbError: true, dbErrorInfo: "Something went wrong! Please try again" });
                                    values.password = tempPW;
                                    values.confirmPassword = tempCPW;
                                });
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email(),
                            username: Yup.string()
                                .min(2, "This username is too short - it should be at least 2 characters")
                                .max(15, "This username is too long - it should be at most 15 characters")
                                .matches(/^[a-zA-Z0-9_-]{2,15}$/, "A username must not contain a special character"),    
                            password: Yup.string()
                                .min(8, "This password is too short - it should be at least 8 characters")
                                .max(32, "This password is too long - it should be at most 32 characters")
                                .matches(/(?=^.{8,32}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
                                            "Passwords must contain a number, upper case letter, lower case letter, and a special character"),
                            confirmPassword: Yup.string()
                                .oneOf([Yup.ref('password'), null], "The passwords you entered do not match")
                        })}>

                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props;

                            return (
                                <div className="editInfoForm">

                                    <h3 id="instructions">Fill out one or more of the fields below to update your information</h3>
                                    <div className={`dbError${this.state.dbError}`}>
                                        <div className="dbError-feedback">{this.state.dbErrorInfo}</div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div id="email">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder="New Email:"
                                                autoComplete="new-email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.email && touched.email && "error"}
                                            />
                                            {errors.email && touched.email && (
                                                <div className="input-feedback">{errors.email}</div>
                                            )}
                                        </div>

                                        <div className="username">
                                            <input
                                                id="username"
                                                name="username"
                                                type="text"
                                                placeholder="New Username:"
                                                autoComplete="new-username"
                                                value={values.username}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.username && touched.username && "error"}
                                            />
                                            {errors.username && touched.username && (
                                                <div className="input-feedback">{errors.username}</div>
                                            )}
                                        </div>

                                        <div className="password">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                placeholder="New Password:"
                                                autoComplete="new-password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.password && touched.password && "error"}
                                            />
                                            {errors.password && touched.password && (
                                                <div className="input-feedback">{errors.password}</div>
                                            )}

                                            <input
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                type="password"
                                                placeholder="Confirm New Password:"
                                                autoComplete="new-password"
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.confirmPassword && touched.confirmPassword && "error"}
                                            />
                                            {errors.confirmPassword && touched.confirmPassword && (
                                                <div className="input-feedback">{errors.confirmPassword}</div>
                                            )}
                                        </div>

                                        <div className="signupButton">
                                            <button type="submit" id="signup" disabled={isSubmitting}>
                                                <p>SUBMIT</p>
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        );
    }
}

export default withRouter(EditInfoPopup);