//imports
import React, { Component } from 'react';
import NFLIcon from '../../assets/nflIcon';
import NHLIcon from '../../assets/nhlIcon';
import MLBIcon from '../../assets/mlbIcon';
import NBAIcon from '../../assets/nbaIcon';
import NcaafSbIcon from "../../assets/ncaafSbIcon";
import SpecialIcon from '../../assets/specialIcon';
import ParlaySbIcon from '../../assets/parlaySbIcon';
import '../../index.scss';
import './liveBetSideBarItem.scss';

/**
 * An element to represent a live bet on the side bar
 * Will display the name of the bet
 */
class LiveBetSidebarItem extends Component {

	/**
	 * render method	 * @returns {JSX.Element|null}
	 */
	render() {
		//if the league of the bet is mlb
		if (this.props.league === 'MLB') {
			return (
				<div id="lb">
					<div id="liveBet"><MLBIcon className="icon" /><div className="name">{this.props.name}</div></div>
				</div>
			);
		}
		//if the league of the bet is nhl
		else if (this.props.league === 'NHL') {
			return (
				<div id="lb">
					<div id="liveBet"><NHLIcon className="icon" /><div className="name">{this.props.name}</div></div>
				</div>
			);
		}
		//if the league of the bet is nfl
		else if (this.props.league === 'NFL') {
			return (
				<div id="lb">
					<div id="liveBet"><NFLIcon className="icon" /><div className="name">{this.props.name}</div></div>
				</div>
			);
		}
		//if the league of the bet is nba
		else if (this.props.league === 'NBA') {
			return (
				<div id="lb">
					<div id="liveBet"><NBAIcon className="icon" /><div className="name">{this.props.name}</div></div>
				</div>
			);
		}
		//if the bet is a special
		else if (this.props.league === 'special') {
			return (
				<div id="lb">
					<div id="liveBet"><SpecialIcon className="icon" /><div className="name">{this.props.name}</div></div>
				</div>
			);
		}
		//if the bet is a parlay
		else if (this.props.league === 'parlay') {
			return (
				<div id="lb">
					<div id="liveBet"><ParlaySbIcon className="icon" /><div className="name">{this.props.name}</div></div>
				</div>
			);
		}
		//if the league of the bet is ncaaf
		else if (this.props.league === 'NCAAF') {
			return (
				<div id="lb">
					<div id="liveBet"><NcaafSbIcon className="icon" /><div className="name">{this.props.name}</div></div>
				</div>
			);
		}
		else {
			return null;
		}
	}
}

export default LiveBetSidebarItem;