//imports
import React, { Component } from 'react';
import Popup from './popup.js'
import './propBox.scss';
import {toBase64} from "js-base64";

/**
 * An element to represent a props odds box
 */
class PropBox extends Component {
	//constructor
	constructor(props) {
		super(props);

		let parlayId = toBase64(this.props.name + this.props.points + this.props.betType + this.props.odds);

		//creating the odds string
		this.oddsStr = this.props.odds > 0 ? `+${this.props.odds}` : `${this.props.odds}`;

		//if the prop is a special then set the teams to null
		if (this.props.teams === "special") {
			this.awayTeam = null;
			this.homeTeam = null;
		}
		else {
			this.awayTeam = this.props.teams.split(" vs ")[0];
			this.homeTeam = this.props.teams.split(" vs ")[1];
		}

		//toggling the popup
		this.togglePopup = this.togglePopup.bind(this);
		this.state = { showPopup: false, selectedForParlay: this.props.currentParlay.some(c => c.parlayBetId === parlayId) };
	}


	/**
	 * A method to toggle the popup
	 */
	togglePopup() {
		if (this.props.parlayMode) {
			this.setState({ selectedForParlay: !this.state.selectedForParlay, });
			const parlayInfo = ({
				parlayBetId: toBase64(this.props.name + this.props.points + this.props.betType + this.props.odds),
				betInfo: this.props
			});
			!this.state.selectedForParlay ?  this.props.addToParlay(parlayInfo) : this.props.removeFromParlay(parlayInfo);
		} else {
			this.setState({
				showPopup: !this.state.showPopup,
			});
			this.props.sendPopupState(!this.state.showPopup ? "Popup Enabled" : "Popup Disabled");
		}
	}

	/**
	 * render method
	 */
	render() {
		return (
			<div>
				<button {...this.state.selectedForParlay && this.props.parlayMode ? {className: "parlay-on"} : null} id="prop-button" onClick={this.togglePopup.bind(this)}>
					<p id="option-name">{this.props.option}</p>
					<p id="propOdds">{this.oddsStr}</p>
				</button>

				{this.state.showPopup ?
					<Popup
						closePopup={this.togglePopup.bind(this)}
						awayTeam={this.awayTeam}
						homeTeam={this.homeTeam}
						betType={7}
						odds={this.oddsStr}
						name={this.props.name}
						gameId={this.props.propId}
						points={this.props.points}
					/>
					:
					null
				}
			</div>
		);
	}
}

export default PropBox;