import React from 'react';

class DropDownArrow extends React.Component {
    render() {
        return (
            <svg width="33" height="21" viewBox="0 0 33 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.9506 20.1018C15.7509 21.0823 17.2491 21.0823 18.0494 20.1018L31.5887 3.51468C32.655 2.20842 31.7255 0.25 30.0393 0.25H2.96067C1.27449 0.25 0.345051 2.20843 1.41129 3.51469L14.9506 20.1018Z" fill="#3DF5D9" />
            </svg>
        )
    }
}

export default DropDownArrow;