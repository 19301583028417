//imports
import React, { Component } from 'react';
import '../../index.scss';
import './trends.scss';
import TrendUp from '../../assets/trendUp.js';
import TrendDown from '../../assets/trendDown.js';
import TrendFlat from '../../assets/trendFlat.js';

/**
 * An element to represent the users current trends
 * Will display all stats from the past 24 hours and past 7 days
 */
class Trends extends Component {

    /**
     * render method
     */

    render() {
        if (this.props.trends.dailyBetsPlaced || this.props.trends.weeklyBetsPlaced) {
            //daily data
            this.dailyBetsPlaced = this.props.trends.dailyBetsPlaced;
            this.dailyBetsWon = this.props.trends.dailyBetsWon;
            this.dailyBetsLost = this.props.trends.dailyBetsLost;
            this.dailyBetsTied = this.props.trends.dailyBetsTied;
            this.dailyBetsRecord = this.dailyBetsWon.toString() + "-" + this.dailyBetsLost.toString() + "-" + this.dailyBetsTied;
            this.dailyBetsFinished = this.dailyBetsWon + this.dailyBetsLost + this.dailyBetsTied;
            this.dailyWinPercentage = this.dailyBetsFinished > 0 ? (100 * this.dailyBetsWon / (this.dailyBetsFinished)).toFixed(1).toString() + "%" : "0.0%";
            this.dailyPointsWagered = this.props.trends.dailyPointsWagered.toFixed(2);
            this.dailyPointsWon = this.props.trends.dailyPointsWon.toFixed(2);
            this.dailyPointsLost = this.props.trends.dailyPointsLost.toFixed(2);
            this.dailyNetGain = (this.dailyPointsWon - this.dailyPointsLost).toFixed(2);
            this.dailyNetGainStr = this.dailyNetGain > 0 ? "+" + this.dailyNetGain.toString() : this.dailyNetGain.toString();
            this.dailyGain = this.dailyNetGain > 0;
            // eslint-disable-next-line
            if (this.dailyNetGain == 0) {
                this.dailyGain = "tie";
            }
            //weekly data
            this.weeklyBetsPlaced = this.props.trends.weeklyBetsPlaced;
            this.weeklyBetsWon = this.props.trends.weeklyBetsWon;
            this.weeklyBetsLost = this.props.trends.weeklyBetsLost;
            this.weeklyBetsTied = this.props.trends.weeklyBetsTied;
            this.weeklyBetsRecord = this.weeklyBetsWon.toString() + "-" + this.weeklyBetsLost.toString() + "-" + this.weeklyBetsTied;
            this.weeklyBetsFinished = this.weeklyBetsWon + this.weeklyBetsLost + this.weeklyBetsTied;
            this.weeklyWinPercentage = this.weeklyBetsFinished > 0 ? (100 * this.weeklyBetsWon / (this.weeklyBetsFinished)).toFixed(1).toString() + "%" : "0.0%";
            this.weeklyPointsWagered = this.props.trends.weeklyPointsWagered.toFixed(2);
            this.weeklyPointsWon = this.props.trends.weeklyPointsWon.toFixed(2);
            this.weeklyPointsLost = this.props.trends.weeklyPointsLost.toFixed(2);
            this.weeklyNetGain = (this.weeklyPointsWon - this.weeklyPointsLost).toFixed(2);
            this.weeklyNetGainStr = this.weeklyNetGain > 0 ? "+" + this.weeklyNetGain.toString() : this.weeklyNetGain.toString();
            this.weeklyGain = this.weeklyNetGain > 0;
            // eslint-disable-next-line
            if (this.weeklyNetGain == 0) {
                this.weeklyGain = "tie";
            }
        }
        return (
            <div className="trends">
                <div id="daily">
                    <h1 id="section-title">Past 24 Hours</h1>
                    {this.dailyBetsPlaced || this.dailyBetsFinished > 0 ? (
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Bets Placed</th>
                                        <td>{this.dailyBetsPlaced}</td>
                                        <th>Points Wagered</th>
                                        <td>{this.dailyPointsWagered}</td>
                                    </tr>
                                    <tr>
                                        <th>Record</th>
                                        <td>{this.dailyBetsRecord}</td>
                                        <th>Win %</th>
                                        <td>{this.dailyWinPercentage}</td>
                                    </tr>
                                    <tr>
                                        <th>Points Won</th>
                                        <td id="p-won">{this.dailyPointsWon}</td>
                                        <th>Points Lost</th>
                                        <td id="p-lost">{this.dailyPointsLost}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div id="net-trend-section">
                                <div className="gain-section">
                                    <h1 className="total-gain">Net Gain: </h1>
                                    <h1 className="total-gain" id={"net-gain-" + this.dailyGain}>{this.dailyNetGainStr}</h1>
                                </div>
                                <div id="trend-graph">
                                    {this.dailyNetGain > 0 ? (
                                        <TrendUp/>
                                        ) : null}
                                    {this.dailyNetGain < 0 ? (
                                        <TrendDown/>
                                        ) : null}
                                    {// eslint-disable-next-line
                                        this.dailyNetGain == 0 ? (
                                        <TrendFlat/>
                                        ) : null}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <h1 id="emptyState">You have not placed any bets in the past 24 hours!</h1>
                    )}
                </div>
                <div id="weekly">
                    <h1 id="section-title">Past 7 Days</h1>
                    {this.weeklyBetsPlaced || this.weeklyBetsFinished > 0 ? (
                        <div>
                            <table>
                                <tbody>
                                <tr>
                                    <th>Bets Placed</th>
                                    <td>{this.weeklyBetsPlaced}</td>
                                    <th>Points Wagered</th>
                                    <td>{this.weeklyPointsWagered}</td>
                                </tr>
                                <tr>
                                    <th>Record</th>
                                    <td>{this.weeklyBetsRecord}</td>
                                    <th>Win %</th>
                                    <td>{this.weeklyWinPercentage}</td>
                                </tr>
                                <tr>
                                    <th>Points Won</th>
                                    <td id="p-won">{this.weeklyPointsWon}</td>
                                    <th>Points Lost</th>
                                    <td id="p-lost">{this.weeklyPointsLost}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div id="net-trend-section">
                                <div className="gain-section">
                                    <h1 className="total-gain">Net Gain: </h1>
                                    <h1 className="total-gain" id={"net-gain-" + this.weeklyGain}>{this.weeklyNetGainStr}</h1>
                                </div>
                                <div id="trend-graph">
                                    {this.weeklyNetGain > 0 ? (
                                        <TrendUp/>
                                    ) : null}
                                    {this.weeklyNetGain < 0 ? (
                                        <TrendDown/>
                                    ) : null}
                                    {// eslint-disable-next-line
                                        this.weeklyNetGain === 0 ? (
                                        <TrendFlat/>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <h1 id="emptyState">You have not placed any bets in the past 7 Days!</h1>
                    )}

                </div>
            </div>
        );
    }
}

export default Trends;