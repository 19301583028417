import React from 'react'; 

class SideBarIcon extends React.Component {
    render() {
        return (
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 32C0 31.4696 0.255867 30.9609 0.711312 30.5858C1.16676 30.2107 1.78447 30 2.42857 30H31.5714C32.2155 30 32.8332 30.2107 33.2887 30.5858C33.7441 30.9609 34 31.4696 34 32C34 32.5304 33.7441 33.0391 33.2887 33.4142C32.8332 33.7893 32.2155 34 31.5714 34H2.42857C1.78447 34 1.16676 33.7893 0.711312 33.4142C0.255867 33.0391 0 32.5304 0 32Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0 2C0 1.46957 0.255867 0.96086 0.711312 0.585787C1.16676 0.210714 1.78447 0 2.42857 0H31.5714C32.2155 0 32.8332 0.210714 33.2887 0.585787C33.7441 0.96086 34 1.46957 34 2C34 2.53043 33.7441 3.03914 33.2887 3.41421C32.8332 3.78929 32.2155 4 31.5714 4H2.42857C1.78447 4 1.16676 3.78929 0.711312 3.41421C0.255867 3.03914 0 2.53043 0 2Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M0 17C0 16.4696 0.255867 15.9609 0.711312 15.5858C1.16676 15.2107 1.78447 15 2.42857 15H31.5714C32.2155 15 32.8332 15.2107 33.2887 15.5858C33.7441 15.9609 34 16.4696 34 17C34 17.5304 33.7441 18.0391 33.2887 18.4142C32.8332 18.7893 32.2155 19 31.5714 19H2.42857C1.78447 19 1.16676 18.7893 0.711312 18.4142C0.255867 18.0391 0 17.5304 0 17Z" fill="white"/>
            </svg>
        )
    }
}

export default SideBarIcon;