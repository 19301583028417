//imports
import React, { Component } from 'react';
import './pastBet.scss'

/**
 * An element to represent a users past bet
 * Will display all data about the bet like bet name, time placed, wager, points won, and points lost when applicable
 */
class PastBet extends Component {
	//constructor
	constructor(props) {
		super(props);

		//creating the time placed date
		this.dateInfo = this.props.date.split(" ");
		this.dayInfo = this.dateInfo[0].split("-");
		this.timeInfo = this.dateInfo[1].split(":");

		this.timestamp = new Date(this.dayInfo[0], this.dayInfo[1] - 1, this.dayInfo[2], this.timeInfo[0], this.timeInfo[1], this.timeInfo[2]);

		this.timePlaced = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' }).format(this.timestamp);
	}

	/**
	 * render method
	 */
	render() {

		if (this.props.didWin === 1) {
			return (
				<table>
					<tbody>
						<tr><td id="name">{this.props.name}</td><td id="pbInfo">{this.timePlaced}</td><td id="pbInfo" className="win">{this.props.toWin}</td><td id="pbInfo" className="win">{this.props.payout}</td><td id="pbInfo" className="lost">{null}</td></tr>
					</tbody>
				</table>
			)
		}
		else if (this.props.didWin === 0) {
			return (
				<table>
					<tbody>
						<tr><td id="name">{this.props.name}</td><td id="pbInfo">{this.timePlaced}</td><td id="pbInfo" className="win">{null}</td><td id="pbInfo" className="win">{null}</td><td id="pbInfo" className="lost">{this.props.wager}</td></tr>
					</tbody>
				</table>
			)
		}
		else if (this.props.didWin === -1) {
			return (
				<table>
					<tbody>
						<tr><td id="name">{this.props.name}</td><td id="pbInfo">{this.timePlaced}</td><td id="pbInfo" className="win">{null}</td><td id="pbInfo" className="tie">{this.props.wager}</td><td id="pbInfo" className="lost">{null}</td></tr>
					</tbody>
				</table>
			)
		}
	}
}

export default PastBet;