//imports
import React, { Component } from 'react';
import './altLine.scss';
import AltSpreadBox from "./altSpreadBox";
import Algos from "../../helpers/algos.js";

/**
 * An element to represent a money line box in the game element
 * Will display the odds of the money line bet for that team
 */
class AltSpread extends Component {

    constructor(props) {
        super(props);

        // console.log(this.props);

        this.state = {
            value: parseFloat(this.props.homeSpread.toFixed(1)),
            homeOdds: this.props.homeOdds,
            awayOdds: this.props.awayOdds,
        }

        this.sendPopupState = this.sendPopupState.bind(this);
        this.addBetToParlay = this.addBetToParlay.bind(this);
        this.removeBetFromParlay = this.removeBetFromParlay.bind(this);
    }

    sendPopupState = (val) => {
        this.props.sendPopupState(val);
    }

    addBetToParlay = (bet) => {
        this.props.addToParlay(bet);
    }

    removeBetFromParlay = (bet) => {
        this.props.removeFromParlay(bet);
    }

    handleChange = (event) => {

        let val = -1 * event.target.value;
        let home = this.state.homeOdds;
        let away = this.state.awayOdds;

        //update the point values
        if (val >= 0 && val < 1) {
            val = 1;
        }
        if (val <= 0 && val > -1) {
            val = -1;
        }

        let change = this.props.homeSpread - val;

        //update the odds value
        //NHL
        if (this.props.league === "NHL") {
            //HOME POSITIVE ODDS
            if (this.props.homeSpread < 0) {
                //NEGATIVE CHANGE IN SPREAD
                if (change >= 0) {
                    home = Algos.NHL_POS_CHANGE_POS_SO(this.props.homeOdds, change);
                    away = Algos.NHL_NEG_CHANGE_NEG_SO(this.props.awayOdds, -change);
                }
                //POSITIVE CHANGE IN SPREAD
                else {
                    home = Algos.NHL_NEG_CHANGE_POS_SO(this.props.homeOdds, change);
                    away = Algos.NHL_POS_CHANGE_NEG_SO(this.props.awayOdds, -change);
                }
            }
            else {
                //NEGATIVE CHANGE IN SPREAD
                if (change >= 0) {
                    home = Algos.NHL_POS_CHANGE_NEG_SO(this.props.homeOdds, change);
                    away = Algos.NHL_NEG_CHANGE_POS_SO(this.props.awayOdds, -change);
                }
                //POSITIVE CHANGE IN SPREAD
                else {
                    home = Algos.NHL_NEG_CHANGE_NEG_SO(this.props.homeOdds, change);
                    away = Algos.NHL_POS_CHANGE_POS_SO(this.props.awayOdds, -change);
                }
            }

            home = Algos.NHL_CLEAN_SPREAD(home, val, this.props.homeSpread, this.props.homeOdds);
            away = Algos.NHL_CLEAN_SPREAD(away, -val, this.props.awaySpread, this.props.awayOdds);
        }

        //NBA
        else if (this.props.league === "NBA") {
            //HOME FAVORITE
            if (this.props.homeSpread < 0) {
                //NEGATIVE CHANGE IN SPREAD
                if (change >= 0) {
                    home = Algos.NBA_POS_CHANGE_FAV(this.props.homeOdds, change);
                    away = Algos.NBA_NEG_CHANGE_DOG(this.props.awayOdds, -change);
                }
                //POSITIVE CHANGE IN SPREAD
                else {
                    home = Algos.NBA_NEG_CHANGE_FAV(this.props.homeOdds, change);
                    away = Algos.NBA_POS_CHANGE_DOG(this.props.awayOdds, -change);
                }
            }
            else {
                //NEGATIVE CHANGE IN SPREAD
                if (change >= 0) {
                    home = Algos.NBA_POS_CHANGE_DOG(this.props.homeOdds, change);
                    away = Algos.NBA_NEG_CHANGE_FAV(this.props.awayOdds, -change);
                }
                //POSITIVE CHANGE IN SPREAD
                else {
                    home = Algos.NBA_NEG_CHANGE_DOG(this.props.homeOdds, change);
                    away = Algos.NBA_POS_CHANGE_FAV(this.props.awayOdds, -change);
                }
            }

            home = Algos.NBA_CLEAN_SPREAD(home, val, this.props.homeSpread, this.props.homeOdds);
            away = Algos.NBA_CLEAN_SPREAD(away, val, this.props.homeSpread, this.props.awayOdds);
        }


        this.setState({value: val, homeOdds: home.toFixed(0), awayOdds: away.toFixed(0)});
    }

    /**
     * render method
     */
    render() {
        return (
            <div id="alt-line">
                <h2 id="alt-line-header">Alternate Spread</h2>
                <div id="slider">
                    <input type="range" step={.5} min={this.props.min} max={this.props.max} value={-this.state.value} onChange={this.handleChange}/>
                </div>
                {/*<div id="alt-spread-boxes">*/}
                    <table id="alt-spread-boxes">
                        <tbody>
                            <tr>
                                <td id="alt-data">
                                    <AltSpreadBox currentParlay={this.props.currentParlay} parlayMode={this.props.parlayMode} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} sendPopupState={this.sendPopupState} team={this.props.away} awayTeam={this.props.awayFull} homeTeam={this.props.homeFull} points={(-1) * this.state.value} odds={this.state.awayOdds} gameId={this.props.gameId} betType={3} />
                                </td>
                                <td id="alt-data">
                                    <AltSpreadBox currentParlay={this.props.currentParlay} parlayMode={this.props.parlayMode} addToParlay={this.addBetToParlay} removeFromParlay={this.removeBetFromParlay} sendPopupState={this.sendPopupState} team={this.props.home} awayTeam={this.props.awayFull} homeTeam={this.props.homeFull} points={this.state.value} odds={this.state.homeOdds} gameId={this.props.gameId} betType={4}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                {/*</div>*/}

            </div>
        );
    }
}

export default AltSpread;