import React from 'react'; 

class NFLSPIcon extends React.Component {
    render() {
        return (
            <svg width="132" height="91" viewBox="0 0 132 91" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M91.1291 5.23473L90.8773 4.76953L90.4122 4.51777C84.863 1.51454 77.9682 0.684971 70.9979 2.18186C63.9074 3.70474 57.2509 7.41816 51.7484 12.9208C46.246 18.4234 42.5324 25.0797 41.0095 32.1703C39.513 39.1403 40.3424 46.0351 43.3456 51.5846L43.5973 52.0498L44.0625 52.3015C47.8075 54.3282 52.164 55.3649 56.7442 55.3649C59.0077 55.3605 61.2643 55.1166 63.4765 54.6373C70.567 53.1145 77.2234 49.401 82.7259 43.8984C88.2284 38.3958 91.9427 31.7393 93.465 24.6487C94.962 17.6785 94.1324 10.7834 91.1291 5.23473ZM88.2506 7.39628C90.4472 11.7116 91.1202 16.7034 90.405 21.7805L73.8663 5.24184C78.9436 4.52665 83.9357 5.19964 88.2506 7.39628ZM46.2243 49.4227C43.9808 45.0153 43.3267 39.9024 44.1177 34.7133L60.9336 51.529C55.7446 52.3203 50.6316 51.6661 46.2243 49.4227ZM80.2133 41.3853C75.7229 45.8756 70.4281 49.0023 65.0366 50.6065L45.0404 30.6104C46.6446 25.2187 49.7712 19.9239 54.2616 15.4336C58.8321 10.863 64.2362 7.70612 69.7274 6.12871L89.5183 25.92C87.9408 31.4108 84.7839 36.8144 80.2133 41.3853Z"
                    fill="white"/>
                <path
                    d="M91.1291 5.23473L90.8773 4.76953L90.4122 4.51777C84.863 1.51454 77.9682 0.684971 70.9979 2.18186C63.9074 3.70474 57.2509 7.41816 51.7484 12.9208C46.246 18.4234 42.5324 25.0797 41.0095 32.1703C39.513 39.1403 40.3424 46.0351 43.3456 51.5846L43.5973 52.0498L44.0625 52.3015C47.8075 54.3282 52.164 55.3649 56.7442 55.3649C59.0077 55.3605 61.2643 55.1166 63.4765 54.6373C70.567 53.1145 77.2234 49.401 82.7259 43.8984C88.2284 38.3958 91.9427 31.7393 93.465 24.6487C94.962 17.6785 94.1324 10.7834 91.1291 5.23473ZM88.2506 7.39628C90.4472 11.7116 91.1202 16.7034 90.405 21.7805L73.8663 5.24184C78.9436 4.52665 83.9357 5.19964 88.2506 7.39628ZM46.2243 49.4227C43.9808 45.0153 43.3267 39.9024 44.1177 34.7133L60.9336 51.529C55.7446 52.3203 50.6316 51.6661 46.2243 49.4227ZM80.2133 41.3853C75.7229 45.8756 70.4281 49.0023 65.0366 50.6065L45.0404 30.6104C46.6446 25.2187 49.7712 19.9239 54.2616 15.4336C58.8321 10.863 64.2362 7.70612 69.7274 6.12871L89.5183 25.92C87.9408 31.4108 84.7839 36.8144 80.2133 41.3853Z"
                    fill="white"/>
                <path
                    d="M77.1407 20.8018L74.628 18.2891L72.3306 20.5863L69.1448 17.4006L66.6321 19.9133L69.8178 23.0991L67 25.9169L63.8142 22.7312L61.3015 25.2439L64.4872 28.4297L61.6694 31.2475L58.4836 28.0618L55.9709 30.5745L59.1566 33.7603L56.8594 36.0577L59.3721 38.5704L61.6694 36.2731L64.8552 39.4588L67.3679 36.9461L64.1823 33.7603L67 30.9425L70.1858 34.1282L72.6985 31.6155L69.5128 28.4297L72.3306 25.6119L75.5164 28.7976L78.0291 26.2849L74.8434 23.0991L77.1407 20.8018Z"
                    fill="white"/>
                <path
                    d="M61.1386 84.8596V68.8516H57.7306V78.9076L50.3146 68.8516H46.8106V84.8596H50.2186V74.4196L57.8506 84.8596H61.1386ZM67.7029 84.8596V78.2116H75.4549V75.2116H67.7029V71.8516H75.6229V68.8516H64.2949V84.8596H67.7029ZM88.0619 84.8596V81.8596H81.2939V68.8516H77.8619V84.8596H88.0619Z"
                    fill="white"/>
            </svg>
        )
    }
}

export default NFLSPIcon;