//imports
import React, { Component } from 'react';
import './home.scss';
import { withRouter } from 'react-router-dom';
import HomeContent from "./homeContent";
import WelcomeBanner from "./welcomeBanner";

/**
 * A component representing the content of the home page when no user is logged in
 * Users will land here when they navigate to the root and are not logged in
 * Will redirect them to signup/login when they click
 */
class NotLoggedIn extends Component {

	/**
	 * A function to handle when the user clicks anywhere on the page
	 * Will send them to the login page when clicked
	 */
    frontingClicked = () => {
        this.props.history.push('/login');
    }

	/**
	 * return method
	 */
	render() {
		return (
			<div>
				<WelcomeBanner/>
				<div id="fronting" onClick={this.frontingClicked}>
					<HomeContent currentParlay={[]}/>
				</div>
			</div>
		);
    }
}

export default withRouter(NotLoggedIn);