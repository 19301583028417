//imports
import React, { Component } from 'react';
import SignupForm from '../../elements/signup/signupForm.js'

/**
 * A class that will be the signup form in the web app
 * Here users can create their account by entering the necessary info
 */
class Signup extends Component {

  /**
   * render method
   */
  render() {
    return (
      <div className="signup">
        <SignupForm/>
      </div>
    );
  }
}
 
export default Signup;