import React from 'react'; 

class NHLIcon extends React.Component {
    render() {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.380981 12.4445V15.1111H1.90479V11.5556H1.14289C0.723839 11.5556 0.380981 11.9556 0.380981 12.4445ZM5.71432 11.5556H2.6667V15.1111L6.24003 15.1022C6.52955 15.1022 6.7886 14.9156 6.91812 14.6133L7.58098 12.9245L6.36955 9.83112L5.71432 11.5556ZM15.3981 11.8133C15.327 11.7308 15.2426 11.6656 15.1497 11.6213C15.0569 11.5771 14.9575 11.5547 14.8572 11.5556H14.0953V15.1111H15.6191V12.4445C15.6191 12.1956 15.5353 11.9733 15.3981 11.8133ZM9.21908 8.74668L12.3048 0.888901H9.75241L8.41146 4.41779L8.03813 5.39557L8.00003 5.52001L6.24765 0.888901H3.69527L6.78098 8.74668L7.93908 11.6978L8.00003 11.8578L9.08193 14.6133C9.21146 14.9156 9.47051 15.1022 9.76003 15.1022L13.3334 15.1111V11.5556H10.2857L9.21908 8.74668V8.74668Z" fill="#3DF5D9"/>
            </svg> 
        )
    }
}

export default NHLIcon;