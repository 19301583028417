//imports
import React, { Component } from 'react';
import '../../index.scss';
import './pastBets.scss';
import PastBet from './pastBet.js';

/**
 * An element to represent the users past bets.
 * Will show data about the users past 25 bets from the format defined in PastBet
 */
class PastBets extends Component {

	/**
	 * render method
	 */
	render() {

		//for the key
		let i = 0;

		return (
			<div className="pastBets">
				<h1 id="title">My Past 25 Bets</h1>
				<div id="pastBetsTable">
					<table>
						<thead>
							<tr><th id="name">Bet Name</th><th id="pbInfo">Date Placed</th><th id="pbInfo">Points Won</th><th id="pbInfo">Payout</th><th id="pbInfo">Points Lost</th></tr>
						</thead>
					</table>
					<div id="pbs">
						{this.props.pastBets ? (
							this.props.pastBets.map(pastBet => (
								<PastBet
									key={i++}
									name={pastBet.betName}
									date={pastBet.datePlaced}
									odds={pastBet.odds}
									wager={pastBet.wager}
									payout={pastBet.payout}
									toWin={pastBet.toWin}
									didWin={pastBet.didWin}
								/>
							)
							)
						) : (
								<div><h1 id="emptyState">Looks Like You Don't Have Any Past Bets Yet!</h1></div>
							)}
					</div>
				</div>
			</div>
		);
	}
}

export default PastBets;