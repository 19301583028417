//imports
import React, { Component } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import './resetPwScreen.scss';
import axios from 'axios';
import { withRouter } from 'react-router-dom'
import { Base64 } from 'js-base64';
import ReactGA from "react-ga";

/**
 * An element to represent the screen where users can reset their password
 */
class ResetPWScreen extends Component {
	//constructor
	constructor(props) {
		super(props);

		//getting the user email from data passed in
		this.userEmail = this.props.location.data;

		//base states
		this.state = {
			dbError: false,
			dbErrorInfo: "",
		}
	}

	/**
	 * render method
	 */
	render() {
		return (
			<Formik
				initialValues={{ password: "", confirmPassword: "", email: this.userEmail }}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						setSubmitting(false);
					}, 500);
					const tempPW = values.password;
					const tempCPW = values.confirmPassword;
					values.password = Base64.encode(values.password);
					values.confirmPassword = Base64.encode(values.confirmPassword);
					if (values.email === undefined) {
						this.props.history.push("/login");
					}
					else {
						const apiKey = process.env.REACT_APP_APIK;

						axios.post(process.env.REACT_APP_APIL + "resetPW", { apiKey, values })
							.then(res => {
								// console.log(res);
								// console.log(res.data);
								if (res.data === 1) {

									//create ga event
									ReactGA.event({
										category: 'Forgot Password',
										action: 'Successful password reset',
									});

									this.props.history.push("/login");
								}
							})
							.catch(error => {
								console.log(error);
								this.setState({ dbError: true, dbErrorInfo: "Something went wrong! Please try again" });
								values.password = tempPW;
								values.confirmPassword = tempCPW;
							});
					}
				}}
				validationSchema={Yup.object().shape({
					password: Yup.string()
						.required("Please enter a password")
						.min(8, "This password is too short - it should be at least 8 characters")
						.max(32, "This username is too long - it should be at most 32 characters")
						.matches(/(?=^.{8,32}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/,
							"Passwords must contain a number, upper case letter, lower case letter, and a special character"),
					confirmPassword: Yup.string()
						.oneOf([Yup.ref('password'), null], "The passwords you entered do not match")
						.required('Please confirm your password'),
				})}>

				{props => {
					const {
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit
					} = props;

					return (
						<div className="pwForm">
							<h1>Reset Your Password</h1>
							<div className={`dbError${this.state.dbError}`}>
								<div className="dbError-feedback">{this.state.dbErrorInfo}</div>
							</div>
							<form onSubmit={handleSubmit}>
							<input type="hidden" name="username" value={this.userEmail} />
							<div className="password">
									<input
										id="password"
										name="password"
										type="password"
										placeholder="Password:"
										autoComplete='new-password'
										value={values.password}
										onChange={handleChange}
										onBlur={handleBlur}
										className={errors.password && touched.password && "error"}
									/>
									{errors.password && touched.password && (
										<div className="input-feedback">{errors.password}</div>
									)}

									<input
										id="confirmPassword"
										name="confirmPassword"
										type="password"
										placeholder="Confirm Password:"
										autoComplete='new-password'
										value={values.confirmPassword}
										onChange={handleChange}
										onBlur={handleBlur}
										className={errors.confirmPassword && touched.confirmPassword && "error"}
									/>
									{errors.confirmPassword && touched.confirmPassword && (
										<div className="input-feedback">{errors.confirmPassword}</div>
									)}
								</div>

								<div className="loginButton">
									<button type="submit" id="login" disabled={isSubmitting}>
										<p>SUBMIT</p>
									</button>
								</div>
							</form>
						</div>
					);
				}}
			</Formik>
		);
	}
}

export default withRouter(ResetPWScreen);