import React from 'react'; 

class NFLIcon extends React.Component {
    render() {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0908 1.12197L15.0161 0.983905L14.878 0.909186C13.2311 0.0178656 11.1848 -0.228341 9.11609 0.215918C7.01169 0.66789 5.03612 1.76999 3.40305 3.40309C1.76997 5.03619 0.667834 7.01171 0.21586 9.11611C-0.228302 11.1847 0.0178724 13.231 0.909164 14.878L0.983883 15.0161L1.12195 15.0908C2.23341 15.6923 3.52638 16 4.88573 16C5.55751 15.9987 6.22726 15.9263 6.8838 15.7841C8.9882 15.3321 10.9637 14.23 12.5968 12.5969C14.2299 10.9638 15.3323 8.98819 15.7841 6.8838C16.2284 4.81514 15.9821 2.76875 15.0908 1.12197ZM14.2365 1.76349C14.8884 3.04423 15.0882 4.52572 14.8759 6.03256L9.9674 1.12408C11.4743 0.911823 12.9559 1.11156 14.2365 1.76349ZM1.76354 14.2364C1.09769 12.9284 0.903561 11.4109 1.13833 9.87084L6.1291 14.8616C4.58906 15.0964 3.07157 14.9022 1.76354 14.2364ZM11.8511 11.851C10.5184 13.1837 8.94697 14.1117 7.34682 14.5878L1.41216 8.65316C1.88829 7.05298 2.81623 5.48154 4.14892 4.14886C5.5054 2.79239 7.10928 1.85545 8.73899 1.38729L14.6127 7.26112C14.1446 8.89069 13.2076 10.4944 11.8511 11.851Z" fill="#3DF5D9"/>
                <path d="M10.7583 5.92514L10.0749 5.24166L9.44996 5.86653L8.5834 5L7.89992 5.68348L8.76646 6.55004L8 7.31649L7.13344 6.44996L6.44996 7.13344L7.31649 8L6.55004 8.76646L5.68348 7.89992L5 8.5834L5.86653 9.44996L5.24166 10.0749L5.92514 10.7583L6.55004 10.1335L7.4166 11L8.10008 10.3165L7.23354 9.44996L8 8.68351L8.86656 9.55004L9.55004 8.86656L8.68351 8L9.44996 7.23354L10.3165 8.10008L11 7.4166L10.1335 6.55004L10.7583 5.92514Z" fill="#3DF5D9"/>
            </svg>
        )
    }
}

export default NFLIcon;