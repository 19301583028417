//imports
import React, { Component } from 'react';
import '../../index.scss';
import './noProps.scss'

/**
 * An element to handle the empty state of no props in a game
 */
class NoProp extends Component {

    /**
     * render method
     */
    render() {
        return (
            <div className="noProp">
                <div className="info">
                    <h2 id="message">There Are No Prop Bets For This Game As Of Right Now</h2>
                </div>
            </div>
        );
    }
}

export default NoProp;