import React from 'react'; 

class CloseIcon extends React.Component {
    render() {
        return (
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.9931 17.0093L33.5675 2.43493C34.13 1.89167 34.1456 0.995302 33.6024 0.432792C33.0591 -0.129719 32.1628 -0.145316 31.6002 0.397946C31.5885 0.409312 31.5768 0.420928 31.5654 0.432792L16.9909 15.0072L2.4165 0.432709C1.85399 -0.110554 0.95762 -0.094956 0.414358 0.467555C-0.115629 1.01629 -0.115629 1.88619 0.414358 2.43493L14.9888 17.0093L0.414358 31.5838C-0.138445 32.1367 -0.138445 33.033 0.414358 33.5859C0.967245 34.1386 1.86361 34.1386 2.4165 33.5859L16.9909 19.0115L31.5653 33.5859C32.1278 34.1292 33.0242 34.1136 33.5675 33.5511C34.0974 33.0023 34.0974 32.1324 33.5675 31.5838L18.9931 17.0093Z" fill="white"/>
            </svg>
        )
    }
}

export default CloseIcon;