/**
 * A class that will hold all the constants used in the project
 */
class Config {
    //the time difference (ET <-> GMT)
    TIME_DIFF = 4;
    
    nameBet = (betType, awayTeam, homeTeam, points, propName) => {
        //naming the bet based off the bet type
        if (betType === 1) {
            return `${awayTeam} Money Line`;
        } else if (betType === 2) {
            return `${homeTeam} Money Line`;
        } else if (betType === 3) {
            return `${awayTeam} ${parseFloat(points).toFixed(1)}`;
        } else if (betType === 4) {
            return `${homeTeam} ${parseFloat(points).toFixed(1)}`;
        } else if (betType === 5) {
            return `${awayTeam} @ ${homeTeam} Over ${parseFloat(points).toFixed(1)}`;
        } else if (betType === 6) {
            return `${awayTeam} @ ${homeTeam} Under ${parseFloat(points).toFixed(1)}`;
        } else if (betType === 7) {
            return propName;
        } else if(betType === 8) {
            return `${awayTeam} @ ${homeTeam} Draw`
        }
    }

    americanToDecimal = (odds) => {
        return odds > 0 ? (Math.abs(odds) + 100) / 100 : (Math.abs(odds) + 100) / Math.abs(odds);
    }

    decimalToAmerican = (odds) => {
        return odds >= 2 ? (odds - 1) * 100 : -100 / (odds - 1);
    }
}

export default new Config();