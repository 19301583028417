//imports
import React, {Component} from 'react';
import '../../index.scss';
import './tosPage.scss';
import TOS from '../../elements/tos/tos.js';

/**
 * An element to represent the container of the tos and privacy document data.
 */
class TosPage extends Component {

    /**
     * render method
     */
    render() {
        return (
            <div id="overall-container">
                <h1 id="tos-title-bar">Terms of Service & Privacy Policy</h1>
                <div id="tos-container">
                    <TOS/>
                </div>
            </div>
        );
    }
}

export default TosPage;