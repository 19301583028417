//imports
import React from 'react';  
import './popup.scss';
import CloseIcon from '../../assets/closeIcon';
import PointsLogo from '../../assets/pointslogo';
import PointsLogoTeal from '../../assets/pointsLogoTeal';
import { withRouter } from 'react-router-dom';
import UserProfile from '../../helpers/userProfile.js';
import axios from 'axios';
import ReactGA from 'react-ga';
import Loader from "react-loader-spinner";
import Config from '../../helpers/config';

/**
 * An element that represents the station where users can place bets
 * Allows for the functionality to see how much you would win and get paid out if you win
 * Will come up every time an odds box gets clicked
 */
class Popup extends React.Component { 
    //constructor
    constructor(props) {
        super(props);

        //defining bet
        this.bet = [];

        //defining the original odds, wager, to win, and payout
        this.odds = this.props.odds;
        this.wager = 100.00;
        if (this.odds > 0) {
            this.oddsStr = this.odds;
            this.toWin = this.odds * .01 * this.wager;
        }
        else {
            this.oddsStr = this.odds;
            this.toWin = (100 * this.wager) / (-1 * this.odds);
        }
        this.payout = this.wager + this.toWin;

        //base states
        this.state = {
            wager: this.wager,
            toWin: this.toWin,
            payout: this.payout,
            errorOn: !!this.props.errorMessage || false,
            errorMsg: this.props.errorMessage || "",
            isLoading: false,
        };

        this.betName = this.props.betType === 9 ? this.props.parlayName : Config.nameBet(this.props.betType, this.props.awayTeam, this.props.homeTeam, this.props.points, this.props.name);
    }

    /**
     * A method that will dynamically update the values of wager, to win, and payout upon a user typing in a wager
     * @param {*} event the user inputting their wager
     */
    updateVals = (event) => {
        //get the value of the new wager
        let newWager = `${event.currentTarget.value}`;
        //if the new wager is empty string (user cleared what they inputted), set the values back to the original ones
        if (newWager === "") {
            this.setState({
                wager: 100,
                toWin: this.toWin,
                payout: this.payout
            });
        }
        else {
            //positive odds
            if (this.odds > 0) {
                this.setState({
                    wager: parseFloat(newWager),
                    toWin: parseFloat(this.odds) * .01 * parseFloat(newWager),
                    payout: (parseFloat(this.odds) * .01 * parseFloat(newWager)) + parseFloat(newWager),
                });
            }
            //negative odds
            else {
                this.setState({
                    wager: parseFloat(newWager),
                    toWin: (100 * parseFloat(newWager)) / (-1 * parseFloat(this.odds)),
                    payout: parseFloat(newWager) + ((100 * parseFloat(newWager)) / (-1 * parseFloat(this.odds)))
                });
            }
        }
    }    

    /**
     * A method to ensure that the values the user enters are valid
     * values must be greater than 0 and less than their bank
     */
    checkVals = () => {
        //if the wager is less than 0
        if (this.state.wager < 0.01) {
            this.setState({
                errorOn: true,
                errorMsg: "Your wager needs to be greater than 0",
            });
            return false;
        }
        //if the wager is greater than their bank
        else if (this.state.wager > UserProfile.getBank()) {
            this.setState({
                errorOn: true,
                errorMsg: "Your wager needs to be less than your bank",
            });
            return false;
        }
        //if the wager is valid
        else {
            this.setState({
                errorOn: false,
                errorMsg: "",
            });
            return true;
        }
    }

    /**
     * A method that handles the submission of a new bet.
     * Will send the values of the bet to the backend for it to handle it.
     */
    handleSubmit = () => {

        this.setState({ isLoading: true});

        //set the timeout value to .5 seconds
        setTimeout(() => {}, 500);

        //create ga event
        ReactGA.event({
            category: 'Bets',
            action: 'Bet placed',
        });

        const apiKey = process.env.REACT_APP_APIK;

        //make sure wager value is valid
        if(this.checkVals()) {
            if (this.props.betType === 9) {
                //define this parlay as an object
                const thisParlay = {
                    "parlayBetType": 9,
                    "legs": this.props.parlay,
                    "parlayName": this.props.parlayName,
                    "parlayOdds": parseInt(this.props.odds),
                    "wager": this.state.wager,
                    "userEmail": UserProfile.getEmail()
                }

                //send to backend
                axios.post(process.env.REACT_APP_APIL + "parlays", {apiKey, thisParlay})
                    .then(res => {
                        // console.log(res);
                        // console.log(res.data);
                        //upon a successful bet place
                        if (res.data.status === 1) { //necessary for safari
                            //update the user's stats and reload the window
                            UserProfile.updateStats();
                        }
                        sessionStorage.setItem("scrollPosition", window.pageYOffset);
                        window.location.reload();
                        return false;
                    })
                    .catch(error => {
                        console.log(error);
                        console.log(error.response.data);
                        this.setState({isLoading: false});
                    });
            }
            else {
                //define this bet as an object
                const thisBet = {
                    "betType": this.props.betType,
                    "gameId": this.props.gameId,
                    "odds": parseInt(this.props.odds),
                    "points": parseFloat(this.props.points),
                    "betName": this.betName,
                    "wager": this.state.wager,
                    "userEmail": UserProfile.getEmail()
                }

                //send to backend
                axios.post(process.env.REACT_APP_APIL + "newBet", {apiKey, thisBet})
                    .then(res => {
                        // console.log(res);
                        // console.log(res.data);
                        // console.log(thisBet);
                        //upon a successful bet place
                        if (res.data.status === 1) { //necessary for safari
                            //update the user's stats and reload the window
                            UserProfile.updateStats();
                        }
                        sessionStorage.setItem("scrollPosition", window.pageYOffset);
                        window.location.reload();
                        return false;
                    })
                    .catch(error => {
                        console.log(error);
                        console.log(error.response);
                        this.setState({isLoading: false});
                    });
            }
        }
        //if the wager is invalid just return false
        else {
            this.setState({ isLoading: false});
            return false;
        }
    }

    /**
	 * render function
	 */
    render() {
        //Used for the top of the popup. Will either be the vs in the game, parlay in a parlay bet, or just say "Special" if it is a special
        let mid = this.props.awayTeam && this.props.homeTeam ? "vs" : this.props.betType === 9 ? "Parlay" : "Special";

        return (  
            <div className={this.props.betType !== 9 ? 'popup' : 'parlayPopup'}>
                <div className='popupInner'>  
                    <div className="game">
                        <h1 id="teams">{this.props.awayTeam} {mid} {this.props.homeTeam}</h1><button id="close" onClick={this.props.closePopup}><CloseIcon/></button>
                    </div>
                    <div className="body">
                        {this.state.isLoading ? (
                            <div id="loading-container">
                                <Loader type="Oval" color="#3DF5D9" height={window.innerWidth > 600 ? 100 : 60} width={window.innerWidth > 600 ? 100 : 60} />
                            </div>
                        ) : null}
                        <div className={`error${this.state.errorOn}`}>
                            <div className="error-feedback">{this.state.errorMsg}</div>
                        </div>
                        <div className="betNameAndOdds">
                            <p id="betName">{this.betName}</p>
                            <p id="odds">{this.oddsStr}</p>
                        </div>
                        <div className="wagerAndPayouts">
                            <div className="wager">
                                <input  
                                    id="wagerAmt"
                                    min={1}
                                    max={UserProfile.getBank()}
                                    required="required"
                                    onChange={this.updateVals}
                                    type="number"
                                    placeholder={this.wager.toFixed(2)}
                                />
                                <PointsLogo/>
                            </div>
                            <table id="desk">
                                <thead>
                                    <tr>
                                        <th id="th">To Win</th>
                                        <th id="th">Payout</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td id="toWin">{this.state.toWin.toFixed(2)} <PointsLogo/></td>
                                        <td id="payout">{this.state.payout.toFixed(2)} <PointsLogoTeal/></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table id="mobile">
                                <tbody>
                                    <tr>
                                        <th id="th">To Win</th>
                                        <td id="toWin">{this.state.toWin.toFixed(2)} <PointsLogo/></td>
                                    </tr>
                                    <tr>
                                        <th id="th">Payout</th>
                                        <td id="payout">{this.state.payout.toFixed(2)} <PointsLogoTeal/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="placeButton">
                            <button onClick={() => this.handleSubmit()} id="place" disabled={this.state.isLoading || this.props.errorMessage}>Place Bet</button>
                        </div>
                    </div>
                </div>  
            </div>  
        );  
    }  
}  

export default withRouter(Popup);