//imports
import React, { Component } from 'react';
import '../../index.scss';
import './liveBets.scss';
import LiveBet from './liveBet.js'

/**
 * An element to show all the users live bets
 * Will show all data about all of the users live bets in the LiveBet element form
 */
class UpcomingBets extends Component {

    /**
     * render method
     */
    render() {

        //for the key
        let i = 0;

        return (
            <div className="liveBets" id="liveBets">
                <h1 id="title">My Upcoming Bets</h1>
                <div id="liveBetsTable">
                    <table>
                        <thead>
                            <tr>
                                <th id="name">Bet Name</th>
                                <th id="lbInfo">Date Placed</th>
                                <th id="lbInfo">Odds</th>
                                <th id="lbInfo">Wager</th>
                                <th id="lbInfo">To Win</th>
                                <th id="lbInfo">Payout</th>
                            </tr>
                        </thead>
                    </table>
                    {this.props.upcomingBets ? (
                        this.props.upcomingBets.map(upcomingBet => (
                                <LiveBet
                                    key={i++}
                                    name={upcomingBet.betName}
                                    date={upcomingBet.datePlaced}
                                    odds={upcomingBet.odds}
                                    wager={upcomingBet.wager}
                                    payout={upcomingBet.payout}
                                    toWin={upcomingBet.toWin}
                                />
                            )
                        )
                    ) : (
                        <div><h1 id="emptyState">Looks Like You Don't Have Any Upcoming Bets Right Now!</h1></div>
                    )}
                </div>
            </div>
        );
    }
}

export default UpcomingBets;