import React from 'react';

class TrendFlat extends React.Component {
    render() {
        return (
            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H6.25V100H0V0ZM6.25 93.75H100V100H6.25V93.75Z" fill="white"/>
                <path d="M92.0684 53.9731C93.24 52.8016 93.24 50.9021 92.0684 49.7305L72.9766 30.6386C71.805 29.467 69.9055 29.467 68.7339 30.6386C67.5624 31.8102 67.5624 33.7097 68.7339 34.8812L85.7045 51.8518L68.7339 68.8224C67.5624 69.9939 67.5624 71.8934 68.7339 73.065C69.9055 74.2366 71.805 74.2366 72.9766 73.065L92.0684 53.9731ZM21.1641 54.8518H89.9471V48.8518H21.1641V54.8518Z" fill="#FFD600"/>
            </svg>
        )
    }
}

export default TrendFlat;