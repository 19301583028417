//imports
import React, { Component } from 'react';
import '../../index.scss';
import './specials.scss'
import PropBet from './propBet';

/**
 * An element to represent the specials section of a game
 */
class Specials extends Component {

	constructor(props) {
		super(props);

		this.sendPopupState = this.sendPopupState.bind(this);
		this.addBetToParlay = this.addBetToParlay.bind(this);
		this.removeBetFromParlay = this.removeBetFromParlay.bind(this);

	}

	sendPopupState = (val) => {
		this.props.sendPopupState(val);
	}

	addBetToParlay = (bet) => {
		this.props.addToParlay(bet);
	}

	removeBetFromParlay = (bet) => {
		this.props.removeFromParlay(bet);
	}


	/**
	 * render method
	 */
	render() {
		return (
			<div className='specialBets'>
				{this.props.specials.map((prop, index) => {
					return <PropBet
						betType="7"
						name={prop.name}
						optionOneName={prop.optionOne}
						optionOneOdds={prop.optionOneOdds}
						optionTwoName={prop.optionTwo}
						optionTwoOdds={prop.optionTwoOdds}
						optionThreeName={prop.optionThree}
						optionThreeOdds={prop.optionThreeOdds}
						optionFourName={prop.optionFour}
						optionFourOdds={prop.optionFourOdds}
						teams="special"
						key={index}
						gameId={prop.gameId}
						propId={prop.propId}
						sendPopupState={this.sendPopupState}
						addToParlay={this.addBetToParlay}
						removeFromParlay={this.removeBetFromParlay}
						parlayMode={this.props.parlayMode}
						currentParlay={this.props.currentParlay}
					/>
				})
				}
			</div>
		);
	}
}

export default Specials;