import React from 'react'; 

class NBAIcon extends React.Component {
    render() {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.34051 13.6518C3.85723 15.1664 5.86619 16 8 16C10.1338 16 12.1436 15.1664 13.6595 13.6518C16.7802 10.5356 16.7802 5.46521 13.6595 2.34817C12.1436 0.834407 10.133 0 8 0C5.86699 0 3.85643 0.834407 2.34051 2.34897C-0.780171 5.46441 -0.780171 10.5356 2.34051 13.6518ZM8.24732 1.61127C9.58405 1.65932 10.8712 2.1288 11.9243 2.9524L8 6.87027L6.83145 5.70338C7.76069 4.52131 8.26573 3.05949 8.24732 1.61127ZM5.6869 4.56047L4.07654 2.9524C4.83255 2.36065 5.71322 1.94799 6.65216 1.74554C6.64015 2.7334 6.29599 3.72526 5.6869 4.56047ZM4.54716 5.6826C3.73184 6.23882 2.76605 6.53353 1.77865 6.5274C1.98662 5.63822 2.3847 4.80447 2.9456 4.08332L4.54716 5.6826ZM1.60576 8.11709C1.66739 8.11869 1.72902 8.12828 1.79065 8.12828C3.19907 8.13277 4.57017 7.67629 5.69411 6.82871L6.86826 8.0004L2.9456 11.9175C2.09909 10.8287 1.62896 9.49526 1.60576 8.11709ZM11.4456 10.311C12.2796 9.70518 13.2705 9.36151 14.2534 9.34712C14.0531 10.2834 13.6435 11.1624 13.0552 11.9183L11.4456 10.311ZM11.9235 13.0484C11.2017 13.6118 10.3665 14.0126 9.4751 14.2233C9.46607 13.2336 9.76136 12.2651 10.3211 11.4483L11.9235 13.0484ZM7.88635 14.3959C6.50293 14.3739 5.16469 13.9007 4.07574 13.0484L8 9.13053L9.17096 10.2998C8.28814 11.4539 7.84313 12.9101 7.88635 14.3959ZM14.3886 7.75423C12.9423 7.73985 11.4809 8.24177 10.3003 9.16729L9.13174 8.0004L13.0544 4.08332C13.8732 5.13723 14.3399 6.42126 14.3886 7.75423Z" fill="#3DF5D9"/>
            </svg>
        )
    }
}

export default NBAIcon;