/**
 * A class to handle the cookies in the web app
 */
class Cookie {

    /**
     * A method to set a cookie in the browser
     * @param cname name of the cookie
     * @param cvalue value of the cookie
     * @param exdays number of days until the cookie expires
     */
    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; expires=" + expires + ";";
    }

    /**
     * A method to get the value of a cookie
     * @param cname the name of the cookie
     * @returns {string} the value of the cookie
     */
    getCookie(cname) {
        const name = cname + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    /**
     * A method to clear a cookie that exists in the browser by setting the expiration to 00000000000
     * @param cname the name of the cookie being cleared
     */
    clearCookie(cname) {
        document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    }
}

export default new Cookie();