//imports
import React, { Component } from 'react';
import '../../index.scss';
import './sportPicker.scss';
import NFLSPIcon from '../../assets/nflSpIcon';
import MLBSPIcon from '../../assets/mlbSpIcon';
import NBASPIcon from '../../assets/nbaSpIcon';
import NHLSPIcon from '../../assets/nhlSpIcon';

/**
 * An element to represent the sport picker element on the home screen
 * Here users can filter which games they see on the home page
 * All sports will be available to them
 */
class SportPicker extends Component {

    //constructor
    constructor(props) {
        super(props);
        //base states
        this.state = {
            currentSport: this.props.startSport + 1
        };
    }

    /**
     * A function to update the props because they are coming in as a state
     * @param nextProps the next props (from state)
     * @param prevState the previous props (from base)
     * @returns the current sport to show on the sport picker
     */
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.startSport!==prevState.startSport){
            return { currentSport: nextProps.startSport + 1};
        }
        else return null;
    }

    /**
     * A method to update which sport is being selected
     * @param event the click of the new sport
     */
    updateCurrentSport = (event) => {
        let key = `${event.currentTarget.id}`;
        this.setState({ currentSport: key });
        this.props.onChange(key);
    };

    /**
     * A method to determine if this sport is the one that's currently selected
     * @param id determining which sport that is being compared
     * @returns {boolean} whether the passed in sport is on or not
     */
    isOn(id) {
        // eslint-disable-next-line
        return id == this.state.currentSport;
    }

    /**
     * render method
     */
    render() {
        return (
            <div className="iconList">
                <input
                    type="radio"
                    id="nfl"
                    name="nfl"
                    value="NFL"
                    readOnly
                    checked={this.isOn(1)}
                />
                <label htmlFor="nfl">
                    <div className="leagueButton">
                        <button id={1} onClick={this.updateCurrentSport}>
                            <NFLSPIcon/>
                        </button>
                    </div>
                </label>
    
                <input
                    type="radio"
                    id="mlb"
                    name="mlb"
                    value="MLB"
                    readOnly
                    checked={this.isOn(2)}
                />
                <label htmlFor="mlb">
                    <div className="leagueButton">
                        <button id={2} onClick={this.updateCurrentSport}>
                            <MLBSPIcon/>
                        </button>
                    </div>
                </label>
    
                <input
                    type="radio"
                    id="nba"
                    name="nba"
                    value="NBA"
                    readOnly
                    checked={this.isOn(3)}
                />
                <label htmlFor="nba">
                    <div className="leagueButton">
                        <button id={3} onClick={this.updateCurrentSport}>
                            <NBASPIcon/>
                        </button>
                    </div>
                </label>
    
                <input
                    type="radio"
                    id="nhl"
                    name="nhl"
                    value="NHL"
                    readOnly
                    checked={this.isOn(4)}
                />
                <label htmlFor="nhl">
                    <div className="leagueButton">
                        <button id={4} onClick={this.updateCurrentSport}>
                            <NHLSPIcon/>
                        </button>
                    </div>
                </label>
            </div>
        );
    }
}

export default SportPicker;