//imports
import React, { Component } from 'react';
import '../../index.scss';
import './info.scss';
import EditInfoPopup from './editInfoPopup.js'
import RestartPopup from './restartPopup.js'
import { withRouter } from 'react-router-dom'
import UserProfile from '../../helpers/userProfile.js';
import Cookies from '../../helpers/cookies.js';

/**
 * An element that will display a users info including username, email, bank, bets placed, bets won, bets lost, and restarts
 * Users can also restart here if they are allowed to, edit their info, and logout
 */
class Info extends Component {
	//constructor
	constructor(props) {
		super(props);

		//getting the users bank and number of live bets
		this.bank = UserProfile.getBank();
		this.numLiveBets = UserProfile.getNumLiveBets();

		//determine if the restart button should be active
		this.restartButton = (this.bank > 2 || this.numLiveBets !== 0) ? "disabled" : "active";
		this.restartActive = (this.bank > 2 || this.numLiveBets !== 0) ? 1 : 0;
		this.toggleEditInfoPopup = this.toggleEditInfoPopup.bind(this);
		this.toggleRestartPopup = this.toggleRestartPopup.bind(this);
		//base states
		this.state = { showEditInfo: false, showRestart: false };

	}

	/**
	 * A method to toggle the edit info popup if clicked
	 */
	toggleEditInfoPopup() {
		//setting the state of the popup
		this.setState({
			showEditInfo: !this.state.showEditInfo,
		});
	}

	/**
	 * A method to toggle the edit info popup if clicked
	 */
	toggleRestartPopup() {
		//setting the state of the restart popup
		this.setState({
			showRestart: !this.state.showRestart,
		});
	}

	/**
	 * A method to handle the logout button being clicked
	 */
	logout() {
		//deleting all cookies
		Cookies.clearCookie("rememberMe");
		//redirect to the login page
		this.props.history.push({pathname: "/login"});
	}

	/**
	 * render function
	 * @returns
	 */
	render() {
		if (this.props.stats) {
			this.betsWon = this.props.stats.betsWon;
			this.betsLost = this.props.stats.betsLost;
			this.totalBets = this.props.stats.betsPlaced;
			this.betsTied = this.totalBets - (this.betsWon + this.betsLost);
			if (this.betsWon) {
				this.record = this.betsWon.toString() + "-" + this.betsLost.toString() + "-" + this.betsTied.toString();
				this.winPercentage = (100 * this.betsWon / (this.totalBets)).toFixed(1).toString() + "%";
				this.bpw = this.props.stats.betsPerWeek.toFixed(2);
				this.bpd = this.props.stats.betsPerDay.toFixed(2);
			}
			if (this.totalBets === 0) {
				this.record = "0-0-0";
				this.winPercentage = "0%";
				this.bpw = 0;
				this.bpd = 0;
			}
		}

		return (
			<div className="dashInfo">
				<h1 id="title">My Stats</h1>
				<div id="stats-section">
					<table id="stats-table">
						<thead>
							<tr>
								<th>Bets Placed</th>
								<th>Betting Record</th>
								<th>Win %</th>
								<th>Bets Per Day</th>
								<th>Bets Per Week</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{this.totalBets}</td>
								<td>{this.record}</td>
								<td>{this.winPercentage}</td>
								<td>{this.bpd}</td>
								<td>{this.bpw}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<h1 id="title">My Info</h1>
				<div id="info-section">
					<table id="info-table">
						<thead>
							<tr>
								<th id="infoUN">Username</th>
								<th id="infoEmail">Email</th>
								{/*<th id="myInfo">Bank</th>*/}
								<th id="myInfo">Restarts</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td id="infoUN">{this.props.stats.username}</td>
								<td id="infoEmail">{this.props.stats.email}</td>
								{/*<td id="myInfo">{this.bank}</td>*/}
								<td id="myInfo">{this.props.stats.restarts}</td></tr>
						</tbody>
					</table>
					<div className="buttons">
						<button id="update" onClick={this.toggleEditInfoPopup.bind(this)}><p>UPDATE INFO</p></button>
						<button className={this.restartButton} id="restart" disabled={this.restartActive} onClick={this.toggleRestartPopup.bind(this)}><p>RESTART</p></button>
						<button className={this.restartButton} id="logout" onClick={() => this.logout()}><p>LOGOUT</p></button>
					</div>
					{this.state.showEditInfo ?
						<EditInfoPopup
							closePopup={this.toggleEditInfoPopup.bind(this)}
						/>
						:
						null
					}
					{this.state.showRestart ?
						<RestartPopup
							closePopup={this.toggleRestartPopup.bind(this)}
						/>
						:
						null
					}
				</div>
			</div>
		);
	}
}

export default withRouter(Info);