import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import Home from './components/homePage/home';
import Signup from './components/signupPage/signup';
import Login from './components/loginPage/login';
import Account from './components/accountPage/accountPage';
import ResetCode from './components/resetCodePage/resetCode';
import ResetPW from './components/resetPWPage/resetPW';
import TosPage from './components/tosPage/tosPage';

ReactGA.initialize('UA-188495409-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {

	render() {
		return (
			<BrowserRouter>
				<div>
					<Switch>
						<Route key={0} path="/" component={Home} exact />
						<Route key={1} path="/account" component={Account} />
						<Route key={2} path="/signup" component={Signup} />
						<Route key={3} path="/login" component={Login} />
						<Route key={4} path="/resetcode" component={ResetCode} />
						<Route key={5} path="/resetpw" component={ResetPW} />
						<Route key={6} path="/tos" component={TosPage} />
						<Route component={Error} />
					</Switch>
				</div>
			</BrowserRouter>
		);
	}
}

export default App;