import React from 'react';

class NotificationBubble extends React.Component {
    render() {
        return (
            <svg width="333" height="155" viewBox="0 0 333 155" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M312.206 18.4998H312.57L312.85 18.265L332 2.14842V140C332 145.255 331.353 148.416 328.849 150.406C327.567 151.425 325.713 152.208 322.993 152.73C320.274 153.251 316.757 153.5 312.206 153.5H18.7606C14.2067 153.5 10.8234 153.251 8.3042 152.732C5.78574 152.214 4.21799 151.444 3.20581 150.469C2.20749 149.508 1.64578 148.247 1.34048 146.523C1.03049 144.773 1 142.643 1 140V36.4999C1 33.8673 1.02927 31.4349 1.34555 29.2421C1.6608 27.0565 2.2529 25.1779 3.33197 23.6331C5.44876 20.6028 9.71274 18.4998 18.7606 18.4998H312.206Z" fill="#3DF5D9" stroke="white" strokeWidth="2"/>
            </svg>
        )
    }
}

export default NotificationBubble;